import { useState } from 'react'

import { useAuthProvider } from '../../context/AuthProvider/AuthProvider'
import { UPDATE_DATA_USER } from '../../utils/constantsAdmin'

import { useUpdateDataUserActions } from '../useUpdateDataUserActions/useUpdateDataUserActions'
import { useTranslation } from 'react-i18next'

export function useSendDataUser() {
	const { user, axiosSupreme, updateLocalDataUser } = useAuthProvider()
	const {
		errors,
		loading,
		editPassword,
		updateLoading,
		updateEditPassword,
		updateErorrs,
	} = useUpdateDataUserActions()
	const { t } = useTranslation()
	const [responseRequest, setResponseRequest] = useState('')

	const handlerEditPassword = (e) => {
		e?.preventDefault()
		e?.stopPropagation()
		updateEditPassword({ editPassword: !editPassword })
	}

	async function sendDataUser({ dataForm }) {
		setResponseRequest('')
		const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
		const validPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{3,}$/

		if (dataForm.email && !dataForm.email.match(validEmail)) {
			updateErorrs({
				errors: [t("errors.mail_format_invalid")],
			})
			return
		}

		if (editPassword) {
			if (!dataForm.password.match(validPassword)) {
				updateErorrs({
					errors: [t("errors.validate_length_letters_special_characters")],
				})
				return
			}

			if (dataForm.password !== dataForm.confirmPassword) {
				updateErorrs({
					errors: [t("errors.passwords_must_be_equal")],
				})
				return
			}
		}

		if (!editPassword) {
			delete dataForm.password
		}
		delete dataForm.confirmPassword

		try {
			updateLoading({ loading: true })
			const res = await updateDataUser(user.id, dataForm)
			updateLocalDataUser(res)
			setResponseRequest(t("success.correct_update_data"))
		} catch (err) {
			if (err.code === 'ERR_BAD_REQUEST') {
				console.error('error del usuario', err.response.data)
				const { data } = err.response
				updateErorrs({
					errorCode: err.code,
					errors: data,
				})
			} else {
				updateErorrs({
					errors: [t("errors.unexpected_error")],
				})
			}
			console.error({ err })
		} finally {
			updateLoading({ loading: false })
		}
	}

	const updateDataUser = async (idUser, body) => {
		const result = await axiosSupreme(
			'patch',
			UPDATE_DATA_USER + idUser + '/',
			body
		)
		return result
	}

	return {
		sendDataUser,
		handlerEditPassword,
		editPassword,
		updateErorrs,
		errors,
		loading,
		responseRequest,
	}
}

export class SliderBar {
	#time = 1250
	get time() {
		return this.#time
	}
	#container
	get container() {
		return this.#container
	}
	#slider
	get slider() {
		return this.#slider
	}

	constructor(scene, x, y, color) {
		const barDiv = document.createElement('div')
		barDiv.classList.add('map__door__opening__bar')

		this.#slider = document.createElement('div')
		this.#slider.classList.add('map__door__opening__bar__slider')
		if (color) {
			this.#slider.style.backgroundColor = color
		}

		barDiv.appendChild(this.#slider)

		// ----- CREATE THE HTML PHASER ELEMENT
		const element = scene.add.dom(0, 0, barDiv)
		//.setOrigin(this.#origin.x, this.#origin.y)

		this.#container = scene.add.container(x - 5, y)
		this.#container.add(element)
		this.#container.alpha = 0
	}
	start() {
		this.#container.alpha = 1
		this.#slider.classList.add(
			'map__door__opening__bar__slider__animations__complete'
		)
	}
	stop() {
		this.#container.alpha = 0
		this.#slider.classList.remove(
			'map__door__opening__bar__slider__animations__complete'
		)
	}
}

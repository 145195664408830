import { useTranslation } from 'react-i18next'
import '../../styles/PlaygroundStart.scss'
import ImageOnFlex1 from './ImageOnFlex1'

const PlaygroundStart = ({ src, srcWebm, onClick }) => {
	const {t} = useTranslation()
	return (
		<section className='playground__start'>
			<ImageOnFlex1
				src={src}
				srcWebm={srcWebm}
				isVideo={true}
				aspectRatio={1622 / 1058}
			/>

			<button onClick={onClick}>
				{t("common.btn_start")}
			</button>
		</section>
	)
}

export default PlaygroundStart

/**
 * Qué quiero?
 *
 * Quiero que cuando le de al boton de start, lo que ocurra
 * sea que el componente, desaparece con una transición.
 *
 * Y tambien quiero que el game, aparezca con una transición de
 * invisible a visible apenas se llama al componente.
 *
 * Cómo lo logro?
 *
 * Qué algo de css, hace que los algos sean invisibles o visibles?
 *
 *  - opacity: (0 - 1)
 */

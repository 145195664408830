import { useSoundAction } from '../../hooks/useSoundAction'

const MenuBar = ({ reloadScene, data }) => {
	const { soundAction } = useSoundAction()

	return (
		<div className='scene__menubar'>
			{data.map((item, index) => {
				return (
					<button
						key={index}
						onMouseUp={item.onClick}
						onClick={() => soundAction('effect', 'TLSG1', 'play')}
					>
						<div
							style={
								index === 0
									? { backgroundColor: '#CC7A1F' }
									: { backgroundColor: '#F2CB8F' }
							}
						>
							<picture>
								{item.imageAvif && (
									<source srcSet={item.imageAvif} type='image/avif' />
								)}

								<img
									src={item.image}
									alt='cofre'
									loading='lazy'
									decoding='async'
								/>
							</picture>
							<label>{item.label}</label>
						</div>
						{item.notification && (
							<div data-reload={reloadScene}></div>
						)}
						{reloadScene && <span></span>}
					</button>
				)
			})}
		</div>
	)
}

export default MenuBar

import '../../styles/Tabs.scss'
import { useSoundAction } from '../../hooks/useSoundAction'

const tabStates = {
	selected: 'popup__window__tabs__section__tab',
	unselected:
		'popup__window__tabs__section__tab popup__window__tabs__section__tab__unselected',
}

const Tabs = ({ data, activeTabIndex, setActiveTabIndex }) => {
	const { soundAction } = useSoundAction()

	return (
		<div className='popup__window__tabs'>
			<div className='popup__window__tabs__section'>
				{data.map((tab, index) => (
					<div
						key={index}
						className={
							activeTabIndex === index
								? tabStates.selected
								: tabStates.unselected
						}
						onClick={(e) => {
							soundAction('effect', 'TLSG3', 'play')
							setActiveTabIndex(index)
						}}>
						{tab.name}
					</div>
				))}
			</div>
		</div>
	)
}

export default Tabs

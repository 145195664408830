import React, { useState, useRef, Fragment } from 'react'
import '../../styles/ContextPairs.scss'

import { useGameStatus } from '../../provider/ActivityProvider'

import Lives from '../fragments/Lives'
import AudioGame from '../fragments/AudioGame'
import { useSoundAction } from '../../hooks/useSoundAction'

/**
 * @type {Array<String>} colors the colors the cards are going to take when completed,
 * so the user can differenciate between the pairs he alredy did.
 */
const colors = [
	'#EF2158',
	'#53DAF9',
	'#D653F7',
	'#F77015',
	'#4EE542',
	'#EF2158',
	'#53DAF9',
	'#D653F7',
	'#F77015',
	'#4EE542',
	'#EF2158',
	'#53DAF9',
	'#D653F7',
	'#F77015',
	'#4EE542',
	'#EF2158',
	'#53DAF9',
	'#D653F7',
	'#F77015',
	'#4EE542',
	'#EF2158',
	'#53DAF9',
	'#D653F7',
	'#F77015',
	'#4EE542',
]

// ------------------------------------------------------------- COMPONENTS STATES
/**
 * @typedef {Object} states Contains the different classNames (visual states) a component can have.
 */
const heartStates = {
	normal: 'playground__pairs__hearts__section__column',
	faded: 'playground__pairs__hearts__section__column playground__pairs__hearts__section__column__faded',
}
// SELECTABLE, NOTSELECTABLE, SELECTED, INVISIBLE, COMPLETED, DISABLED.
const cardStates = {
	selectable:
		'playground__pairs__section__column__card playground__pairs__section__column__card__selectable',
	notSelectable:
		'playground__pairs__section__column__card playground__pairs__section__column__card__not__selectable',
	selected:
		'playground__pairs__section__column__card playground__pairs__section__column__card__selected',
	invisible:
		'playground__pairs__section__column__card playground__pairs__section__column__card__invisible',
	wrong: 'playground__pairs__section__column__card playground__pairs__section__column__card__wrong',
	completed:
		'playground__pairs__section__column__card playground__pairs__section__column__card__completed',
	disabled:
		'playground__pairs__section__column__card playground__pairs__section__column__card__disabled',
}

// ------------------------------------------------------------- COMPONENTS

/**
 * Renders a game with the words in wordsData passed by the Playground Component
 * @param {Array<Object>} wordsData Each object has a word, translation, audio, image, etc...
 * @returns {JSX}
 */
export default function ContextPairs({ wordsData }) {
	const [_, setGameStatus, gameMistakesCounter] = useGameStatus()
	const { soundAction } = useSoundAction()

	/**
	 * Convert the wordsData objects into pairs
	 * Object order 1 is pair of Object order 2
	 * Object order 3 is pair of Object order 4
	 * and so on...
	 *
	 * wordsData = [
	 *      [{object-order1}, {object-order2}],
	 *      [{object-order3}, {object-order4}],
	 *      ...
	 * ]
	 */
	const once = useRef(false)
	if (!once.current) {
		wordsData.sort((a, b) => a.order - b.order)
		let wordsDataClone = structuredClone(wordsData)

		let emptyStructure = []
		for (let i = 0; i < wordsDataClone.length / 2; i++) {
			emptyStructure.push([{}, {}])
		}

		let auxIndexPair = 0
		for (let i = 0; i < wordsDataClone.length; i++) {
			emptyStructure[auxIndexPair][i % 2] = wordsDataClone[i]
			if (i % 2 !== 0) {
				auxIndexPair += 1
			}
		}
		wordsData = emptyStructure.slice()
	}

	/**
	 * @type {String} audioSRC the url of the audio of the word.
	 * @type {String} audioSRC2 the url of the audio of the word.
	 * @type {String} finalGameState The state of the game inProgress, gameOver, completed.
	 * @type {Number} mistakesCounter The number of times the user have commited a mistake.
	 * @type {Number} heartsAmount  the amount if times the user can commit mistakes without loosing.
	 */
	const [audioSRC, setAudioSRC] = useState('')
	const [audioSRC2, setAudioSRC2] = useState('')
	const [finalGameState, setFinalGameState] = useState('inProgress') // gameOver, completed
	const [mistakesCounter, setMistakesCounter] = useState(0)
	const heartsAmount = 3

	/**
	 * @type {Number} completedPairsCounter the number of pairs the user has completed
	 * @type {Number} firstSelectedCardPosition The user has to select two cards, this variable
	 * remembers the first one selected (its position in cards :v)
	 * @type {Array<Array<Object>, Array<Object>} cards this has two arrays, each representing
	 * a column in the game, the first column (left) has all the first pair, and the second one
	 * contains the second pair. (**** REMEBER THAT IN A PHRASE THE ORDER MATTERS ****)
	 * Each object (card) is like this:
	 * @typedef card
	 * @property {String} state the visual state of the card (className)
	 * @property {Object} styleCompletedBackgroundColor the style argument of the div
	 * @property {Object} positionInWordsData literally the pointer to wordsData which contains
	 * the word, translation, image and audio.
	 */
	const [completedPairsCounter, setCompletedPairsCounter] = useState(0)
	const completedPairsCounterRef = useRef(completedPairsCounter)
	const [firstSelectedCardPosition, setFirstSelectedCardPosition] =
		useState(null)
	const [cards, setCards] = useState([[], []])
	// ----- CREATE THE CARDS IN THE FIRST AND ONLY IN THE FIRST RENDER.
	if (cards[0].length === 0) {
		const newCards = [[], []]

		/**
		 * ----- #1 CREATE AN ARRAY WITH TWO ARRAYS, EACH REPRESENTING A COLUMN
		 * EACH FULL OF CARDS, WITH POINTERS TO ITS CONTENT IN WORDS DATA.
		 * ITS STATE AND STYLE. EVERYTHING WHERE IT HAS TO BE.
		 * [[
		 *      [{...}, {...}], PAIR 1 CARD 1
		 *      [{...}, {...}], PAIR 2 CARD 1
		 *      [{...}, {...}], PAIR 3 CARD 1
		 * ], [
		 *      [{...}, {...}], PAIR 1 CARD 2
		 *      [{...}, {...}], PAIR 2 CARD 2
		 *      [{...}, {...}], PAIR 3 CARD 2
		 * ]]
		 */

		let pairIndex = 0
		let cardIndex = 0
		// FOR EACH PAIR OF WORDS DATA
		for (const pair of wordsData) {
			// FOR EACH ELEMENT IN THE PAIR
			for (const _ of pair) {
				// CREATE THE CARD WITH ITS PROPER CONTENTS
				const newCard = {
					state: cardIndex === 0 ? 'selectable' : 'notSelectable',
					styleCompletedBackgroundColor: { backgroundColor: '' },
					positionInWordsData: { pairIndex, cardIndex },
				}
				newCards[cardIndex].push(newCard)
				cardIndex++
			}
			pairIndex++
			cardIndex = 0
		}

		/**
		 * ----- #2 SHUFFLE THAT ARRAY :D
		 * At the moment the array looks like this:
		 * [{pair1Card1}, {pair1Card2}, {pair2Card1}, {pair2Card2}...]
		 * Algorithm: Push a Removed random card in a range of the array,
		 * Range: Pushed cards go lasts. Each pushed card, reduces the range by 1,
		 *     so it always removes a dont randomise card before.
		 */
		let columnIndex = 0
		for (const column of newCards) {
			let alreadyRandomisedCardsAmount = 1 // It starts with one :v... I know, I know.
			do {
				const rangeOfNewCardsToPickARandomCard =
					column.length - alreadyRandomisedCardsAmount
				const randomIndex = Math.round(
					Math.random() * rangeOfNewCardsToPickARandomCard
				)
				newCards[columnIndex].push(
					newCards[columnIndex].splice(randomIndex, 1)[0]
				)
				alreadyRandomisedCardsAmount++
			} while (alreadyRandomisedCardsAmount <= column.length)
			columnIndex++
		}
		// ----- #3 SET THE BRAND NEW CARDS, OOUUUUU completed
		setCards(newCards)
	}

	/**
	 * This controls the entire logic of the interaction when the user presses the cards.
	 * @param {Number} cardPosition The index of the card in the list of cards
	 */
	function onCardClicked(cardPosition) {
		// So... what do I want to do now?

		// ----- #1 GET THE SELECTED CARD.
		const selectedCard = { ...cards[cardPosition.column][cardPosition.row] }

		// ----- #2 RETURN IF:
		// THE SELECTED CARD IS NOT SELECTABLE.
		if (selectedCard.state !== 'selectable') return

		if (cardPosition.column === 0) {
			// ----- PLAY THE AUDIO
			setAudioSRC(
				wordsData[selectedCard.positionInWordsData.pairIndex][
					selectedCard.positionInWordsData.cardIndex
				].audio
			)

			// ----- #3 CHANGE STATE OF THIS CARD TO SELECTED
			setCards((crds) =>
				crds.map((column, columnIndex) => {
					if (columnIndex !== cardPosition.column) return column

					return column.map((card, rowIndex) => {
						if (rowIndex !== cardPosition.row) return card

						return { ...card, state: 'selected' }
					})
				})
			)

			// ----- #4 CHANGE THE REST OF THE CARDS IN THAT COLUMN TO INVISIBLE
			setCards((crds) =>
				crds.map((column, columnIndex) => {
					if (columnIndex !== cardPosition.column) return column

					return column.map((card, rowIndex) => {
						if (
							rowIndex === cardPosition.row ||
							card.state !== 'selectable'
						)
							return card

						return { ...card, state: 'invisible' }
					})
				})
			)

			// ----- #5 MAKE THE OTHER COLUMN SELECTABLE
			setCards((crds) =>
				crds.map((column, columnIndex) => {
					if (columnIndex === cardPosition.column) return column

					return column.map((card, rowIndex) => {
						if (card.state !== 'notSelectable') return card

						return { ...card, state: 'selectable' }
					})
				})
			)

			// ----- #6 SAVE THIS FIRST SELECTED CARD POSITION
			setFirstSelectedCardPosition(cardPosition)
		} else if (cardPosition.column === 1) {
			setAudioSRC('')

			// ----- #3 CHECK IF THE PAIR IS VALID
			const firstSelectedCard = {
				...cards[firstSelectedCardPosition.column][
					firstSelectedCardPosition.row
				],
			}
			const selectedPairPosition = [
				{ ...firstSelectedCardPosition },
				cardPosition,
			]
			if (
				firstSelectedCard.positionInWordsData.pairIndex ===
				selectedCard.positionInWordsData.pairIndex
			) {
				// VALID PAIR :D
				// ----- PLAY THE AUDIO
				soundAction('effect', 'TLIJ1', 'play')
				setAudioSRC2(
					wordsData[selectedCard.positionInWordsData.pairIndex][
						selectedCard.positionInWordsData.cardIndex
					].audio
				)

				// ----- SYNC WITH WORDS LIST
				const selectedCardWord =
					wordsData[selectedCard.positionInWordsData.pairIndex][
						selectedCard.positionInWordsData.cardIndex
					].word
				const firstselectedCardWord =
					wordsData[firstSelectedCard.positionInWordsData.pairIndex][
						firstSelectedCard.positionInWordsData.cardIndex
					].word

				// ----- #4 CHANGE THE STATE OF THE PAIR TO COMPLETED
				setCards((crds) =>
					crds.map((column, columnIndex) => {
						return column.map((card, rowIndex) => {
							if (
								rowIndex !==
								selectedPairPosition[columnIndex].row
							)
								return card

							return {
								...card,
								state: 'completed',
								styleCompletedBackgroundColor: {
									...card.styleCompletedBackgroundColor,
									backgroundColor:
										colors[completedPairsCounter],
								},
							}
						})
					})
				)

				// ----- #5 RETURN ALL THE INVISIBLE CARD OF THE FIRST COLUMN TO SELECTABLE AGAIN
				setCards((crds) =>
					crds.map((column, columnIndex) => {
						if (columnIndex === 1) return column

						return column.map((card, rowIndex) => {
							if (card.state !== 'invisible') return card

							return { ...card, state: 'selectable' }
						})
					})
				)

				// ----- #5 RETURN ALL THE SELECTABLE OF THE SECOND COLUMN TO NOT SELECTABLE
				setCards((crds) =>
					crds.map((column, columnIndex) => {
						if (columnIndex === 0) return column

						return column.map((card, rowIndex) => {
							if (card.state !== 'selectable') return card

							return { ...card, state: 'notSelectable' }
						})
					})
				)

				// ----- #6 INCREASE THE COMPLETED PAIRS COUNTER
				setCompletedPairsCounter(completedPairsCounter + 1)
				completedPairsCounterRef.current =
					completedPairsCounterRef.current + 1
				if (completedPairsCounter + 1 >= cards[0].length) {
					setFinalGameState('completed')
					startFinishing()
				}
			} else {
				// IN-VALID :C
				// playAudio('error')
				setAudioSRC2('') // con esto me garantiso que si no se ha acabado el audio, pues que no se ejecute onAudioEnd.

				// ----- #4 CHANGE THE STATE OF THE PAIR TO WRONG
				setCards((crds) =>
					crds.map((column, columnIndex) => {
						return column.map((card, rowIndex) => {
							if (
								rowIndex !==
								selectedPairPosition[columnIndex].row
							)
								return card

							return {
								...card,
								state: 'wrong',
							}
						})
					})
				)

				// ----- #4 INVISIBLE THE SECOND COLUMN WHILE THE WRONG ANIMATION
				setCards((crds) =>
					crds.map((column, columnIndex) => {
						if (columnIndex === 0) return column

						return column.map((card, rowIndex) => {
							if (card.state !== 'selectable') return card

							return {
								...card,
								state: 'invisible',
							}
						})
					})
				)

				// ----- #5 DELAY THE NEXT STEPS UNTIL THE WRONG ANIMATION ENDS
				const wrongAnimationEnd = setTimeout(() => {
					clearTimeout(wrongAnimationEnd)

					// ----- #6 MAKE WRONG CARDS INVISIBLE (THIS IS FOR MAKING THE RETURNING LOGIC SIMPLIER)
					setCards((crds) =>
						crds.map((column, columnIndex) => {
							return column.map((card, rowIndex) => {
								if (
									rowIndex !==
									selectedPairPosition[columnIndex].row
								)
									return card

								return { ...card, state: 'invisible' }
							})
						})
					)

					// IN THIS MOMENT ALL CARDS THAT HAVEN'T BEEN RESOLVED YET, ARE INVISIBLE.
					// ----- #7 MAKE FIRST COLUMN INVISIBLE CARDS TO SELECTABLE
					setCards((crds) =>
						crds.map((column, columnIndex) => {
							if (columnIndex === 1) return column

							return column.map((card, rowIndex) => {
								if (card.state !== 'invisible') return card

								return { ...card, state: 'selectable' }
							})
						})
					)

					// ----- #8 MAKE SECOND COLUMN INVISIBLE CARDS TO NOT SELECTABLE
					setCards((crds) =>
						crds.map((column, columnIndex) => {
							if (columnIndex === 0) return column

							return column.map((card, rowIndex) => {
								if (card.state !== 'invisible') return card

								return { ...card, state: 'notSelectable' }
							})
						})
					)

					// ----- #9 INCREASE MISTAKES COUNTER
					gameMistakesCounter.current += 1
					setMistakesCounter(mistakesCounter + 1)
				}, 300)
			}
		}
	}

	const onDeath = () => {
		setFinalGameState('gameOver')
		setGameStatus('gameOver')

		// ----- #10 DISABLE ALL NON COMPLETED PAIRS
		setCards((crds) =>
			crds.map((column, columnIndex) => {
				return column.map((card, rowIndex) => {
					if (card.state === 'completed') return card

					return { ...card, state: 'disabled' }
				})
			})
		)
	}

	const win = () => {
		setFinalGameState('gameOver')
		setGameStatus('completed')
	}

	/**
	 * Called when the audio starts.
	 * Sets the NPCprofile expression to talking.
	 */
	const handleAudioStart = () => {}

	/**
	 * Called when the audio ends.
	 */
	const handleAudioEnd = () => {}

	const audioAlreadyStartedRef = useRef(false)
	const audioAlreadyStartedTimeoutRef = useRef(null)
	const audioAlreadyStartedTimeLimit = 2000
	const startFinishing = () => {
		audioAlreadyStartedRef.current = false
		audioAlreadyStartedTimeoutRef.current = setTimeout(() => {
			clearTimeout(audioAlreadyStartedTimeoutRef.current)

			// Si entra aquí, es porque el audio nunca arrancó.
			// asi de debemos ganar manualmente
			if (!audioAlreadyStartedRef.current) win()
		}, audioAlreadyStartedTimeLimit)
	}

	const handleAudioStart2 = (audioIndex) => {
		if (audioIndex < cards[0].length) return

		clearTimeout(audioAlreadyStartedTimeoutRef.current)
		audioAlreadyStartedRef.current = true
	}

	const handleAudioEnd2 = (audioIndex) => {
		if (audioIndex < cards[0].length) return

		clearTimeout(audioAlreadyStartedTimeoutRef.current)
		win()
	}

	const livesAmounts = [1, 2, 4, 5, 6, 7, 9]

	return (
		<div
			className='playground__game playground__pairs'
			id='playground__pairs'>
			<Lives
				amount={livesAmounts[Math.ceil(wordsData.length / 2)]}
				mistakesCounter={mistakesCounter}
				onDeath={onDeath}
				columnsAmount={livesAmounts[wordsData.length]}
			/>
			<div
				className='playground__pairs__section'
				id='playground__pairs__section'>
				{cards.map((column, i) => {
					return (
						<Fragment key={i}>
							{i === 1 && (
								<div className='playground__pairs__section__columnlines'>
									{column.map((card, e) => (
										<div key={e} />
									))}
								</div>
							)}
							<div className='playground__pairs__section__column'>
								{column.map((card, e) => {
									const cardData =
										wordsData[
											card.positionInWordsData.pairIndex
										][card.positionInWordsData.cardIndex]
									return (
										<Card
											key={
												card.positionInWordsData
													.cardIndex +
												'_' +
												card.positionInWordsData
													.pairIndex
											}
											position={{ column: i, row: e }}
											state={card.state}
											data={cardData}
											onCardClicked={onCardClicked}
											styleCompletedBackgroundColor={
												card.styleCompletedBackgroundColor
											}></Card>
									)
								})}
							</div>
						</Fragment>
					)
				})}
			</div>
			<AudioGame
				src={audioSRC}
				handleAudioStart={handleAudioStart}
				handleAudioEnd={handleAudioEnd}></AudioGame>
			<AudioGame
				key={completedPairsCounterRef.current}
				src={audioSRC2}
				index={completedPairsCounterRef.current}
				handleAudioStart={handleAudioStart2}
				handleAudioEnd={handleAudioEnd2}></AudioGame>
		</div>
	)
}

/**
 *
 * @param {Number} position the index of the card in the column of cards
 * @param {String} state the visual state of the card (not className)
 * @param {Object} data the object that contains the wordsData: name, translation, image, audio.
 * @param {Function} onCardClicked The function to execute when the user clicks the card
 * @param {Object} styleCompletedBackgroundColor The style of the div
 * @returns
 */
const Card = ({
	position,
	state,
	data,
	onCardClicked,
	styleCompletedBackgroundColor,
}) => {
	/**
	 * @type {String} actualCardState The className calculated with the state
	 */
	let actualCardState
	if (state === 'selectable') actualCardState = cardStates.selectable
	if (state === 'notSelectable') actualCardState = cardStates.notSelectable
	if (state === 'selected') actualCardState = cardStates.selected
	if (state === 'invisible') actualCardState = cardStates.invisible
	if (state === 'wrong') actualCardState = cardStates.wrong
	if (state === 'completed') actualCardState = cardStates.completed
	if (state === 'disabled') actualCardState = cardStates.disabled

	return (
		<div
			className={actualCardState}
			style={styleCompletedBackgroundColor}
			onClick={() => {
				onCardClicked(position)
			}}>
			<div className='playground__pairs__section__column__card__content'>
				<div className='playground__pairs__section__column__card__content__text'>
					{data.word}
				</div>
				{state === 'completed' ? (
					<div className='playground__pairs__section__column__card__content__translation'>
						{data.translation}
					</div>
				) : (
					'--------'
				)}
			</div>
		</div>
	)
}

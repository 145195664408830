import { useContext, useEffect, useState } from 'react'
import { GET_SCENE_WORLD_BY_WORLD } from '../../../../utils/constantsAdmin'
import ModalContext from '../../../../context/editor/ModalContext'
import FormSceneWorld from './FormSceneWorld'
import SceneWorldComponent from './SceneWorldComponent'
import { Pagination } from '../../searchAndPagination/Pagination'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ListSceneWorld = ({ idWorld }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// REFRESH SCENE WORLDS
	const [refreshSceneWorlds, setRefreshSceneWorlds] = useState(false)

	// DATA
	const [dataSceneWorlds, setDataSceneWorlds] = useState(undefined)

	// GET SCENE WORLDS FOR LEVEL WORLDS
	useEffect(() => {
		getData()
	}, [refreshSceneWorlds])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				GET_SCENE_WORLD_BY_WORLD + idWorld + '/'
			)
			setDataSceneWorlds(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddScenebWorldModal = () => {
		setTitleModal(t("pages.editor.components.list_scene_world.add_scene"))
		setContentModal(
			<FormSceneWorld
				idWorld={idWorld}
				setRefreshSceneWorlds={setRefreshSceneWorlds}
			/>
		)
		openModal()
	}

	return (
		<div className='worldsBodyComponent__Content__ContainerSceneWorld'>
			<div className='worldsBodyComponent__Content__ContainerSceneWorld__ContainerTitle'>
				<h1>{t("pages.editor.components.list_scene_world.scenes")}</h1>
				<div className='worldsBodyComponent__Content__ContainerSceneWorld__ContainerTitle__BoxButton'>
					{gameUser.permissions?.hasOwnProperty('add_world_scene') ? (
						<button
							className='editorBtnActionAdd'
							onClick={OpenAddScenebWorldModal}>
							{t("common.add")}
						</button>
					) : null}
				</div>
			</div>

			{dataSceneWorlds !== undefined ? (
				<>
					{dataSceneWorlds === 'search' ||
						dataSceneWorlds.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setDataSceneWorlds}
								setRefreshDataList={setRefreshSceneWorlds}
								url={GET_SCENE_WORLD_BY_WORLD + idWorld + '/'}
								search={search}
								setSearch={setSearch}
							/>
							{dataSceneWorlds === 'search' ? (
								<p className='noSelect'>
									{t("pages.editor.components.list_scene_world.none_scene_found")}
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							{t("pages.editor.components.list_scene_world.none_scene_assigned")}
						</p>
					)}
					{dataSceneWorlds !== 'search' ? (
						<div className='worldsBodyComponent__Content__ContainerSceneWorld__ListSceneWorld'>
							{dataSceneWorlds.results.map((data_item) => (
								<SceneWorldComponent
									key={data_item.id_world_scene}
									idWorld={idWorld}
									objectSceneWorld={data_item}
									setRefreshSceneWorlds={
										setRefreshSceneWorlds
									}
								/>
							))}
							<Pagination
								data={dataSceneWorlds}
								setData={setDataSceneWorlds}></Pagination>
						</div>
					) : null}
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}

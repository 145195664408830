import React, { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../../context/editor/AlertContext'
import ModalContext from '../../../../../context/editor/ModalContext'
import { API_MANAGE_CHALLENGES } from '../../../../../utils/constantsAdmin'
import SaveButton from '../../../SaveButton'
import {
	onInputChangeDefault,
	processErrors,
} from '../../../../../utils/general_functions_forms'
import { handleKeyPressInteger } from '../../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../../LoadingIcon'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const FormChallenge = ({
	stateObjectChallenge,
	setStateObjectChallenge,
	setRefreshChallenges,
}) => {
	const { t } = useTranslation()
	const { gameUser, axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [category, setCategory] = useState('')
	const [reward, setReward] = useState('')
	const [stars, setStars] = useState('')
	const [xp, setXp] = useState('')

	// VISIBLE STARS
	const [visibleInputStars, setVisibleInputStars] = useState(false)

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setCategory('')
		setReward('')
		setStars('')
		setXp('')
	}

	// SET WORLD WHEN UPDATE
	useEffect(() => {
		if (stateObjectChallenge !== undefined) {
			setName(stateObjectChallenge.name)
			setCategory(stateObjectChallenge.category)
			setReward(stateObjectChallenge.reward)
			if (stateObjectChallenge.category === 'g') {
				setVisibleInputStars(true)
				setStars(stateObjectChallenge.stars)
			}
			if (stateObjectChallenge.xp) {
				setXp(stateObjectChallenge.xp)
			}
		} else {
			clearFields()
		}
	}, [stateObjectChallenge])

	// SUBMIT FORM CHALLENGE
	const onSubmitChallenge = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		formData.append('category', event.target.elements.category.value)
		formData.append('reward', event.target.elements.reward.value)
		formData.append(
			'stars',
			visibleInputStars ? event.target.elements.stars.value : ''
		)
		formData.append('xp', event.target.elements.xp.value)
		if (stateObjectChallenge === undefined) {
			// SAVE
			manageChallenges(API_MANAGE_CHALLENGES, formData, 'add')
		} else {
			// UPDATE
			manageChallenges(
				API_MANAGE_CHALLENGES + stateObjectChallenge.id_challenge + '/',
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE DIALOGUE
	const manageChallenges = async (url, obj, action) => {
		try {
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add'
					? t("common.added")
					: t("common.modified")
			)
			showAlert()
			if (action === 'add') setRefreshChallenges((prev) => !prev)
			if (action === 'update') setStateObjectChallenge(result_data)
			setStateLoading(false)
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t("errors.request_error"))
			if (errorPromise.response.status === 403) {
				setMessage(
					t("common.insufficient_permissions")
				)
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const onInputChangeCategory = (event, setFunction) => {
		setStars('')
		setFunction(event.target.value)
		if (event.target.value === 'g') {
			setVisibleInputStars(true)
		} else {
			setVisibleInputStars(false)
		}
	}

	return (
		<>
			<form onSubmit={onSubmitChallenge}>
				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_challenge.name")}
					</label>
					<input
						maxLength='45'
						name='name'
						className='admin__container__inputs__in'
						id='name'
						type='text'
						placeholder={t("pages.editor.components.form_challenge.placeholder_name")}
						autoComplete='off'
						value={name}
						onChange={(event) =>
							onInputChangeDefault(event, setName)
						}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_challenge.reward")}
					</label>
					<input
						maxLength='45'
						name='reward'
						className='admin__container__inputs__in'
						id='reward'
						type='number'
						placeholder={t("pages.editor.components.form_challenge.placeholder_reward")}
						autoComplete='off'
						value={reward}
						onChange={(event) =>
							onInputChangeDefault(event, setReward)
						}
						onKeyPress={(event) => handleKeyPressInteger(event)}
						required></input>
				</div>

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_challenge.category")}
					</label>
					<select
						name='category'
						className='admin__container__inputs__in'
						id='category'
						placeholder={t("pages.editor.components.form_challenge.placeholder_category")}
						autoComplete='off'
						value={category}
						required
						onChange={(event) =>
							onInputChangeCategory(event, setCategory)
						}>
						<option key='' value=''>
							{t("pages.editor.components.form_challenge.select_category")}
						</option>
						<option value='l'>Lección</option>
						<option value='o'>Objeto</option>
						<option value='g'>Juego</option>
						<option value='ee'>Easter Egg</option>
					</select>
				</div>

				{visibleInputStars ? (
					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t("pages.editor.components.form_challenge.stars_number")}
						</label>
						<input
							maxLength='45'
							name='stars'
							className='admin__container__inputs__in'
							id='stars'
							type='number'
							placeholder={t("pages.editor.components.form_challenge.placeholder_stars")}
							autoComplete='off'
							value={stars}
							onChange={(event) =>
								onInputChangeDefault(event, setStars)
							}
							onKeyPress={(event) => handleKeyPressInteger(event)}
							required></input>
					</div>
				) : null}

				<div
					className='admin__container__inputs'
					id='admin__container__inputs'>
					<label
						className='admin__container__inputs__title'
						forhtml='admin__container__inputs__title'>
						{t("pages.editor.components.form_challenge.experience")}
					</label>
					<input
						maxLength='45'
						name='xp'
						className='admin__container__inputs__in'
						id='xp'
						type='number'
						placeholder={t("pages.editor.components.form_challenge.placeholder_experience")}
						autoComplete='off'
						value={xp}
						onChange={(event) => onInputChangeDefault(event, setXp)}
						onKeyPress={(event) =>
							handleKeyPressInteger(event)
						}></input>
				</div>
				{stateLoading === false ? (
					<div className='admin__container__boxBtn'>
						<SaveButton></SaveButton>
					</div>
				) : (
					<LoadingIcon />
				)}
			</form>
		</>
	)
}

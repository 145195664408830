import { ClickableNPC } from "./ClickableNPC"
import { Image } from "./Image"

export class NPCGame extends ClickableNPC {
	static gameTypes = {
		anagram: 'Anagrama',
		wordSearch: 'Sopa de Letras',
		hangman: 'Ahorcado',
		memory: 'Memoria',
		contextPairs: 'Pares de Contexto',
		crossword: 'Crucigrama',
		wordWithImage: 'Relacion Texto con Imagen',
	}
	#gameType

	// ----- THIS COULD BE IN THE MISSIONS NPC TOO
	#starsData = {
		total: 3,
		earned: 2,
		items: [],
	}

	constructor(
		id,
		name,
		pos,
		spriteSheets,
		type,
		dialogues,
		missions,
		profileImage,
		gameType,
		starsData
	) {
		super(
			id,
			name,
			pos,
			spriteSheets,
			type,
			dialogues,
			missions,
			profileImage
		)
		this.#gameType = gameType
		this.#starsData.earned = starsData.earned
	}

	create(scene, bodyType, onClick, axiosSupreme, missionIsCompleted) {
		super.create(scene, bodyType, onClick, axiosSupreme, missionIsCompleted)

		// ----- CREATE STARS
		const referencePoint = {
			x: this.pos.x,
			y: this.pos.y - this.sprite.height / 2 - 20,
		}
		const starWidth = 64
		const gapSize = 13
		const starOrigin = { x: 0, y: 1 }
		const starsStartPoint = {
			x:
				referencePoint.x -
				(this.#starsData.total * starWidth +
					(this.#starsData.total - 1) * gapSize) /
					2,
			y: referencePoint.y,
		}
		const spaceBetweenStarsFromSamePoint = gapSize + starWidth

		for (let i = 0; i < this.#starsData.total; i++) {
			// const newStar = new Image('starFull', starsStartPoint, starOrigin)
			// newStar.create(scene)
			// newStar.image.setDisplaySize(64, 64)
			// if (i < this.#starsData.earned) {
			// 	newStar.image.setTint(0xffffff)
			// } else {
			// 	newStar.image.setTint(0x000000)
			// }

			let newStar = null
			if (i < this.#starsData.earned) {
				newStar = new Image('starFull', starsStartPoint, starOrigin)
			} else {
				newStar = new Image('starEmpty', starsStartPoint, starOrigin)
			}
			newStar.create(scene)
			newStar.image.setDisplaySize(64, 60)

			this.#starsData.items.push(newStar)
			starsStartPoint.x += spaceBetweenStarsFromSamePoint
		}
		// ---------
	}
	onActivityOpen(player, setActivityState, setActivity) {
		super.onActivityOpen(player, setActivityState, setActivity)

		setActivity((d) => ({
			...d,
			isGame: true,
			gameType: this.#gameType,
		}))
	}
	setEarnedStars(earnedStars) {
		if (earnedStars <= this.#starsData.earned) return

		this.#starsData.earned = earnedStars
		let i = 1 // porque si entra acá quiere decir que al menos ganó una.
		for (const star of this.#starsData.items) {
			if (i <= earnedStars) {
				star.image.setTint(0xffffff)
			}
			i++
		}
	}
	onActivityCompleted(updateMission, earnedStars, missionIsCompleted) {
		if (earnedStars <= 0) return
		this.setEarnedStars(earnedStars)
		updateMission(this.missions.id, earnedStars, false)
	}
	onActivityClosed(
		scene,
		missionIsCompleted,
		player,
		setActivityState,
		setActivity,
		gameStatus,
		soundAction
	) {
		super.onActivityClosed(player, setActivityState, setActivity)
		super.randomiseContentItems(setActivity)

		if (!missionIsCompleted(this.missions.id)) return
		if (gameStatus !== 'completed') return

		let onlyXP = false
		if (this.closedAfterFirstTimeCompleted) onlyXP = true
		this.closedAfterFirstTimeCompleted = true

		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			super.getVisualReward(scene, onlyXP)
			if (onlyXP) soundAction('effect', 'TLSI3', 'play')
			else soundAction('effect', 'TLSI5', 'play')
		}, 500)
	}
}

import { useEffect, useRef } from 'react'

import '../../styles/AudioHelpButton.scss'
import { useSceneConfig } from '../../context/SceneConfigProvider'

/**
 * Plays each time the src prop changes, and it plays just once.
 */
const AudioGame = ({ src, index, handleAudioStart, handleAudioEnd }) => {
	const { soundPointers } = useSceneConfig()
	const audio = useRef(new Audio())

	useEffect(() => {
		if (!src) return

		try {
			new URL(src)
		} catch (error) {
			return
		}

		audio.current.src = src
		audio.current.onloadeddata = () => {
			const { volume } = soundPointers.voices
			if (volume !== '0') {
				audio.current.volume = Number.isNaN(Number(volume))
					? 1
					: Number(volume)
				audio.current.play()
			}
		}
		audio.current.onplay = () => {
			handleAudioStart(index)
		}
		audio.current.onended = () => {
			handleAudioEnd(index)
		}
		audio.current.onerror = () => {
			handleAudioEnd(index)
		}
	}, [src])

	return null
}

export default AudioGame

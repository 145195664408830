export class DoorBar {
	#pos
	#origin
	#text
	#className
	#isVisible

	#container
	#element
	get element() {
		return this.#element
	}
	#percentage = 0

	constructor(pos, origin, text, className, isVisible, percentage) {
		this.#pos = pos
		this.#origin = origin
		this.#text = text
		this.#className = className
		this.#isVisible = isVisible
		this.#percentage = percentage ? percentage : 100
	}
	create(scene) {
		// ----- CREATE HTML
		const bar = document.createElement('div')
		bar.classList.add('map__door__bar')

		const slider = document.createElement('div')
		slider.classList.add('map__door__bar__slider')
		slider.style.right = this.#percentage + '%'

		// ----- CREATE HTML
		const textContainer = document.createElement('div')
		textContainer.classList.add('map__door__bar__text__container')

		const span = document.createElement('span')
		span.classList.add('map__door__bar__span')
		span.innerText = this.#text

		const span2 = document.createElement('span')
		span2.classList.add('map__door__bar__span2')
		const star = document.createElement('img')
		star.classList.add('map__door__bar__star')
		// star.setAttribute('src', process.env.PUBLIC_URL + '/images/star.svg')
		star.setAttribute(
			'src',
			process.env.PUBLIC_URL + '/images/starFull.png'
		)
		star.setAttribute(
			'alt',
			'Estrella completada'
		)
		span2.appendChild(star)

		textContainer.appendChild(span2)
		textContainer.appendChild(span)

		bar.appendChild(slider)
		bar.appendChild(textContainer)
		// bar.appendChild(span2)
		// bar.appendChild(span)

		// -------------------

		// ----- CREATE THE HTML PHASER ELEMENT
		this.#element = scene.add
			.dom(0, 0, bar)
			.setOrigin(this.#origin.x, this.#origin.y)

		// ----- CREATE THE CONTAINER WHERE THE ELEMENT LIVES
		this.#container = scene.add.container(this.#pos.x, this.#pos.y)
		this.#container.add(this.#element)

		// ----- ENABLE THE VISIBILITY
		this.enableVisibility(this.#isVisible)
	}
	enableVisibility(visibility) {
		if (visibility) this.#container.alpha = 1
		else this.#container.alpha = 0
	}
}

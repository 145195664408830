import { useState } from 'react'
import ModalContext from '../context/editor/ModalContext'

const ModalProvider = (props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [titleModal, setTitleModal] = useState(false)
	const [contentModal, setContentModal] = useState(false)

	const openModal = () => {
		setIsOpen((prev) => !prev)
		if (isOpen) {
			setContentModal(false)
		}
	}

	return (
		<ModalContext.Provider
			value={{
				isOpen,
				openModal,
				titleModal,
				setTitleModal,
				contentModal,
				setContentModal,
			}}>
			{props.children}
		</ModalContext.Provider>
	)
}

export default ModalProvider

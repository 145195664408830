import '../../styles/TutorialSecuence.scss'

import tutorialNext from '../../assets/images/tutorialNext.png'
import tutorialSkip from '../../assets/images/tutorialSkip.png'
import useResponsiveDivAspectRatio from '../../hooks/useResponsiveDivAspectRatio'
import { useState } from 'react'
import { useSoundAction } from '../../hooks/useSoundAction'
import { useTranslation } from 'react-i18next'

const TutorialSecuence = ({ isConfig, secuence = [], lastList, close }) => {
	const { soundAction } = useSoundAction()
	const [parentRef, childRef] = useResponsiveDivAspectRatio(1 / 1)
	const [actualIndex, setActualIndex] = useState(secuence.length - 1)

	const [isClosing, setIsClosing] = useState(false)
	const onNext = () => {
		soundAction('effect', 'TLSG8', 'play')
		if (actualIndex <= 0) {
			onClose()
		} else {
			setActualIndex(actualIndex - 1)
		}
	}

	const onSkip = () => {
		soundAction('effect', 'TLSG9', 'play')
		onClose()
	}
	const onClose = () => {
		setIsClosing(true)
		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			setComponentStyle({ ...componentStyle, opacity: 0 })
		}, 600)
		const timeout2 = setTimeout(() => {
			clearTimeout(timeout2)
			close()
		}, 800)
	}

	const [componentStyle, setComponentStyle] = useState({ opacity: 1 })

	return (
		<div
			className='tutorialsecuence'
			style={componentStyle}
			ref={parentRef}>
			<div
				className={
					'tutorialsecuence__main' +
					(isClosing
						? isConfig
							? ' tutorialsecuence__main__animate__closetoconfig'
							: ' tutorialsecuence__main__animate__close'
						: '')
				}
				ref={childRef}>
				<div className='tutorialsecuence__main__container'>
					<TwoImages
						isConfig={isConfig}
						secuence={secuence}
						lastList={lastList}
						actualIndex={actualIndex}
					/>
					<Controls
						onNext={onNext}
						onSkip={onSkip}
						actualIndex={actualIndex}
						Text={secuence[actualIndex]?.text}
						hidden={actualIndex === 0 && isConfig}
					/>
				</div>
			</div>
		</div>
	)
}

const TwoImages = ({ isConfig, secuence, lastList, actualIndex }) => {
	return (
		<>
			{secuence.map((element, elementIndex) => {
				if (
					elementIndex > actualIndex - 4 &&
					elementIndex <= actualIndex
				)
					return (
						<picture
							key={elementIndex}
							className={
								'tutorialsecuence__main__container__card' +
								(elementIndex === actualIndex - 1
									? ' tutorialsecuence__main__container__card__animate__next'
									: '')
							}
						>
							{element.imageAvif && (
								<source srcSet={element.imageAvif} type='image/avif' />
							)}
							<img
								className='tutorialsecuence__main__container__card__image'
								src={element.image}
								alt={`secuenicia de tutorial número ${elementIndex}`}
							/>
							{isConfig && lastList && actualIndex === 0 && (
								<div className='tutorialsecuence__main__container__card__info'>
									{lastList.map((element, elementIndex) => (
										<div
											key={elementIndex}
											className='tutorialsecuence__main__container__card__info__section'>
											<picture className='tutorialsecuence__main__container__card__info__section__image'>
												{
													element.imageAvif && (
														<source srcSet={element.imageAvif} type='image/avif' />
													)
												}
												<img
													src={element.image}
													alt={`secuenicia de tutorial número ${elementIndex}`}
												/>
											</picture>
											<div className='tutorialsecuence__main__container__card__info__section__text'>
												{element.text}
											</div>
										</div>
									))}
								</div>
							)}
						</picture>
					)
				return <></>
			})}
		</>
	)
}

const Controls = ({ onNext, onSkip, Text, hidden }) => {
	const { t } = useTranslation()
	return (
		<div className='tutorialsecuence__main__container__controls'>
			<div
				className='tutorialsecuence__main__container__controls__text'
				style={{
					visibility: hidden ? 'hidden' : 'visible'
				}}
			>
				{Text}
			</div>
			<button
				className='tutorialsecuence__main__container__controls__button'
				onMouseDown={onNext}>
				<img src={tutorialNext} alt='flecha siguiente' />
				{t("common.btn_next")}
			</button>
			<button
				className='tutorialsecuence__main__container__controls__button'
				onClick={onSkip}>
				<img src={tutorialSkip} alt='flechas omitir' />
				{t("common.btn_skip")}
			</button>
		</div>
	)
}

export default TutorialSecuence

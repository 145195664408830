import { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { API_MANAGE_STARS } from '../../../../utils/constantsAdmin'

import { FormStars } from './FormStars'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ItemStar = ({ objectStar, setRefreshStars }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT START
	const [stateObjectStar, setStateObjectStar] = useState(undefined)
	useEffect(() => {
		setStateObjectStar(objectStar)
	}, [objectStar])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE STAR
	const UpdateStar = async () => {
		const data_stars = await axiosSupreme(
			'get',
			API_MANAGE_STARS + stateObjectStar.id_star + '/',
			undefined
		)
		setTitleModal(t("pages.editor.components.item_star.update_stars"))
		setContentModal(
			<FormStars
				stateObjectStar={stateObjectStar}
				setStateObjectStar={setStateObjectStar}
				setRefreshStars={setRefreshStars}
			/>
		)
		openModal()
	}

	// DELETE STAR
	const DeleteStar = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_STARS + stateObjectStar.id_star + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshStars((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectStar != null && (
				<div className='starsContent__Container'>
					<div className='starsContent__Container__Left'>
						<img src={stateObjectStar.image_file} alt={t("pages.editor.components.item_star.alt_star")} />
					</div>
					<div className='starsContent__Container__Right'>
						<div className='starsContent__Container__Right__Options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_star'
							) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteStar)
										setMessage(
											t("pages.editor.components.item_star.confirm_deleting_1") +
											stateObjectStar.name +
											'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_star'
							) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateStar}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
						<div className='starsContent__Container__Right__Information'>
							<h4>{t("pages.editor.components.item_star.name")}</h4>
							<p>{stateObjectStar.name}</p>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

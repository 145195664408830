import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../context/editor/ModalContext'
import { BsFillTrashFill, BsFillPencilFill } from 'react-icons/bs'
import { ContentMissionBodyCollapse } from './ContentMissionBodyCollapse'
import { FormContentMission } from './FormContentMission'
import {
	API_CONTENT_MISSION,
	API_CONTENT_MISSION_ADD_ONS,
} from '../../../../utils/constantsAdmin'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ContentMissionCollapse = ({
	idScene,
	idObjectMission,
	objectContentMission,
	setRefreshMissionsContent,
	typeMission,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// OBJECT
	const [stateObjectContentMission, setStateObjectContentMission] =
		useState(undefined)
	useEffect(() => {
		setStateObjectContentMission(objectContentMission)
	}, [])

	// COLLAPSE
	const [isCollapsed, setIsCollapsed] = useState(true)
	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE CONTENT MISSION
	const UpdateContentMission = async () => {
		setTitleModal(t("pages.editor.components.content_mission_collapse.title"))
		setContentModal(
			<FormContentMission
				idObjectMission={idObjectMission}
				objectContentMission={stateObjectContentMission}
				setStateObjectContentMission={setStateObjectContentMission}
				setRefreshMissionsContent={setRefreshMissionsContent}
				idScene={idScene}
				typeMission={typeMission}
			/>
		)
		openModal()
	}

	// DELETE CONTENT MISSION
	const DeleteContentMission = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_CONTENT_MISSION_ADD_ONS +
			objectContentMission.id_mission_content_add_ons +
			'/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			const result_data_mission_content = await axiosSupreme(
				'delete',
				API_CONTENT_MISSION +
				objectContentMission.mission_content.id_mission_content +
				'/',
				undefined
			)
			if (result_data_mission_content.hasOwnProperty('response')) {
				openDelete()
				setTypeMessage('error')
				setMessageAlert(
					t("common.cannot_delete")
				)
				showAlert()
			} else {
				setRefreshMissionsContent((prev) => !prev)
				openDelete()
			}
		}
	}

	return (
		<>
			{stateObjectContentMission !== undefined ? (
				<div>
					<div className='collapseStylesBtn'>
						<div className='title' onClick={toggleCollapse}>
							<h2>
								{stateObjectContentMission.mission_content
									.order +
									'. ' +
									stateObjectContentMission.mission_content
										.text}
							</h2>
						</div>
						<div className='options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_mission_content'
							) &&
								gameUser.permissions?.hasOwnProperty(
									'delete_mission_content_add_ons'
								) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(
											() => DeleteContentMission
										)
										setMessage(								t("pages.editor.components.content_mission_collapse.confirm_deleting_1") +
											objectContentMission
												.mission_content.text +
											'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />{' '}
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_mission_content'
							) &&
								gameUser.permissions?.hasOwnProperty(
									'change_mission_content_add_ons'
								) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateContentMission}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
					</div>
					{!isCollapsed && (
						<ContentMissionBodyCollapse
							key={
								stateObjectContentMission.id_mission_content_add_ons
							}
							stateObjectContentMission={
								stateObjectContentMission
							}
						/>
					)}
				</div>
			) : null}
		</>
	)
}

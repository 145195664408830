import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import { BackButton } from '../../../components/editor/BackButton'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ChallengeAndGameCollapse } from '../../../components/editor/design/ChallengeAndGameTabs/ChallengeAndGameCollapse'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'

export default function ManageChallengeGame() {
	return useAuthNavigator(<PrivatePage />)
}

const PrivatePage = () => {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// RESET SCROLL
	window.scroll(0, 0)

	return (
		<div className='challengeGame'>
			<div className='challengeGame__container'>
				<Alert></Alert>
				<Modal></Modal>
				<ModalDelete></ModalDelete>
				<TitleAdministratorAndMenu name='RETOS Y JUEGOS'></TitleAdministratorAndMenu>
				<BackButton url={'/manage-scene-world'}></BackButton>
				{gameUser.permissions?.hasOwnProperty(
					'view_designer_administration'
				) &&
				(gameUser.permissions?.hasOwnProperty('view_challenge') ||
					gameUser.permissions?.hasOwnProperty('view_game')) ? (
					<div className='adminD'>
						<div className='adminDesign'>
							<div className='adminDesign__container ExtendManageExternal'>
								<div className='adminDesign__container__mainContainerBank'>
									<div className='adminDesign__container__mainContainerBank__Item'>
										<div className='adminDesign__container__mainContainerBank__Item__content'>
											<ChallengeAndGameCollapse />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<ErrorPerms />
				)}
			</div>
		</div>
	)
}

import { useState } from 'react'
import DeleteContext from '../context/editor/DeleteContext'

const DeleteProvider = (props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [message, setMessage] = useState(false)
	const [functionDelete, setFunctionDelete] = useState(undefined)

	const openDelete = () => {
		setIsOpen((prev) => !prev)
	}

	return (
		<DeleteContext.Provider
			value={{
				isOpen,
				openDelete,
				message,
				setMessage,
				functionDelete,
				setFunctionDelete,
			}}>
			{props.children}
		</DeleteContext.Provider>
	)
}

export default DeleteProvider

import React, { useEffect, useRef, useState } from 'react'

import BtnCrossWord from './BtnCrossWord'
import '../../styles/Crossword.scss'
import TimerLine from '../fragments/TimerLine'
import { useGameStatus } from '../../provider/ActivityProvider'
import { useSceneConfig } from '../../context/SceneConfigProvider'
import { PanelOfWords } from '../fragments/ImageOnFlex1'
import backArrow from '../../assets/icons/back.png'

const Crossword = ({ wordsData }) => {
	// Hooks
	const [, setGameStatus, gameMistakesCounter] = useGameStatus()
	const { soundPointers } = useSceneConfig()

	// States
	const [arrayButtons, setArrayButtons] = useState([])
	const [timerlineIsRunning, setTimerlineIsRunning] = useState(false)
	const [wordCompleted, setWordCompleted] = useState([])

	// Refs
	const matrix = useRef([])
	const handlerAudio = useRef(new Audio())

	const words = useRef([])
	const wordsCopy = useRef([])
	const horizontal_words = useRef([])
	const vertical_words = useRef([])
	const min_x = useRef(15)
	const min_y = useRef(15)
	const max_x = useRef(15)
	const max_y = useRef(15)
	const max_length = useRef(30)
	const bussy_col = useRef([])
	const bussy_row = useRef([])
	const counterDrawingWords = useRef([])
	const durationTimerline = useRef(0)
	const dataOfAudios = useRef([])
	const addedWords = useRef([])

	useEffect(() => {
		const arrayOfAudios = new Map()

		const wordsSorted = Array.from(
			new Set(
				wordsData
					.sort((a, b) => a.word.localeCompare(b.word))
					.map((v) => {
						if (v.word.length > max_length.current)
							max_length.current = v.word.length

						arrayOfAudios.set(v.word, v.audio)
						return v.word
					})
			)
		)

		dataOfAudios.current = arrayOfAudios
		words.current = wordsSorted
		wordsCopy.current = [...wordsSorted]
		set_matrix_empty()
		add_words_matrix()
	}, [])

	useEffect(() => {
		if (arrayButtons.length > 0) {
			setTimerlineIsRunning(true)
		}
	}, [arrayButtons])

	const makeSound = (urlAudio) => {
		const { volume } = soundPointers.voices
		if (volume === '0') return
		try {
			new URL(urlAudio)
			handlerAudio.current.src = urlAudio
			handlerAudio.current.onloadeddata = () => {
				handlerAudio.current.volume = Number.isNaN(Number(volume))
					? 1
					: Number(volume)
				handlerAudio.current.play()
			}
		} catch (error) {
			console.error(error)
		}
	}

	const set_matrix_empty = () => {
		let row = []
		let innerMatrix = []
		let letter_obj = { letter: '', show: '', audio: '', words: [] }

		for (let index = 0; index < max_length.current; index++) {
			row.push(letter_obj)
		}

		for (let index = 0; index < max_length.current; index++) {
			innerMatrix.push([...row])
		}

		matrix.current = innerMatrix
	}

	const add_words_matrix = () => {
		add_first_word(words.current[0])
		add_words_to_crossword()
	}

	const add_first_word = (word) => {
		let pos_y = 9
		let pos_x = 14
		let h_word_obj = { word: word, colunm: pos_y, row: pos_x, letters: [] }

		for (let index = 0; index < word.length; index++) {
			let letter_obj = {
				letter: word[index],
				show: '_',
				words: [word],
			}
			if (index === 0) {
				letter_obj.show = word[index]
			}
			matrix.current[pos_x][pos_y] = letter_obj
			set_min_max_matrix(pos_x, pos_y)
			pos_y += 1
			h_word_obj.letters.push(letter_obj)
		}
		counterDrawingWords.current.push({ word, counter: 1 })
		horizontal_words.current.push(h_word_obj)
		wordsCopy.current.shift()
	}

	const add_words_to_crossword = () => {
		let counter_cycles = 0
		while (wordsCopy.current.length > 0 && counter_cycles < 3) {
			for (let i = 0; i < wordsCopy.current.length; i++) {
				const word = wordsCopy.current[i]
				let added = try_add_vertical_word({ word })
				if (added) {
					counterDrawingWords.current.push({ word, counter: 1 })
					wordsCopy.current.splice(i, 1)
					break
				} else continue
			}

			for (let i = 0; i < wordsCopy.current.length; i++) {
				const word = wordsCopy.current[i]
				let added_hor = try_add_horizontal_word({ word })
				if (added_hor) {
					counterDrawingWords.current.push({ word, counter: 1 })
					wordsCopy.current.splice(i, 1)
					break
				} else continue
			}

			counter_cycles += 1
		}

		let newDrawingWords = {}
		counterDrawingWords.current.forEach((c) => {
			if (newDrawingWords[c.word]) {
				newDrawingWords[c.word]++
			} else {
				newDrawingWords[c.word] = c.counter
			}
		})

		const keysDrawingWords = Object.keys(newDrawingWords)

		addedWords.current = wordsData.filter((wordData) =>
			keysDrawingWords.includes(wordData.word)
		)

		counterDrawingWords.current = Object.keys(newDrawingWords).map(
			(word) => ({
				word: word,
				counter: newDrawingWords[word],
			})
		)
		_draw_crossword()
	}

	const set_min_max_matrix = (x, y) => {
		if (x > max_x.current) max_x.current = x
		if (x < min_x.current) min_x.current = x
		if (y > max_y.current) max_y.current = y
		if (y < min_y.current) min_y.current = y
	}

	const try_add_vertical_word = ({ word }) => {
		let can_add_vword = false
		for (
			let h_word = 0;
			h_word < horizontal_words.current.length;
			h_word++
		) {
			if (vertical_words.current.some((item) => item.word === word)) break
			if (
				!(
					word
						.toLowerCase()
						.includes(
							horizontal_words.current[h_word][
								'word'
							].toLowerCase()
						) ||
					horizontal_words.current[h_word]['word']
						.toLowerCase()
						.includes(word.toLowerCase())
				)
			) {
				for (
					let char_index = 0;
					char_index < word.length;
					char_index++
				) {
					let total_letters_match =
						horizontal_words.current[h_word]['word']
							.toLowerCase()
							.split(word[char_index].toLowerCase()).length - 1
					if (total_letters_match > 0) {
						for (
							let char_h_word = 0;
							char_h_word <
							horizontal_words.current[h_word]['word'].length;
							char_h_word++
						) {
							if (
								horizontal_words.current[h_word]['word'][
									char_h_word
								] === word[char_index]
							) {
								let col_match_char = char_h_word
								let valid_to_insert = true
								let pos_y =
									horizontal_words.current[h_word]['row'] -
									char_index
								if (pos_y >= 0) {
									let x =
										col_match_char +
										horizontal_words.current[h_word][
											'colunm'
										]
									if (!bussy_col.current.includes(x)) {
										for (
											let char_ind = 0;
											char_ind < word.length;
											char_ind++
										) {
											let y = pos_y + char_ind
											if (y <= max_y.current) {
												if (
													!(
														matrix.current[y][x][
															'letter'
														].toLowerCase() ===
															word[
																char_ind
															].toLowerCase() ||
														matrix.current[y][x][
															'letter'
														] === ''
													)
												) {
													valid_to_insert = false
												}
											} else {
												valid_to_insert = false
											}
										}
										if (valid_to_insert) {
											let v_word_obj = {
												word: word,
												colunm: x,
												row: pos_y,
												letters: [],
											}
											for (
												let char_index_3 = 0;
												char_index_3 < word.length;
												char_index_3++
											) {
												let letter_obj = {
													letter: word[char_index_3],
													show: '_',
													words: [word],
												}

												if (char_index_3 === 0) {
													const prevData =
														matrix.current[
															pos_y + char_index_3
														][x]

													if (
														prevData?.words
															?.length > 0
													) {
														letter_obj.words = [
															...letter_obj.words,
															...prevData.words,
														]
													}

													letter_obj.show =
														word[char_index_3]

													letter_obj.words.push(word)
													letter_obj.words =
														Array.from(
															new Set(
																letter_obj.words
															)
														)

													if (
														letter_obj.words
															.length > 1 &&
														letter_obj.show !== '_'
													) {
														letter_obj.words
															.filter(
																(saveWords) =>
																	saveWords !==
																	word
															)
															.forEach(
																(saveWords) => {
																	const indexExistWord =
																		counterDrawingWords.current.findIndex(
																			(
																				existDataWord,
																				wordIndex
																			) =>
																				existDataWord.word ===
																				saveWords
																		)
																	if (
																		indexExistWord !==
																		-1
																	) {
																		counterDrawingWords
																			.current[
																			indexExistWord
																		]
																			.counter++
																	} else {
																		counterDrawingWords.current.push(
																			{
																				word,
																				counter: 1,
																			}
																		)
																	}
																}
															)
													}
												}

												if (
													matrix.current[
														pos_y + char_index_3
													][x][
														'letter'
													].toLowerCase() ===
														word[
															char_index_3
														].toLowerCase() &&
													char_index_3 !== 0
												) {
													letter_obj =
														matrix.current[
															pos_y + char_index_3
														][x]
													letter_obj.words.push(word)
													letter_obj.words =
														Array.from(
															new Set(
																letter_obj.words
															)
														)

													if (
														letter_obj.words
															.length > 1 &&
														letter_obj.show !== '_'
													) {
														letter_obj.words
															.filter(
																(saveWords) =>
																	saveWords !==
																	word
															)
															.forEach(
																(saveWords) => {
																	const indexExistWord =
																		counterDrawingWords.current.findIndex(
																			(
																				existDataWord,
																				wordIndex
																			) =>
																				existDataWord ===
																				saveWords
																		)
																	if (
																		indexExistWord !==
																		-1
																	) {
																		counterDrawingWords
																			.current[
																			indexExistWord
																		]
																			.counter++
																	} else {
																		counterDrawingWords.current.push(
																			{
																				word,
																				counter: 1,
																			}
																		)
																	}
																}
															)
													}
												}
												v_word_obj['letters'].push(
													letter_obj
												)
												matrix.current[
													pos_y + char_index_3
												][x] = letter_obj
												set_min_max_matrix(
													pos_y + char_index_3,
													x
												)
											}
											can_add_vword = true
											bussy_col.current.push(x)
											vertical_words.current.push(
												v_word_obj
											)
											return can_add_vword
										} else {
											valid_to_insert = false
											break
										}
									}
								}
							}
						}
					} else {
						continue
					}
				}
			}
		}
		return can_add_vword
	}

	const try_add_horizontal_word = ({ word }) => {
		let can_add_hword = false
		for (let v_word = 0; v_word < vertical_words.current.length; v_word++) {
			if (horizontal_words.current.some((item) => item.word === word))
				break

			if (
				!(
					word
						.toLowerCase()
						.includes(
							vertical_words.current[v_word]['word'].toLowerCase()
						) ||
					vertical_words.current[v_word]['word']
						.toLowerCase()
						.includes(word.toLowerCase())
				)
			) {
				for (
					let char_index = 0;
					char_index < word.length;
					char_index++
				) {
					let row_match_char = vertical_words.current[v_word][
						'word'
					].indexOf(word[char_index])
					if (row_match_char !== -1) {
						let valid_to_insert = true
						let pos_x =
							vertical_words.current[v_word]['colunm'] -
							char_index

						if (pos_x >= 0) {
							let y =
								row_match_char +
								vertical_words.current[v_word]['row']
							if (!bussy_row.current.includes(y)) {
								for (
									let char_ind = 0;
									char_ind < word.length;
									char_ind++
								) {
									let x = pos_x + char_ind
									if (x <= max_x.current) {
										if (
											!(
												matrix.current[y][x][
													'letter'
												].toLowerCase() ===
													word[
														char_ind
													].toLowerCase() ||
												matrix.current[y][x][
													'letter'
												] === ''
											)
										) {
											valid_to_insert = false
										}
									} else valid_to_insert = false
								}

								if (valid_to_insert) {
									const h_word_obj = {
										word: word,
										colunm: pos_x,
										row: y,
										letters: [],
									}
									for (
										let char_ind = 0;
										char_ind < word.length;
										char_ind++
									) {
										let letter_obj = {
											letter: word[char_ind],
											show: '_',
											words: [word],
										}
										// const letter_obj = {"letter": word[char_ind], "show": "_", "audio": get_url_audio_from_text(word)}
										if (char_ind === 0) {
											const prevData =
												matrix.current[y][
													pos_x + char_ind
												]

											if (prevData?.words?.length > 0) {
												letter_obj.words = [
													...letter_obj.words,
													...prevData.words,
												]
											}

											letter_obj['show'] = word[char_ind]
											// letter_obj["number h"] = horizontal_words.current.length + 1
											letter_obj.words.push(word)
											letter_obj.words = Array.from(
												new Set(letter_obj.words)
											)

											if (
												letter_obj.words.length > 1 &&
												letter_obj.show !== '_'
											) {
												letter_obj.words
													.filter(
														(saveWords) =>
															saveWords !== word
													)
													.forEach((saveWords) => {
														const indexExistWord =
															counterDrawingWords.current.findIndex(
																(
																	existDataWord,
																	wordIndex
																) =>
																	existDataWord.word ===
																	saveWords
															)
														if (
															indexExistWord !==
															-1
														) {
															counterDrawingWords
																.current[
																indexExistWord
															].counter++
														} else {
															counterDrawingWords.current.push(
																{
																	word,
																	counter: 1,
																}
															)
														}
													})
											}
										}

										if (
											matrix.current[y][pos_x + char_ind][
												'letter'
											] === word[char_ind] &&
											char_ind !== 0
										) {
											letter_obj =
												matrix.current[y][
													pos_x + char_ind
												]
											letter_obj.words.push(word)
											letter_obj.words = Array.from(
												new Set(letter_obj.words)
											)

											if (
												letter_obj.words.length > 1 &&
												letter_obj.show !== '_'
											) {
												letter_obj.words
													.filter(
														(saveWords) =>
															saveWords !== word
													)
													.forEach((saveWords) => {
														const indexExistWord =
															counterDrawingWords.current.findIndex(
																(
																	existDataWord,
																	wordIndex
																) =>
																	existDataWord ===
																	saveWords
															)
														if (
															indexExistWord !==
															-1
														) {
															counterDrawingWords
																.current[
																indexExistWord
															].counter++
														} else {
															counterDrawingWords.current.push(
																{
																	word,
																	counter: 1,
																}
															)
														}
													})
											}
										}
										h_word_obj['letters'].push(letter_obj)
										matrix.current[y][pos_x + char_ind] =
											letter_obj
										set_min_max_matrix(y, pos_x + char_ind)
									}
									can_add_hword = true
									bussy_row.current.push(y)
									horizontal_words.current.push(h_word_obj)
								}
							} else {
								valid_to_insert = false
								break
							}
						}
						break
					}
				}
			}
		}

		return can_add_hword
	}

	const _draw_crossword = () => {
		const final_buttons = []
		for (let box_x = min_x.current; box_x <= max_x.current; box_x++) {
			for (let box_y = min_y.current; box_y <= max_y.current; box_y++) {
				final_buttons.push(matrix.current[box_x][box_y])
			}
		}

		const secondsTimerline = counterDrawingWords.current.reduce(
			(duration, arrayWord) => {
				const secondsForWord =
					arrayWord.word.split('').filter((letter) => letter !== ' ')
						.length * 4
				duration += secondsForWord

				return duration
			},
			0
		)

		durationTimerline.current = secondsTimerline * 1000

		matrix.current = []
		setArrayButtons(final_buttons)
		// setRerendering(true)
	}

	const updateLetterOfArray = (index, letter) => {
		setArrayButtons((prevState) => {
			prevState[index].show = letter
			return prevState
		})
	}

	const updateCounterDrawingWords = ({ word, dataButton }) => {
		let counterCorrects = 0

		counterDrawingWords.current.forEach((arrayWord, wordCountIndex) => {
			if (word.includes(arrayWord.word)) {
				counterDrawingWords.current[wordCountIndex].counter += 1
			}

			// Verify if game finished
			if (
				counterDrawingWords.current[wordCountIndex].counter ===
				arrayWord.word.split(' ').join('').length
			) {
				counterCorrects++

				if (!wordCompleted.includes(arrayWord.word)) {
					setWordCompleted((prevWords) => [
						...prevWords,
						arrayWord.word,
					])
				}
			}
		})

		if (counterCorrects === counterDrawingWords.current.length) {
			gameMistakesCounter.current = 0
			setGameStatus('completed')
			// calculateAnswersUser()
		}
	}

	const calculateAnswersUser = () => {
		let counterCorrectWords = 0

		const lengthOfWords = counterDrawingWords.current.length
		counterDrawingWords.current.forEach((arrayWord) => {
			const { length } = arrayWord.word
				.split('')
				.filter((letter) => letter !== ' ')

			if (length === arrayWord.counter) {
				counterCorrectWords++
			}
		})

		const percentageCorrectWords = counterCorrectWords / lengthOfWords

		if (percentageCorrectWords < 0.25) {
			setGameStatus('gameOver')
		} else if (
			percentageCorrectWords >= 0.25 &&
			percentageCorrectWords < 0.5
		) {
			gameMistakesCounter.current = 2
			setGameStatus('completed')
		} else if (
			percentageCorrectWords >= 0.5 &&
			percentageCorrectWords < 0.99
		) {
			setGameStatus('completed')
			gameMistakesCounter.current = 1
		} else {
			gameMistakesCounter.current = 0
			setGameStatus('completed')
		}
	}

	const onAnimationEnd = () => {
		calculateAnswersUser()
		setTimerlineIsRunning(false)
	}

	return (
		<>
			<TimerLine
				duration={durationTimerline.current}
				isRunning={timerlineIsRunning}
				onAnimationEnd={onAnimationEnd}
				className='positionTopFixed'
			/>
			<div className='crossword'>
				<div className='crosswordPanelLeft'>
					<div className='playground__main__bar__container__crossword'>
						<p>Moverse entre los cuadros</p>

						<picture className='playground__main__bar__container__crossword__container'>
							<img
								src={backArrow}
								alt='Flecha hacia arriba'
								loading='lazy'
								decoding='async'
							/>
							<picture>
								<img
									src={backArrow}
									alt='Flecha hacia la izquierda'
									loading='lazy'
									decoding='async'
								/>
								<img
									src={backArrow}
									alt='Flecha hacia abajo'
									loading='lazy'
									decoding='async'
								/>
								<img
									src={backArrow}
									alt='Flecha hacia la derecha'
									loading='lazy'
									decoding='async'
								/>
							</picture>
						</picture>
					</div>
					<PanelOfWords
						wordsData={addedWords.current}
						isCrossword
						wordCompleted={wordCompleted}
					/>
				</div>
				<div
					className='crosswordContainer'
					style={{
						gridTemplateColumns: `repeat(${
							max_y.current - min_y.current + 1
						}, 3rem)`,
					}}>
					{arrayButtons.map((dataButton, botonIndex) => (
						<BtnCrossWord
							dataButton={dataButton}
							key={botonIndex}
							botonIndex={botonIndex}
							updateCounterDrawingWords={
								updateCounterDrawingWords
							}
							updateLetterOfArray={updateLetterOfArray}
							dataOfAudios={dataOfAudios.current}
							makeSound={makeSound}
							x={botonIndex % (max_y.current - min_y.current + 1)}
							y={Math.floor(
								botonIndex / (max_y.current - min_y.current + 1)
							)}
						/>
					))}
				</div>
			</div>
		</>
	)
}

export default Crossword

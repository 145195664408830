import React, { useEffect, useState } from 'react'

import { API_MANAGE_ADD_GROUP } from '../../../../utils/constantsAdmin'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const GroupToAdd = ({
	idUser,
	objectNoGroups,
	setRefreshUserGroup,
	setRefreshNoGroups,
}) => {
	const { gameUser, axiosSupreme } = useAuthProvider()
	// SET OBJECT GROUPS
	const [stateObjectNoGroup, setStateObjectNoGroup] = useState(undefined)
	useEffect(() => {
		setStateObjectNoGroup(objectNoGroups)
	}, [objectNoGroups])

	const addGroup = async () => {
		// MAKE DICT
		let formData = new FormData()
		formData.append('id_user', idUser)
		formData.append('id_group', stateObjectNoGroup.id)

		const result_data = await axiosSupreme(
			'post',
			API_MANAGE_ADD_GROUP,
			formData
		)
		setRefreshNoGroups((prev) => !prev)
		setRefreshUserGroup((prev) => !prev)
	}

	return (
		<>
			{stateObjectNoGroup !== undefined ? (
				<div className='groups__container__content__toAdd__content'>
					<div className='text'>
						<h1>{stateObjectNoGroup.name}</h1>
					</div>
					<div className='btn'>
						<button className='btnMas' onClick={addGroup}>
							+
						</button>
					</div>
				</div>
			) : null}
		</>
	)
}

/**
 * Defines an image who can have an animation on the y axis for the moment.
 */
export class Image {
	// Is supposed that you pass just the name of an alredy pre-load image.
	#imageName
	#pos
	get pos() {
		return this.#pos
	}
	#origin
	#image
	get image() {
		return this.#image
	}

	constructor(imageName, pos, origin) {
		this.#imageName = imageName
		this.#pos = { ...pos, y: pos.y }
		this.#origin = { ...origin, y: origin.y }
	}

	create(scene) {
		this.#image = scene.add.image(this.#pos.x, this.#pos.y, this.#imageName)
		this.image.setOrigin(this.#origin.x, this.#origin.y)
	}
}

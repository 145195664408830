import React, { useState } from 'react'
import '../../styles/WordWithImage.scss'

import { useGameStatus } from '../../provider/ActivityProvider'

import Lives from '../fragments/Lives'
import ImageOnFlex1 from '../fragments/ImageOnFlex1'
import AudioGame from '../fragments/AudioGame'
import { useSoundAction } from '../../hooks/useSoundAction'

/**
 * @type {Array<String>} colors the colors the cards are going to take when completed,
 * so the user can differenciate between them
 */
const colors = [
	'#ADF1F7',
	'#ADF7D8',
	'#CFCCFF',
	'#F2B7F7',
	'#BDD9FF',
	'#ADF1F7',
	'#ADF7D8',
	'#CFCCFF',
	'#F2B7F7',
	'#BDD9FF',
	'#ADF1F7',
	'#ADF7D8',
	'#CFCCFF',
	'#BDD9FF',
]

// ------------------------------------------------------------- COMPONENTS STATES
/**
 * @typedef {Object} states Contains the different classNames (visual states) a component can have.
 */
const heartStates = {
	normal: 'playground__wwi__hearts__section__container',
	faded: 'playground__wwi__hearts__section__container playground__wwi__hearts__section__container__faded',
}
// SELECTABLE, NOTSELECTABLE, SELECTED, INVISIBLE, COMPLETED, DISABLED.
const cardStates = {
	selectable: 'playground__wwi__card playground__wwi__card__selectable',
	notSelectable:
		'playground__wwi__card playground__wwi__card__not__selectable',
	selected: 'playground__wwi__card playground__wwi__card__selected',
	invisible: 'playground__wwi__card playground__wwi__card__invisible',
	wrong: 'playground__wwi__card playground__wwi__card__wrong',
	completed: 'playground__wwi__card playground__wwi__card__completed',
	disabled: 'playground__wwi__card playground__wwi__card__disabled',
}

const imageStates = {
	visible:
		'playground__wwi__section__image playground__wwi__section__image__animation__visible',
	invisible:
		'playground__wwi__section__image playground__wwi__section__image__animation__invisible',
}

// ------------------------------------------------------------- COMPONENTS
/**
 * Renders a game with the words in wordsData passed by the Playground Component
 * @param {Array<Object>} wordsData Each object has a word, translation, audio, image, etc...
 * @returns {JSX}
 */
export default function WordWithImage({ wordsData }) {
	const [_, setGameStatus, gameMistakesCounter] = useGameStatus()
	const { soundAction } = useSoundAction()

	/**
	 * @type {String} audioSRC the url of the audio of the word.
	 * @type {String} finalGameState The state of the game inProgress, gameOver, completed.
	 */
	const [audioSRC, setAudioSRC] = useState('')
	const [finalGameState, setFinalGameState] = useState('inProgress') // gameOver, completed
	const [mistakesCounter, setMistakesCounter] = useState(0)

	/**
	 * @type {Number} rightAnswersCounter the number of cards the user has completed
	 * @type {String} imageState the state of the main image (className)
	 * @type {Array<Array<Object>, Array<Object>} options an array that represents the column
	 * of options in the game
	 * @typedef option
	 * @property {String} state the visual state of the card (className)
	 * @property {Object} styleCompletedBackgroundColor the style argument of the div
	 * @property {Object} positionInWordsData literally the pointer to wordsData which contains
	 * the word, translation, image and audio.
	 */
	const [rightAnswersCounter, setRightAnswersCounter] = useState(0)
	const [imageState, setImageState] = useState('visible')
	const [options, setOptions] = useState([])

	// CREATE THE CARDS IN THE FIRST AND ONLY IN THE FIRST RENDER.
	if (options.length === 0) {
		const newOptions = []

		// ----- #1 CREATE A RANDOM ARRAY OF OPTIONS

		let positionInWordsData = 0
		for (const _ of wordsData) {
			const newOption = {
				state: 'selectable',
				styleCompletedBackgroundColor: { backgroundColor: '' },
				positionInWordsData: positionInWordsData,
			}
			newOptions.push(newOption)
			positionInWordsData++
		}

		// ----- #2 SHUFFLE THAT ARRAY :D

		let alreadyRandomisedItemsAmount = 1 // It starts with one :v... I know, I know.
		do {
			const rangeToPickARandom =
				newOptions.length - alreadyRandomisedItemsAmount
			const randomIndex = Math.round(Math.random() * rangeToPickARandom)
			newOptions.push(newOptions.splice(randomIndex, 1)[0])
			alreadyRandomisedItemsAmount++
		} while (alreadyRandomisedItemsAmount <= newOptions.length)

		// ----- #3 SET THE BRAND NEW CARDS, OOUUUUU completed
		setOptions(newOptions)
	}

	/**
	 * @param {String} optionPos The index of the option selected in the options array
	 * @returns
	 */
	function onOptionClicked(optionPos) {
		// So... what do I want to do now?

		// ----- #1 GET THE SELECTED OPTION.
		const selectedOption = { ...options[optionPos] }

		// ----- #2 RETURN IF:
		// THE SELECTED CARD IS NOT SELECTABLE.
		if (selectedOption.state !== 'selectable') return

		// ----- #3 CHANGE STATE OF THIS CARD TO SELECTED
		setOptions((opts) =>
			opts.map((option, index) => {
				if (index !== optionPos) return option

				return { ...option, state: 'selected' }
			})
		)

		// ----- #3 CHECK IF THE OPTION IS VALID ******************************
		if (selectedOption.positionInWordsData === rightAnswersCounter) {
			// ----- IS VALID :D

			// ----- PLAY THE AUDIO
			soundAction('effect', 'TLIJ1', 'play')
			setAudioSRC(wordsData[selectedOption.positionInWordsData].audio)

			// ----- #4 CHANGE THE STATE OF THE OPTION TO COMPLETED
			setOptions((opts) =>
				opts.map((option, index) => {
					if (index !== optionPos) return option

					return {
						...option,
						state: 'completed',
						styleCompletedBackgroundColor: {
							...option.styleCompletedBackgroundColor,
							backgroundColor: colors[rightAnswersCounter],
						},
					}
				})
			)

			// ----- #5 CHANGE THE REST OF THE CARDS IN THAT COLUMN TO INVISIBLE
			setOptions((opts) =>
				opts.map((option, index) => {
					if (index === optionPos || option.state !== 'selectable')
						return option

					return { ...option, state: 'invisible' }
				})
			)

			// ----- #6 DONT WAIT TILL ANIMATION COMPLETED TO INFORM THE USER THE GAME IS WIN
			if (rightAnswersCounter + 1 >= options.length) {
				setFinalGameState('completed')
				const timeout = setTimeout(() => {
					clearTimeout(timeout)
					setGameStatus('completed')
				}, 600)
			} else {
				// ----- #7 WAIT A LITTLE THE AUDIO BEFORE CHANGING
				const visualTimeout = setTimeout(() => {
					clearTimeout(visualTimeout)

					// ----- #7 MAKE THE IMAGE SECTION INVISIBLE
					setImageState('invisible')

					// ----- #7 WAIT TILL INVISIBLE COMPLETES
					const imageInvisibleTimeout = setTimeout(() => {
						clearTimeout(imageInvisibleTimeout)

						// ----- #8 LET THE IMAGE WHITE FOR A MOMENT (WAIT TILL IS LOADS)
						setRightAnswersCounter(rightAnswersCounter + 1)
						const whiteTimeout = setTimeout(() => {
							clearTimeout(whiteTimeout)

							// ----- #7 MAKE THE IMAGE SECTION VISIBLE AGAIN
							setImageState('visible')

							const waitTimeout = setTimeout(() => {
								clearTimeout(waitTimeout)

								// ----- #8 MAKE ALL THE INVISIBLE OPTIONS SELECTED AGAIN.
								setOptions((opts) =>
									opts.map((option, index) => {
										if (option.state !== 'invisible')
											return option

										return {
											...option,
											state: 'selectable',
										}
									})
								)
							}, 400)
						}, 400)
					}, 300)
				}, 1500)
			}
		} else {
			// ----- IS INCORRECT :C
			// playAudio('error')

			// ----- #4 CHANGE THE STATE OF THE OPTION TO WRONG
			setOptions((opts) =>
				opts.map((option, index) => {
					if (index !== optionPos) return option

					return {
						...option,
						state: 'wrong',
					}
				})
			)

			// ----- #5 DELAY THE NEXT STEPS UNTIL THE WRONG ANIMATION ENDS
			const wrongAnimationEnd = setTimeout(() => {
				clearTimeout(wrongAnimationEnd)
				// ----- #7 RETURN THE WRONG TO SELECTABLE
				setOptions((opts) =>
					opts.map((option, index) => {
						if (index !== optionPos) return option

						return { ...option, state: 'selectable' }
					})
				)

				// ----- #9 INCREASE MISTAKES COUNTER
				gameMistakesCounter.current += 1
				setMistakesCounter(mistakesCounter + 1)
			}, 300)
		}
	}

	/**
	 * Called when the audio starts.
	 * Sets the NPCprofile expression to talking.
	 */
	const handleAudioStart = () => {}

	/**
	 * Called when the audio ends.
	 */
	const handleAudioEnd = () => {}

	const onDeath = () => {
		setFinalGameState('gameOver')
		setGameStatus('gameOver')

		// ----- #10 DISABLE ALL NON COMPLETED PAIRS
		setOptions((opts) =>
			opts.map((option, index) => {
				if (option.state === 'completed') return option

				return { ...option, state: 'disabled' }
			})
		)
	}

	const livesAmounts = [1, 2, 3, 4, 5, 6, 7]

	return (
		<div className='playground__game playground__wwi' id='playground__wwi'>
			<Lives
				amount={wordsData.length}
				mistakesCounter={mistakesCounter}
				onDeath={onDeath}
				columnsAmount={wordsData.length}
			/>
			<div
				className='playground__wwi__section'
				id='playground__wwi__section'>
				<div
					className='playground__wwi__section__options'
					id='playground__wwi__section__options'>
					{options.map((option, index) => {
						const wordData = wordsData[option.positionInWordsData]
						return (
							<Card
								key={index}
								position={index}
								state={option.state}
								data={wordData}
								onOptionClicked={onOptionClicked}
								styleCompletedBackgroundColor={
									option.styleCompletedBackgroundColor
								}></Card>
						)
					})}
				</div>
				<div
					className={
						imageState === 'visible'
							? imageStates.visible
							: imageStates.invisible
					}>
					<ImageOnFlex1 src={wordsData[rightAnswersCounter].image} />
				</div>
			</div>
			<AudioGame
				src={audioSRC}
				handleAudioStart={handleAudioStart}
				handleAudioEnd={handleAudioEnd}></AudioGame>
		</div>
	)
}

/**
 *
 * @param {Number} position the index of the card in the column of cards
 * @param {String} state the visual state of the card (not className)
 * @param {Object} data the object that contains the wordsData: name, translation, image, audio.
 * @param {Function} onOptionClicked The function to execute when the user clicks the card
 * @param {Object} styleCompletedBackgroundColor The style of the div
 * @returns
 */
const Card = ({
	position,
	state,
	data,
	onOptionClicked,
	styleCompletedBackgroundColor,
}) => {
	/**
	 * @type {String} actualCardState The className calculated with the state
	 */
	let actualCardState
	if (state === 'selectable') actualCardState = cardStates.selectable
	if (state === 'notSelectable') actualCardState = cardStates.notSelectable
	if (state === 'selected') actualCardState = cardStates.selected
	if (state === 'invisible') actualCardState = cardStates.invisible
	if (state === 'wrong') actualCardState = cardStates.wrong
	if (state === 'completed') actualCardState = cardStates.completed
	if (state === 'disabled') actualCardState = cardStates.disabled

	return (
		<div
			className={actualCardState}
			style={styleCompletedBackgroundColor}
			onClick={() => {
				onOptionClicked(position)
			}}>
			<div className='playground__wwi__card__content'>
				{state === 'completed' ? (
					<div className='playground__wwi__card__content__translation'>
						{data.translation}
					</div>
				) : (
					'--------'
				)}
				<div className='playground__wwi__card__content__text'>
					{data.word}
				</div>
			</div>
		</div>
	)
}

import { useEffect, useState } from "react"
import { updateProgresBarData } from "../sceneCores/progressBar"

export const useLocalFillProgressBarAtInit = (
	axiosSupreme,
	profileData,
	sceneData,
	actualDoor,
	doors,
	setProgressBarData,
	progressBarDataRef,
	levelResults,
	levelResume,
	missionIsCompleted,
	NPCS
) => {
	// fill the progress bar data for the first time, so it can renders once the game is loaded.
	const [phaserCreateAlreadyCalled, setPhaserCreateAlreadyCalled] =
		useState(false)
	useEffect(() => {
		if (!phaserCreateAlreadyCalled || actualDoor.isWorld) return

		const execute = async () => {
			await updateProgresBarData(
				axiosSupreme,
				sceneData.progressBarInitData,
				setProgressBarData,
				progressBarDataRef,
				levelResults,
				levelResume,
				sceneData,
				profileData,
				doors.current
			)

			// ----- NPCS
			for (const npc of NPCS.current.class) {
				npc.getActualState(missionIsCompleted)
			}
			for (const npc of NPCS.current.game) {
				npc.getActualState(missionIsCompleted)
			}
		}
		execute()
	}, [phaserCreateAlreadyCalled])

	return [phaserCreateAlreadyCalled, setPhaserCreateAlreadyCalled]
}

import React, { useState, useRef, useEffect } from 'react'
import '../../styles/WordSearch.scss'

import { useGameStatus } from '../../provider/ActivityProvider.jsx'
import TimerLine from '../fragments/TimerLine.js'

import { Words } from './components/Words.jsx'
import { Soup } from './components/Soup.jsx'

/**
 * Renders a game with the words in wordsData passed by the Playground Component
 * @param {Array<Object>} wordsData Each object has a word, translation, audio, image, etc...
 * @returns {JSX}
 */
const WordSeach = ({ wordsData }) => {
	// --------------------------------------------------------------------- $UNIVERSE
	// --------------------------------------------------------------------- $SISTEMA SOLAR
	// --------------------------------------------------------------------- $VIA LACTEA (POR LA LACTOSA)
	// --------------------------------------------------------------------- $AGUJERO NEGRO (POR EL QUE TIENE ATRÁS)
	// --------------------------------------------------------------------- $TEORÍA DE CUERDAS (PARA QUE SE AMARRE LOS ZAPATOS)
	const matrix = useRef([])
	const words = useRef([])
	const added_words = useRef([])
	const filas_disponibles = useRef([])
	const columnas_disponibles = useRef([])
	const diagonales_disponibles = useRef([])
	const diagonales_disponibles_inv = useRef([])
	const [wordsFound, setWordsFound] = useState([])
	const max_length = useRef(10)
	const durationTimerline = useRef(0)
	const audiosOfWords = useRef(new Map())
	const [, setRenderMatrix] = useState(false)
	const [timerlineIsRunning, setTimerlineIsRunning] = useState(false)
	const [, setGameStatus, gameMistakesCounter] = useGameStatus()

	useEffect(() => {
		added_words.current = []
		words.current = wordsData
			.sort((a, b) => b.word.length - a.word.length)
			.map(({ word }) => word.at(0) + word.slice(1).toLowerCase())

		if (words.current[0].length > max_length.current) {
			max_length.current = words.current[0].length
		}

		set_matrix_empty()
		set_array_available({ isRow: true })
		set_array_available({ isRow: false })
		add_words_matrix()

		setRenderMatrix(true)
		setTimerlineIsRunning(true)

		added_words.current = added_words.current.map(word => word.toLowerCase())

		wordsData.forEach(wordData => {
			const wordReverse = wordData.word.toLowerCase().split('').reverse().join('')
			if (added_words.current.includes(wordData.word.toLowerCase()) || added_words.current.includes(wordReverse)) {
				audiosOfWords.current.set(wordData.word, wordData.audio)
			}
		})

		const timeInMiliseconds = added_words.current.reduce((sum, word) =>
			sum += word.length * 4
		, 0)

		durationTimerline.current = timeInMiliseconds * 1000
	}, [])

	const set_matrix_empty = () => {
		matrix.current = []

		for (let i = 0; i < max_length.current; i++) {
			matrix.current.push(Array(max_length.current).fill(''))
		}
	}

	const add_words_matrix = () => {
		for (let word of words.current) {
			for (let atemp = 0; atemp < max_length.current / 2; atemp++) {
				let isAdded = set_angle_word(word)

				if (isAdded) break
			}
		}
	}

	const set_angle_word = (word) => {
		/**
		1= Horizontal right to left 
		2= Horizontal left to right
		3= Vertical top to down 
		4= Vertical down to top 
		5= diagonal right to left top to down 
		6= diagonal right to left down to top 
		7= diagonal left to right top to down 
		8= diagonal left to right down to top 
		*/
		let angle = Math.floor(Math.random() * 8) + 1
		let added = true

		if (angle === 1) {
			// set_array_available(filas_disponibles)
			added = add_horizontal_word(word)
		}
		else if (angle === 2) {
			// set_array_available(filas_disponibles)
			word = reverse_word(word)
			added = add_horizontal_word(word)
		}
		else if (angle === 3) {
			// set_array_available(columnas_disponibles)
			added = add_vertical_word(word)
		}
		else if (angle === 4) {
			// set_array_available(columnas_disponibles)
			word = reverse_word(word)
			added = add_vertical_word(word)
		}
		else if (angle === 5) {
			set_diagonales_disponibles(word)
			added = add_diagonal_word(word)
		}
		else if (angle === 6) {
			set_diagonales_disponibles(word)
			word = reverse_word(word)
			added = add_diagonal_word(word)
		}
		else if (angle === 7) {
			set_diagonales_disponibles_inv(word)
			added = add_diagonal_word_inv(word)
		}
		else if (angle === 8) {
			set_diagonales_disponibles_inv(word)
			word = reverse_word(word)
			added = add_diagonal_word_inv(word)
		}

		return added
	}

	const reverse_word = (text) => text.split('').reverse().join('')

	const set_array_available = ({ isRow }) => {
		const array = []
		for (let i = 0; i < max_length.current; i++) {
			array.push(i)
		}

		if (isRow) filas_disponibles.current = array
		else columnas_disponibles.current = array
	}

	const add_horizontal_word = (word_to_add) => {
		let can_add_word = true

		if (filas_disponibles.current.length >= 1) {
			// let word = word_to_add
			let fila_disponible = Math.floor(Math.random() * filas_disponibles.current.length)
			let position_row = filas_disponibles.current[fila_disponible]
			filas_disponibles.current.splice(fila_disponible, 1)
			let position_colum = 0

			if ((max_length.current - word_to_add.length) > 0) {
				let diference = max_length.current - word_to_add.length
				position_colum = Math.floor(Math.random() * diference)
			}

			let row_copy = matrix.current[position_row]

			for (let index = 0; index < max_length.current; index++) {
				let cell = row_copy[index + position_colum]
				if (index === word_to_add.length) break
				let letter = word_to_add[index]
				if (!(cell === "" || cell === letter)) {
					can_add_word = false
					break
				}
			}

			if (can_add_word) {
				added_words.current.push(word_to_add)
				for (let index = 0; index < max_length.current; index++) {
					let _cell = row_copy[index]
					if (index === word_to_add.length) break

					let letter = word_to_add[index]
					row_copy[index + position_colum] = letter
				}
			}
			// else add_horizontal_word(word_to_add)
		}
		// else set_angle_word(word_to_add)

		return can_add_word
	}

	const add_vertical_word = (word_to_add) => {
		let can_add_word = true

		if (columnas_disponibles.current.length >= 1) {
			// let word = word_to_add
			let columna_disponible = Math.floor(Math.random() * columnas_disponibles.current.length)
			let position_col = columnas_disponibles.current[columna_disponible]
			columnas_disponibles.current.splice(columna_disponible, 1)
			let position_on_y = 0

			if ((max_length.current - word_to_add.length) > 0) {
				let diference = max_length.current - word_to_add.length
				position_on_y = Math.floor(Math.random() * diference)
			}

			for (let index = 0; index < max_length.current; index++) {
				let cell = matrix.current[index + position_on_y][position_col]
				if (index === word_to_add.length) break

				let letter = word_to_add[index]
				if (!(cell === "" || cell === letter)) {
					can_add_word = false
					break
				}
			}

			if (can_add_word) {
				added_words.current.push(word_to_add)
				for (let index = 0; index < max_length.current; index++) {
					if (index === word_to_add.length) break
					let letter = word_to_add[index]
					matrix.current[index + position_on_y][position_col] = letter
				}
			}
			// else add_horizontal_word(word_to_add)
		}
		// else set_angle_word(word_to_add)
		
		return can_add_word
	}

	const set_diagonales_disponibles = (word) => {
		diagonales_disponibles.current = []
		diagonales_disponibles.current.push([0,0])

		if ((max_length.current - word.length - 2) > 0) {
			for (let i = 1; i < (max_length.current - 2) - word.length; i++) {
				diagonales_disponibles.current.push([0, i])
				diagonales_disponibles.current.push([i, 0])
			}
		}
	}

	const set_diagonales_disponibles_inv = (word) => {
		diagonales_disponibles_inv.current = []
		diagonales_disponibles_inv.current.push([max_length.current - 1, 0])

		if ((max_length.current - word.length - 2) > 0) {
			for (let i = 1; i < (max_length.current - 2) - word.length; i++) {
				diagonales_disponibles_inv.current.push([max_length.current - 1, i])
				diagonales_disponibles_inv.current.push([max_length.current - 1 - i, 0])
			}
		}
	}

	const add_diagonal_word = (word_to_add) => {
		let can_add_word = true

		if (diagonales_disponibles.current.length > 0) {
			let word = word_to_add
			// let _diference = max_length.current - word.length
			let diagonal = diagonales_disponibles.current[0]
			diagonales_disponibles.current.shift()

			for (let index = 0; index < max_length.current; index++) {
				let pos_x = index + diagonal[0]
				let pos_y = index + diagonal[1]
				if (pos_x > max_length.current || pos_y > max_length.current) break
				if (pos_x >= max_length.current) pos_x -= 1 
				
				let cell = matrix.current[pos_x][pos_y]
				if (index === word.length) break
				let letter = word[index]

				if (!(cell === "" || cell === letter)) {
					can_add_word = false
					break
				}
			}

			if (can_add_word) {
				added_words.current.push(word)
				for (let index = 0; index < max_length.current; index++) {
					var pos_x = index + diagonal[0]
					var pos_y = index + diagonal[1]

					if (pos_x >= max_length.current || pos_y >= max_length.current) break
					if (index === word.length) break
					var letter = word[index]
					matrix.current[pos_x][pos_y] = letter
				}
			}
			// else add_diagonal_word(word)
		}
		// else set_angle_word(word_to_add)
		return can_add_word
	}

	const add_diagonal_word_inv = (word_to_add) => {
		let can_add_word = true

		if (diagonales_disponibles_inv.current.length > 0) {
			let word = word_to_add
			let diference = max_length.current - word.length
			let diagonal = diagonales_disponibles_inv.current[0]
			let position_on_y = 0

			if (diference > 0) {
				let posible_position = 0
				if (diference - 2 !== 0) posible_position = Math.floor(Math.random() * (diference - 2))

				diagonal = diagonales_disponibles_inv.current.at(posible_position)
				diagonales_disponibles_inv.current.splice(posible_position, 1)
				if ((max_length.current - word.length) > 0) position_on_y = Math.floor(Math.random() * diference)
			} else diagonales_disponibles_inv.current.shift()

			for (let index = 0; index < max_length.current; index++) {
				let pos_x = diagonal[0] - index - position_on_y
				let pos_y = diagonal[1] + index + position_on_y

				if (pos_x >= max_length.current || pos_y >= max_length.current || pos_x < 0 || pos_y < 0) {
					can_add_word = false 
					break
				}

				let cell = matrix.current[pos_x][pos_y]
				if (index === word.length) break

				let letter = word[index]
				if (!(cell === "" || cell === letter)) {
					can_add_word = false
					break
				}
			}

			if (can_add_word) {
				added_words.current.push(word)
				for (let index = 0; index < max_length.current; index++) {
					let pos_x = diagonal[0] - index - position_on_y
					let pos_y = diagonal[1] + index + position_on_y
					
					if (pos_x >= max_length.current || pos_y >= max_length.current) break
					if (index === word.length) break

					let letter = word[index]
					matrix.current[pos_x][pos_y] = letter
				}
				// else add_diagonal_word(word)
			}
		}
			// els:set_angle_word(word_to_add)
		return can_add_word
	}

	const calculateAnswerUser = (foundWord = 0) => {
		setTimerlineIsRunning(false)
		const percentageCorrectWords = (wordsFound.length + foundWord) / added_words.current.length

		if (percentageCorrectWords < 0.25) {
			setGameStatus('gameOver')
		} else if (
			percentageCorrectWords >= 0.25 &&
			percentageCorrectWords < 0.5
		) {
			gameMistakesCounter.current = 2
			setGameStatus('completed')
		} else if (
			percentageCorrectWords >= 0.5 &&
			percentageCorrectWords < 0.99
		) {
			setGameStatus('completed')
			gameMistakesCounter.current = 1
		} else {
			gameMistakesCounter.current = 0
			setGameStatus('completed')
		}
	}

	const onAnimationEnd = () => {
		calculateAnswerUser()
	}

	const handlerWordFounded = (wordFounded) => {
		setWordsFound(prevState => [...prevState, wordFounded])
	}

	return (
		<div className='containerWordSearch'>
			<TimerLine
				onAnimationEnd={onAnimationEnd}
				isRunning={timerlineIsRunning}
				duration={durationTimerline.current}
			/>

			<div
				className='playground__word__search__main'
			>
				<Words
					audiosOfWords={audiosOfWords.current}
					wordsFound={wordsFound}
				/>

				<Soup
					matrix={matrix.current}
					wordsFound={wordsFound}
					addedWords={added_words.current}
					audiosOfWords={audiosOfWords.current}
					calculateAnswerUser={calculateAnswerUser}
					handlerWordFounded={handlerWordFounded}
				/>
			</div>
		</div>
	)
}

export default WordSeach

import { useState, createContext, useContext, useRef, useEffect } from 'react'

import {
	API_AUTH_LOGOUT,
	ACCESS_TOKEN_API,
	USER_API,
	GAME_LANGUAGES,
} from '../../utils/constants'
import { removeAllItems } from '../../utils/manage_local_storage'

import myCookies from '../../utils/myCookies'
import axios from 'axios'

export const AuthContext = createContext(null)
export const useAuthProvider = () => useContext(AuthContext)

export function AuthProvider({ children }) {
	const [refreshToken, setRefreshToken] = useState(myCookies.get.refresh())
	const [accessToken, setAccessToken] = useState(myCookies.get.access())
	const [user, setUser] = useState(myCookies.get.user())

	const [gameUser, setGameUser] = useState(
		myCookies.get.gameUser() ? myCookies.get.gameUser() : {}
	)
	const [gameLanguageOptions, setGameLanguageOptions] = useState(null)

	const userIsAuthenticated = refreshToken && accessToken
	const [accessTokenPromise, setAccessTokenPromise] = useState(null)
	const accessTokenPromiseRef = useRef(false)

	const updateToken = (type, newToken) => {
		myCookies.set[type](newToken)
		if (type === 'refresh') setRefreshToken(newToken)
		else if (type === 'access') setAccessToken(newToken)
	}

	const updateLocalDataUser = (data) => {
		myCookies.set.user(data)
		setUser(data)
	}

	const tryGettingNewAccessToken = async () => {
		let wasSuccessfulAndRefreshTokenStillValid = false
		let myError
		if (accessTokenPromise || accessTokenPromiseRef.current)
			return [true, undefined, accessTokenPromiseRef.current]

		const promise = axios.post(ACCESS_TOKEN_API, {
			refresh: myCookies.get.refresh(),
		})

		accessTokenPromiseRef.current = promise
		setAccessTokenPromise(promise)

		try {
			const res = await promise
			myCookies.set.access(res.data.access)
			updateToken('access', res.data.access)
			myCookies.set.refresh(res.data.refresh)
			updateToken('refresh', res.data.refresh)

			wasSuccessfulAndRefreshTokenStillValid = true
		} catch (error) {
			console.error(error)
			myError = error
			const refreshTokenWasBlacklisted = true
			if (refreshTokenWasBlacklisted) logout()
		}

		setAccessTokenPromise(null)
		accessTokenPromiseRef.current = null

		return [wasSuccessfulAndRefreshTokenStillValid, myError]
	}

	const logout = async () => {
		if (!userIsAuthenticated) return
		const res = await axios.post(API_AUTH_LOGOUT, {
			refresh: myCookies.get.refresh(),
		})
		if (res.data?.message === 'Success Logout') {
			myCookies.remove.all()
			removeAllItems() // local storage

			updateToken('refresh', null)
			updateToken('access', null)
		}
	}

	const updateGameUser = async () => {
		try {
			const response = await axiosSupreme(
				'get',
				USER_API,
				myCookies.get.gameLanguageID()
			)
			setGameUser(response.data)
			myCookies.set.gameUser(response.data)
		} catch (e) {
			if (!window.location.pathname.includes('/editor'))
				window.location.replace('/')
		}
	}

	const updateGameLanguages = async () => {
		try {
			const response = await axiosSupreme('get', GAME_LANGUAGES)
			setGameLanguageOptions(response)
			return response
		} catch (e) {
			console.error(e)
		}
	}

	const axiosSupreme = async (reqType, url, parameters) => {
		// IF THERE ARE NO COOKIES, THEN LOGOUT THE USER.
		if (!myCookies.get.refresh() || !myCookies.get.access()) {
			updateToken('refresh', null)
			updateToken('access', null)
		}

		let activeAccessTokenPromise
		let repeat

		do {
			repeat = false
			try {
				// IF THERE IS AN ACCESS TOKEN REQUEST ACTIVE, THEN WAIT TILL APROVATION.
				if (activeAccessTokenPromise) {
					await activeAccessTokenPromise
				}

				// TRY TO REQUEST THE PRINCIPAL API YOU ARE HERE FOR.
				const config = myCookies.getConfig()
				let response
				if (reqType === 'get') {
					response = await axios.get(
						!parameters ? url : url + parameters,
						config
					)
				} else if (reqType === 'post')
					response = await axios.post(url, parameters, config)
				else if (reqType === 'put')
					response = await axios.put(url, parameters, config)
				else if (reqType === 'patch')
					response = await axios.patch(url, parameters, config)
				else if (reqType === 'delete')
					response = await axios.delete(
						!parameters ? url : url + parameters,
						config
					)
				return response.data
			} catch (error) {
				// ----- IF THERE WAS AN ERRORESTE ES EL ERROR A MIRAR:  CHECK IF IS AN AUTH ONE
				const invalidAccessToken = error.response?.status === 401
				if (invalidAccessToken) {
					// ----- IF IT IS, THEN TRY TO GET A NEW ACCESS TOKEN,
					// AND ACTIVATE A PROMISE TO MAKE ALL THE NEW REQUESTS WAIT TILL
					// WE GOT A NEW ACCESS TOKEN WHICH BY THE WAY ITS NEEDED TO MAKE THEM.
					const [
						wasSuccessfulAndRefreshTokenStillValid,
						error,
						accessTokenPromise,
					] = await tryGettingNewAccessToken()
					activeAccessTokenPromise = accessTokenPromise

					if (wasSuccessfulAndRefreshTokenStillValid) repeat = true
					else throw error
				} else {
					throw error
				}

				if (!repeat) {
					if (reqType === 'get') {
						const newError = new Error(error)
						if (error.response?.data?.detail) {
							newError.message = error.response.data.detail
						}
						throw newError
					} else {
						throw error
					}
				}
			}
		} while (repeat)
	}

	const peticionesEnParalelo = async (dataRequests) => {
		// IF THERE ARE NO COOKIES, THEN LOGOUT THE USER.
		if (!myCookies.get.refresh() || !myCookies.get.access()) {
			updateToken('refresh', null)
			updateToken('access', null)
		}

		const config = myCookies.getConfig()

		const arrOfRequests = dataRequests.map((dataRequest) =>
			axios[dataRequest.reqType](dataRequest.url, config)
		)

		const responsesOfRequests = await Promise.all(arrOfRequests)
		return responsesOfRequests
	}

	useEffect(() => {
		const init = async () => {
			// ----- #1. GET THE GAME LANGUAGES
			const gameLanguageOptionsCopy = await updateGameLanguages()
			// ----- #2 . IF THERE IS NO GAME_LANGUAGE_ID THEN ASSING IT THE
			// FIRST GAME LANGUAGE AS ITS INIT DEFAULT VALUE
			if (!gameLanguageOptionsCopy) return

			if (
				!myCookies.get.gameLanguageID() ||
				myCookies.get.gameLanguageID() === 'undefined'
			) {
				myCookies.set.gameLanguageID(
					gameLanguageOptionsCopy[0]?.game_language.id_game_language
				)
			}
			// ----- #3. SET THE FIRST GAME USER DATA
			updateGameUser()
		}
		init()
	}, [])

	return (
		<AuthContext.Provider
			value={{
				refreshToken,
				accessToken,
				updateToken,
				user,
				gameUser,
				updateGameUser,
				userIsAuthenticated,
				tryGettingNewAccessToken,
				logout,
				gameLanguageOptions,
				axiosSupreme,
				peticionesEnParalelo,
				updateLocalDataUser,
			}}>
			{children}
		</AuthContext.Provider>
	)
}

import React, { useRef, useState } from 'react'
import '../../styles/BtnCrossWord.scss'

const MOVEMENTS_ALLOWEDS = {
	ArrowDown: 'ArrowDown',
	ArrowUp: 'ArrowUp',
	ArrowRight: 'ArrowRight',
	ArrowLeft: 'ArrowLeft',
}

const BtnCrossWord = ({
	dataButton,
	botonIndex,
	dataOfAudios,
	updateCounterDrawingWords,
	updateLetterOfArray,
	makeSound,
	x,
	y,
}) => {
	const [text, setText] = useState('')
	const isFirstTimeWritting = useRef(false)
	const [error, setError] = useState(false)
	const timeOut = useRef(null)
	const divCorrectRef = useRef()

	const handlerChange = (e) => {
		const { value } = e.target

		isFirstTimeWritting.current = true
		if (value.trim().length > 1) return

		clearTimeout(timeOut.current)
		setError(false)
		setText(value.toLowerCase())
		const valueToSave =
			value.toLowerCase() === '' ? '_' : value.toLowerCase()
		updateLetterOfArray(botonIndex, valueToSave)

		if (value.toLowerCase() === dataButton.letter.toLowerCase()) {
			updateCounterDrawingWords({ word: dataButton.words, dataButton })
			handleClick()
			divCorrectRef.current.focus()
		} else {
			setError(true)
			timeOut.current = setTimeout(function () {
				setText('')
				setError((prev) => !prev)
			}, 2000)
		}
	}

	const handleClick = () => {
		makeSound(dataOfAudios.get(dataButton.words[0]))
	}

	const handlerMoveSquare = (e) => {
		if (MOVEMENTS_ALLOWEDS[e.key]) {
			if (e.key === MOVEMENTS_ALLOWEDS.ArrowDown) {
				const downInput = document.querySelector(
					`input[data-position-x="${x}"][data-position-y="${1 + y}"]`
				)

				if (downInput != null) downInput.focus()
			} else if (e.key === MOVEMENTS_ALLOWEDS.ArrowUp) {
				const upInput = document.querySelector(
					`input[data-position-x="${x}"][data-position-y="${y - 1}"]`
				)

				if (upInput != null) upInput.focus()
			} else if (e.key === MOVEMENTS_ALLOWEDS.ArrowRight) {
				const rightInput = document.querySelector(
					`input[data-position-x="${1 + x}"][data-position-y="${y}"]`
				)

				if (rightInput != null) rightInput.focus()
			} else {
				const leftInput = document.querySelector(
					`input[data-position-x="${x - 1}"][data-position-y="${y}"]`
				)

				if (leftInput != null) leftInput.focus()
			}
		}
	}

	return (
		<div
			className={`btnCrossword
			${dataButton.letter === '' ? 'btnCrosswordHidden' : ''} 
			${
				(text.toLowerCase() === dataButton.letter.toLowerCase() ||
					dataButton.letter === ' ') &&
				dataButton.show !== ''
					? 'btnCrosswordCorrected'
					: ''
			} ${error ? 'btnCrosswordWrong' : ''}
		`}
			onClick={handleClick}
			onKeyDown={handlerMoveSquare}
			tabIndex={0}
			ref={divCorrectRef}>
			{(dataButton.show === '_' ||
				dataButton.show !== dataButton.letter) &&
			dataButton.letter !== ' ' ? (
				<input
					type='text'
					value={text}
					onChange={handlerChange}
					maxLength='1'
					className='btnCrosswordInput'
					placeholder='_'
					readOnly={
						text.toLowerCase() === dataButton.letter.toLowerCase()
					}
					autoComplete='off'
					autoSave='off'
					data-position-x={x}
					data-position-y={y}
				/>
			) : (
				<p>
					{dataButton.letter === ' ' ? ' ' : dataButton?.show || ''}
				</p>
			)}
		</div>
	)
}

export default BtnCrossWord

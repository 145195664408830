import { useSoundAction } from '../../hooks/useSoundAction'

const ZoomButtons = ({ onClick }) => {
	const { soundAction } = useSoundAction()
	return (
		<div className='scene__zoombuttons'>
			<button
				className='scene__zoombuttons__button'
				onClick={() => {
					onClick(true)
					soundAction('effect', 'TLSG4', 'play')
				}}>
				+
			</button>
			<button
				className='scene__zoombuttons__button'
				onClick={() => {
					onClick(false)
					soundAction('effect', 'TLSG5', 'play')
				}}>
				-
			</button>
		</div>
	)
}

export default ZoomButtons

/**
 *
 * Le llevó a la población mundial, 100.000 años llegar a los mil millones,
 * y solo 100 años más llegar a los 2 mil millones.
 * Y solo 50 años duplicarlo a los 4 mil millones en 1970.
 * Hoy estamos cerca de los 8 mil millones.
 *
 * Bartlet da el ejemplo de un matraz.
 * Con solo una bacteria en él, una que se divide y se duplica cada minuto.
 * Si se coloca la primera bacteria en el matraz a las 11 en punto...
 * y está completamente lleno a las 12.
 * ¿A qué hora está el matraz lleno a la mitad?
 * A las 11:59.
 * Es la hora en la que estamos.
 * En 40 años, 32 mil millones de personas lucharán para vivir.
 * Fracasaran.
 * Estamos a un minuto de media noche.
 * Todos los males globales que enferman al planeta tienen su origen
 * en la sobre población humana.
 *
 * Han habido cinco extinciones importantes en la historia de la tierra
 * Y si no hacemos algo radical de inmediato...
 * La sexta extinción...será la nuestra.
 * Estamos a un minuto de media noche.
 *
 * Patogeno viral.
 *
 */

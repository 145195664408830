import React, { useContext, useEffect, useState } from 'react'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import DeleteContext from '../../../../context/editor/DeleteContext'

import { API_MANAGE_GROUPS_ADMIN } from '../../../../utils/constantsAdmin'
import GroupForm from './GroupForm'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const GroupContent = ({ objGroup, setRefreshGroups }) => {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT GROUP
	const [stateObjectGroup, setStateObjectGroup] = useState(undefined)
	useEffect(() => {
		setStateObjectGroup(objGroup)
	}, [objGroup])
	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE GROUP
	const UpdateGroup = async () => {
		const data_result = await axiosSupreme(
			'get',
			API_MANAGE_GROUPS_ADMIN + stateObjectGroup.id + '/',
			undefined
		)
		setTitleModal('Actualizar Grupo')
		setContentModal(
			<GroupForm
				stateObjectGroup={stateObjectGroup}
				setStateObjectGroup={setStateObjectGroup}
				setRefreshGroups={setRefreshGroups}
			/>
		)
		openModal()
	}

	// DELETE GROUP
	const DeleteGroup = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_GROUPS_ADMIN + stateObjectGroup.id + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				'No se ha podido eliminar, existe llave foranea asociada.'
			)
			showAlert()
		} else {
			setRefreshGroups((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectGroup !== undefined ? (
				<tr>
					<td>{stateObjectGroup.name}</td>
					{gameUser.permissions?.hasOwnProperty('change_group') ||
					gameUser.permissions?.hasOwnProperty('delete_group') ? (
						<td>
							{gameUser.permissions?.hasOwnProperty(
								'delete_group'
							) ? (
								<button
									className='btnDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteGroup)
										setMessage(
											'¿Está seguro de que desea eliminar: ' +
												stateObjectGroup.name +
												'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_group'
							) ? (
								<button
									className='btnEdit'
									onClick={UpdateGroup}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</td>
					) : null}
				</tr>
			) : null}
		</>
	)
}

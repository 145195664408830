/**
 * An invisible zone that allows any kind of events when collided with.
 */
export class Zone {
	// *** FOR THIS TO WORK THE UPDATE FUNCTION NEEDS TO BE CALLED ***
	#label
	#parentID
	#pos = { x: 0, y: 0 }
	get pos() {
		return this.#pos
	}
	#size = { x: 0, y: 0 }
	#shape

	#isCollisioning = false
	get isCollisioning() {
		return this.#isCollisioning
	}

	sprite // public because I think am inheriting this from another class later.
	//get sprite() { return this.#sprite }

	constructor(scene, label, parentID, pos, size, shape) {
		this.#label = label
		this.#parentID = parentID
		this.#pos = { x: pos.x, y: pos.y }
		this.#size = size
		this.#shape = shape

		this.#create(scene)
	}
	#create(scene) {
		if (this.#shape === 'circle') {
			this.sprite = scene.matter.add.circle(
				this.#pos.x,
				this.#pos.y,
				this.#size.x
			)
		} else {
			this.sprite = scene.matter.add.rectangle(
				this.#pos.x,
				this.#pos.y,
				this.#size.x,
				this.#size.y
			)
		}
		this.sprite.isSensor = true
		this.sprite.label = { text: this.#label, id: this.#parentID }
	}
}

import '../../../styles/AdminDesign.scss'
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'
import { ListLevelsLanguage } from '../../../components/editor/design/LevelsLanguage/ListLevelsLanguage'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import { ObjectLevelLanguageProvider } from '../../../components/editor/design/LevelsLanguage/ObjectLevelLanguage'
import { Toaster } from 'react-hot-toast'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'
import { useEffect } from 'react'
import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { BackButton } from '../../../components/editor/BackButton'
import { useTranslation } from 'react-i18next'

const Editor = () => {
	return useAuthNavigator(<PrivatePage />)
}

const PrivatePage = () => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// RESET SCROLL
	useEffect(() => {
		window.scroll(0, 0)
	}, [])

	return (
		<div className='adminD'>
			<ObjectLevelLanguageProvider>
				<Alert></Alert>
				<Modal></Modal>
				<ModalDelete></ModalDelete>
				<Toaster></Toaster>
				<TitleAdministratorAndMenu name={t("pages.editor.components.title_admin_menu.content")}></TitleAdministratorAndMenu>
				<BackButton url={'/'}></BackButton>
				{gameUser.permissions?.hasOwnProperty(
					'view_designer_administration'
				) &&
					gameUser.permissions?.hasOwnProperty('view_level_language') ? (
					<ListLevelsLanguage />
				) : (
					<ErrorPerms />
				)}
			</ObjectLevelLanguageProvider>
		</div>
	)
}

export default Editor

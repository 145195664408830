import boton from '../../assets/icons/boton.png'
import '../../styles/ImageOnFlex1.scss'
import useResponsiveDivAspectRatio from '../../hooks/useResponsiveDivAspectRatio'
import AudioHelpButton from './AudioHelpButton'
import { useEffect } from 'react'

const ImageOnFlex1 = ({
	src,
	srcWebm,
	className,
	delay = false,
	aspectRatio = 1000 / 750,
	isVideo = false,
	isGame,
	wordsData,
	children,
	isAnagram,
	currentIndex,
}) => {
	const [parentRef, childRef] = useResponsiveDivAspectRatio(
		aspectRatio,
		undefined,
		delay
	)

	useEffect(() => {
		if (isGame) {
			const [width] = childRef.current.style.width.split('px')
			childRef.current.style.width = `${Number(width || 50) - 50}px`
		}
	}, [isGame, childRef])

	return (
		<div className={'image__on__flex1'} ref={parentRef}>
			{isGame && (
				<PanelOfWords
					wordsData={wordsData}
					isAnagram={isAnagram}
					currentIndex={currentIndex}
				/>
			)}
			<div className='image__on__flex1__container'>
				{!isVideo ? (
					<img
						className={className}
						ref={childRef}
						alt='text support from video'
						src={src}
					/>
				) : (
					<video autoPlay loop ref={childRef}>
						<source src={srcWebm} type='video/webm' />
						<source src={src} type='video/mp4' />
					</video>
				)}
				{children}
			</div>
		</div>
	)
}

export function PanelOfWords({
	wordsData,
	isPanelFloating,
	showPanel,
	handlerShowPanel,
	isAnagram,
	isCrossword,
	currentIndex,
	wordCompleted,
}) {
	return (
		<div
			className={`image__on__flex1__panel_of_words
			${isPanelFloating ? 'image__on__flex1__panel_of_words__floating' : ''}
			${showPanel ? 'image__on__flex1__panel_of_words__floating__show' : ''}
		`}>
			<button
				className='image__on__flex1__panel_of_words__button'
				onClick={handlerShowPanel}>
				<img
					src={boton}
					alt='muestra y oculta el panel'
					loading='lazy'
					decoding='async'
				/>
			</button>
			{wordsData.map((wordData, wordDataIndex) => {
				const countLettersToView =
					~~(~~(wordData.word.length / 2) / 2) + 1

				// Debería poder cortar la palabra si el minijuego es un crucigrama o anagrama
				// y la palabra ya se completo es en panel de palabras
				const shouldSplitingWord =
					(isAnagram || isCrossword) &&
					(wordDataIndex >= currentIndex ||
						(wordCompleted != null &&
							!wordCompleted.includes(wordData.word)))

				return (
					<article key={wordData.order}>
						<div>
							<AudioHelpButton src={wordData.audio} />
						</div>
						<div>
							<p>
								{shouldSplitingWord
									? wordData.word.slice(
											0,
											countLettersToView
									  ) +
									  '-'.repeat(
											wordData.word.length -
												countLettersToView
									  )
									: wordData.word}
							</p>
							<p>{wordData.translation}</p>
						</div>
					</article>
				)
			})}
		</div>
	)
}

export default ImageOnFlex1

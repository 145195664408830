import { useState } from 'react'
import '../../../styles/SearchAndPagination.scss'

import { BsSearch } from 'react-icons/bs'
import { LoadingIcon } from '../LoadingIcon'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'

export function SearchCamp({
	setData,
	setRefreshDataList,
	url,
	search,
	setSearch,
}) {
	const { gameUser, axiosSupreme } = useAuthProvider()
	const [loadingSearch, setLoadingSearch] = useState(false)

	// SUBMIT FORM SEARCH
	const onSubmitSearch = async (event) => {
		event.preventDefault()
		setLoadingSearch(true)
		setSearch((prev) => prev.trim())
		if (search === '') {
			setRefreshDataList((prev) => !prev)
			setLoadingSearch(false)
		} else {
			const result_data = await axiosSupreme(
				'get',
				url + '?search=' + encodeURIComponent(search),
				undefined
			)
			if ('results' in result_data) {
				if (result_data.results.length === 0) {
					setData('search')
				} else {
					setData(result_data)
				}
			} else {
				if (result_data.length === 0) {
					setData('search')
				} else {
					setData(result_data)
				}
			}
			setLoadingSearch(false)
		}
	}

	// EVENT FOR INPUT CHANGE FIELDS
	const onInputChange = (event, setFunction) => {
		setFunction(event.target.value)
	}

	return (
		<form onSubmit={onSubmitSearch}>
			<div className='searchCamp'>
				<input
					className='searchCamp__input'
					type='text'
					value={search}
					onChange={(event) => onInputChange(event, setSearch)}
					placeholder='Buscar'
				/>
				<button className='searchCamp__btn' type='submit'>
					<BsSearch />
				</button>
			</div>
			{loadingSearch ? (
				<div>
					<LoadingIcon />
				</div>
			) : null}
		</form>
	)
}

import { useContext } from 'react'
import '../../styles/Modal.scss'
import DeleteContext from '../../context/editor/DeleteContext'
import { useTranslation } from 'react-i18next'

export default function ModalDelete() {
	const { t } = useTranslation()
	const { isOpen, openDelete, message, functionDelete } =
		useContext(DeleteContext)

	const onClickAnswer = (answer) => {
		if (answer) {
			functionDelete()
		} else {
			openDelete()
		}
	}

	return (
		<>
			<div
				id='myModal'
				className='modalDelete'
				style={{ display: isOpen === true ? 'block' : 'none' }}>
				<div className='modalDelete-content'>
					<span className='close' onClick={openDelete}>
						&times;
					</span>
					<h2>{t("common.delete")}</h2>
					<p>{message}</p>
					<div className='content-btns'>
						<button
							className='btnAdd confirm'
							onClick={() => onClickAnswer(true)}>
							{t("common.confirm")}
						</button>
						<button
							className='btnAdd cancel'
							onClick={() => onClickAnswer(false)}>
							{t("common.cancel")}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

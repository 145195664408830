import { BASE_API } from '../urls'

/**
 * It literally loads all the images and audios that represents the instances of our game.
 * Additionally it has direct comunication with the LoadingScreen.
 */
export default function preload(
	scene,
	updateLoadingBarPercentage,
	loadingBarWasCompleted,
	sceneData,
	player,
	doors,
	NPCS,
	lostItems,
	decorations
) {
	/**
	 * Comunicates to the LoadingScreen telling it the percentage of the resources
	 * preloaded at the moment. Whe it comes to 100% the LoadingScreen acts.
	 */
	// ----- LOADING SCREEN EVENTS
	scene.load.on('progress', (value) => {
		updateLoadingBarPercentage(value)
	})
	/**
	 * Print the file that have been just loaded.
	 */
	scene.load.on('fileprogress', (file) => file.src)
	/**
	 * Prints when it reaches 100% (all of the resources were preloaded)
	 */
	scene.load.on('complete', () => {
		loadingBarWasCompleted()
	})

	// ----- MAP
	// Load body map from JSON file generated using PhysicsEditor
	//scene.load.json('shapes', process.env.PUBLIC_URL + '/map.json')
	scene.load.json('shapes', BASE_API + sceneData.collisionFile)
	scene.load.image('map', BASE_API + sceneData.imageFile)

	// ----- DECORATIONS
	for (const decoration of decorations) decoration.preload(scene)

	// ----- PLAYER
	player.preload(scene)

	// ----- NPCS
	for (const npc of NPCS.class) npc.preload(scene)
	for (const npc of NPCS.game) npc.preload(scene)
	for (const npc of NPCS.lostItem) npc.preload(scene)
	for (const npc of NPCS.easterEgg) npc.preload(scene)

	// ----- DOORS
	for (const door of doors) door.preload(scene)

	// ----- MISSION OBJECTS
	for (const missionObject of lostItems) missionObject.preload(scene)

	// ----- ARROW
	// scene.load.image('arrow', process.env.PUBLIC_URL + '/images/arrow.png')

	// ----- STARS
	scene.load.image(
		'starEmpty',
		process.env.PUBLIC_URL + '/images/starEmpty.png'
	)
	scene.load.image(
		'starFull',
		process.env.PUBLIC_URL + '/images/starFull.png'
	)
}

import React, { useEffect, useState } from 'react'
import { API_MANAGE_DELETE_GROUP } from '../../../../utils/constantsAdmin'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const GroupAdded = ({
	idUser,
	objectGroups,
	setRefreshUserGroup,
	setRefreshNoGroups,
}) => {
	const { gameUser, axiosSupreme } = useAuthProvider()
	// SET OBJECT GROUPS
	const [stateObjectUserGroup, setStateObjectUserGroup] = useState(undefined)
	useEffect(() => {
		setStateObjectUserGroup(objectGroups)
	}, [objectGroups])

	const deleteGroup = async () => {
		// MAKE DICT
		let formData = new FormData()
		formData.append('id_user', idUser)
		formData.append('id_group', stateObjectUserGroup.id)

		const result_data = await axiosSupreme(
			'post',
			API_MANAGE_DELETE_GROUP,
			formData
		)
		setRefreshUserGroup((prev) => !prev)
		setRefreshNoGroups((prev) => !prev)
	}

	return (
		<>
			{stateObjectUserGroup !== undefined ? (
				<div className='groups__container__content__toDel__content'>
					<div className='text'>
						<h1>{stateObjectUserGroup.name}</h1>
					</div>
					<div className='btn'>
						<button className='btnMenos' onClick={deleteGroup}>
							-
						</button>
					</div>
				</div>
			) : null}
		</>
	)
}

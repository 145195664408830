import Cookies from 'js.cookie'

import { DOMAIN, cookiesForDev } from '../urls'

const refresh = 'REFRESH_TOKEN'
const access = 'ACCESS_TOKEN'
const user = 'USER'
const gameUser = 'GAME_USER'
const gameLanguageID = 'GAME_LANGUAGE_ID'

const attrs = {
	path: '/',
	domain: cookiesForDev ? undefined : DOMAIN,
}

const myCookies = {
	get: {
		refresh: () => {
			return Cookies.get(refresh)
		},
		access: () => {
			return Cookies.get(access)
		},
		user: () => {
			return Cookies.get(user) ? JSON.parse(Cookies.get(user)) : {}
		},
		gameUser: () => {
			return Cookies.get(gameUser)
				? JSON.parse(Cookies.get(gameUser))
				: {}
		},
		gameLanguageID: () => {
			return Cookies.get(gameLanguageID)
		},
	},
	set: {
		refresh: (token) => {
			Cookies.set(refresh, token, attrs)
		},
		access: (token) => {
			Cookies.set(access, token, attrs)
		},
		user: (data) => {
			Cookies.set(user, JSON.stringify(data), attrs)
		},
		gameUser: (data) => {
			Cookies.set(gameUser, JSON.stringify(data), attrs)
		},
		gameLanguageID: (token) => {
			Cookies.set(gameLanguageID, token, attrs)
		},
	},
	remove: {
		refresh: () => {
			Cookies.remove(refresh, attrs)
		},
		access: () => {
			Cookies.remove(access, attrs)
		},
		user: () => {
			Cookies.remove(user, attrs)
		},
		gameUser: () => {
			Cookies.remove(gameUser, attrs)
		},
		gameLanguageID: () => {
			Cookies.remove(gameLanguageID, attrs)
		},
	},
}

myCookies.remove = {
	...myCookies.remove,
	all: () => {
		myCookies.remove.refresh()
		myCookies.remove.access()
		myCookies.remove.user()
		myCookies.remove.gameUser()
		myCookies.remove.gameLanguageID()
	},
}

myCookies.getConfig = (params) => {
	return {
		headers: { Authorization: `Bearer ${myCookies.get.access()}` },
		params: params,
	}
}

export default myCookies

import React from 'react'
import { useTranslation } from 'react-i18next'

export default function SaveButton() {
	const {t} = useTranslation()
	return (
		<button
			className='editorBtnActionAdd'
			id='admin__container__boxBtn__btn'
			type='submit'>
			{t("common.save")}
		</button>
	)
}

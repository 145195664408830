import React, { useContext, useEffect, useState } from 'react'
import SaveButton from '../../SaveButton'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_GET_CONSTANTS,
	API_MANAGE_GROUPS_ADMIN,
	API_MANAGE_USERS_ADMIN,
} from '../../../../utils/constantsAdmin'

import { onInputChangeDefault } from '../../../../utils/general_functions_forms'
import { compareString } from '../../../../utils/general_functions'
import { PasswordEditForm } from './PasswordEditForm'
import { MagaUserGroups } from '../UserGroup/MagaUserGroups'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export function UserForm({
	stateObjectUser,
	setStateObjectUser,
	setRefreshUsers,
}) {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	const [contenidoAbierto, setContenidoAbierto] = useState('BasicData')

	const handleClick = (contenido) => {
		setContenidoAbierto(contenido)
	}
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [username, setUsername] = useState('')
	const [name, setName] = useState('')
	const [lastName, setLastName] = useState('')
	const [country, setCountry] = useState('')
	const [email, setEmail] = useState('')
	const [telephone, setTelephone] = useState('')
	const [password, setPassword] = useState('')
	const [group, setGroup] = useState('')

	// GET LIST COUNTRIES
	const [listCountry, setListCountry] = useState(undefined)
	useEffect(() => {
		getListCountries()
	}, [])
	const getListCountries = async () => {
		const result = await axiosSupreme(
			'get',
			API_GET_CONSTANTS + 'country/',
			undefined
		)
		setListCountry(result)
	}
	// GET LIST GROUPS
	const [listGroup, setListGroup] = useState(undefined)
	useEffect(() => {
		getListGroup()
	}, [])
	const getListGroup = async () => {
		if (stateObjectUser === undefined) {
			const result = await axiosSupreme(
				'get',
				API_MANAGE_GROUPS_ADMIN,
				undefined
			)
			setListGroup(result)
		}
	}

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setUsername('')
		setName('')
		setLastName('')
		setCountry('')
		setEmail('')
		setTelephone('')
		setGroup('')
		setPassword('')
	}

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// SET USER WHEN UPDATE
	useEffect(() => {
		if (stateObjectUser !== undefined) {
			setUsername(stateObjectUser.username)
			setName(stateObjectUser.name)
			setLastName(stateObjectUser.last_name)
			if (stateObjectUser.country) {
				setCountry(stateObjectUser.country)
			}
			setEmail(stateObjectUser.email)
			setTelephone(stateObjectUser.telephone)
			setPassword(stateObjectUser.password)
		} else {
			clearFields()
		}
	}, [stateObjectUser])

	// SUBMIT FORM USER
	const onSubmitUser = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		let isValidatePassword = false
		if (stateObjectUser === undefined) {
			if (
				compareString(
					event.target.elements.password.value,
					event.target.elements.confirm_password.value
				)
			) {
				isValidatePassword = false
			} else {
				isValidatePassword = true
				alert('Las contraseñas no coinciden')
			}
		} else {
			isValidatePassword = false
		}
		if (isValidatePassword === false) {
			// MAKE DICT
			let formData = new FormData()
			formData.append('username', event.target.elements.username.value)
			formData.append('name', event.target.elements.name.value)
			formData.append('last_name', event.target.elements.last_name.value)
			formData.append('country', event.target.elements.country.value)
			formData.append('email', event.target.elements.email.value)
			formData.append('telephone', event.target.elements.telephone.value)
			if (stateObjectUser === undefined) {
				formData.append('group', event.target.elements.group.value)
				formData.append(
					'password',
					event.target.elements.password.value
				)
			}
			if (stateObjectUser === undefined) {
				// SAVE
				manageUser(API_MANAGE_USERS_ADMIN, formData, 'add')
			} else {
				// UPDATE
				manageUser(
					API_MANAGE_USERS_ADMIN + stateObjectUser.id + '/',
					formData,
					'update'
				)
			}
		}
	}

	// FUNCTION FOR SAVE OR UPDATE USER
	const manageUser = async (url, obj, action) => {
		// PROCESS DATA
		let result_data =
			action === 'add'
				? await axiosSupreme('post', url, obj)
				: await axiosSupreme('put', url, obj)
		if (result_data.hasOwnProperty('response')) {
			if (result_data.response.status === 403) {
				setTypeMessage('error')
				setMessage(
					'No tienes los permisos suficientes para realizar esta acción'
				)
			}
			if (result_data.response.status === 400) {
				setTypeMessage('error')
				let error = ''
				Object.keys(result_data.response.data).map((key) => {
					error += key
					for (
						let i = 0;
						i < result_data.response.data[key].length;
						i++
					) {
						error +=
							': \t' + result_data.response.data[key][0] + '\n'
					}
					error += '\n'
					return error
				})
				setTypeMessage('error')
				setMessage(error)
			}
			showAlert()
			setStateLoading(false)
		} else {
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add'
					? 'Agregado correctamente'
					: 'Actualizado correctamente'
			)
			showAlert()
			if (action === 'add') setRefreshUsers((prev) => !prev)
			if (action === 'update') setStateObjectUser(result_data)
			setStateLoading(false)
			openModal()
		}
	}

	return (
		<>
			{stateObjectUser === undefined ? (
				<div className='btnsManageUserDataTwo'>
					<button
						className='btnAdd'
						onClick={() => handleClick('BasicData')}>
						Info Basica
					</button>
				</div>
			) : (
				<div className='btnsManageUserData'>
					<button
						className='btnAdd'
						onClick={() => handleClick('BasicData')}>
						Info Basica
					</button>
					{gameUser.permissions?.hasOwnProperty('view_group') &&
					gameUser.permissions?.hasOwnProperty('add_user') &&
					gameUser.permissions?.hasOwnProperty('change_user') ? (
						<button
							className='btnAdd'
							onClick={() => handleClick('ManageUserGroups')}>
							Gestion de Grupos
						</button>
					) : null}
					<button
						className='btnAdd'
						onClick={() => handleClick('ManagePassword')}>
						Gestion de contraseña
					</button>
				</div>
			)}

			{contenidoAbierto === 'BasicData' && (
				<div className='container_form'>
					<form onSubmit={onSubmitUser}>
						<div
							className='admin__container__inputs'
							id='admin__container__inputs'>
							<label
								className='admin__container__inputs__title'
								forhtml='admin__container__inputs__title'>
								Nombre de usuario*
							</label>
							<input
								maxLength='300'
								name='username'
								className='admin__container__inputs__in'
								id='username'
								type='text'
								placeholder='Nombre de usuario'
								autoComplete='off'
								value={username}
								onChange={(event) =>
									onInputChangeDefault(event, setUsername)
								}
								required></input>
						</div>

						<div
							className='admin__container__inputs'
							id='admin__container__inputs'>
							<label
								className='admin__container__inputs__title'
								forhtml='admin__container__inputs__title'>
								Nombres
							</label>
							<input
								maxLength='300'
								name='name'
								className='admin__container__inputs__in'
								id='name'
								type='text'
								placeholder='Nombres'
								autoComplete='off'
								value={name}
								onChange={(event) =>
									onInputChangeDefault(event, setName)
								}></input>
						</div>

						<div
							className='admin__container__inputs'
							id='admin__container__inputs'>
							<label
								className='admin__container__inputs__title'
								forhtml='admin__container__inputs__title'>
								Apellidos
							</label>
							<input
								maxLength='300'
								name='last_name'
								className='admin__container__inputs__in'
								id='last_name'
								type='text'
								placeholder='Apellidos'
								autoComplete='off'
								value={lastName}
								onChange={(event) =>
									onInputChangeDefault(event, setLastName)
								}></input>
						</div>

						<div
							className='admin__container__inputs'
							id='admin__container__inputs'>
							<label
								className='admin__container__inputs__title'
								forhtml='admin__container__inputs__title'>
								E-mail*
							</label>
							<input
								maxLength='300'
								name='email'
								className='admin__container__inputs__in'
								id='email'
								type='email'
								placeholder='E-mail'
								autoComplete='off'
								value={email}
								onChange={(event) =>
									onInputChangeDefault(event, setEmail)
								}
								required></input>
						</div>

						<div
							className='admin__container__inputs'
							id='admin__container__inputs'>
							<label
								className='admin__container__inputs__title'
								forhtml='admin__container__inputs__title'>
								Pais*
							</label>
							<select
								maxLength='300'
								name='country'
								className='admin__container__inputs__in'
								id='country'
								type='text'
								placeholder='Pais'
								autoComplete='off'
								value={country}
								required
								onChange={(event) =>
									onInputChangeDefault(event, setCountry)
								}>
								<option value=''>Seleccione un Pais</option>
								{listCountry !== undefined
									? listCountry.map((country, index) => (
											<option
												key={index}
												value={country.key}>
												{country.value}
											</option>
									  ))
									: null}
							</select>
						</div>

						<div
							className='admin__container__inputs'
							id='admin__container__inputs'>
							<label
								className='admin__container__inputs__title'
								forhtml='admin__container__inputs__title'>
								Telefono
							</label>
							<input
								maxLength='300'
								name='telephone'
								className='admin__container__inputs__in'
								id='telephone'
								type='number'
								placeholder='Telefono'
								autoComplete='off'
								value={telephone}
								onChange={(event) =>
									onInputChangeDefault(event, setTelephone)
								}></input>
						</div>
						{stateObjectUser === undefined ? (
							<>
								<div
									className='admin__container__inputs'
									id='admin__container__inputs'>
									<label
										className='admin__container__inputs__title'
										forhtml='admin__container__inputs__title'>
										Grupo
									</label>
									<select
										maxLength='300'
										name='group'
										className='admin__container__inputs__in'
										id='group'
										type='text'
										placeholder='Grupo'
										autoComplete='off'
										value={group}
										onChange={(event) =>
											onInputChangeDefault(
												event,
												setGroup
											)
										}>
										<option>Seleccione un grupo</option>
										{listGroup !== undefined
											? listGroup.results.map(
													(group, index) => (
														<option
															key={index}
															value={group.id}>
															{group.name}
														</option>
													)
											  )
											: null}
									</select>
								</div>

								<div
									className='admin__container__inputs'
									id='admin__container__inputs'>
									<label
										className='admin__container__inputs__title'
										forhtml='admin__container__inputs__title'>
										Contraseña*
									</label>
									<input
										maxLength='300'
										name='password'
										className='admin__container__inputs__in'
										id='password'
										type='password'
										placeholder='Contraseña'
										autoComplete='off'
										value={password}
										onChange={(event) =>
											onInputChangeDefault(
												event,
												setPassword
											)
										}
										required></input>
								</div>

								<div
									className='admin__container__inputs'
									id='admin__container__inputs'>
									<label
										className='admin__container__inputs__title'
										forhtml='admin__container__inputs__title'>
										Repita la contraseña*
									</label>
									<input
										maxLength='300'
										name='confirm_password'
										className='admin__container__inputs__in'
										id='confirm_password'
										type='password'
										placeholder='Contraseña'
										autoComplete='off'
										required></input>
								</div>
							</>
						) : null}

						<div className='admin__container__boxBtn'>
							<SaveButton></SaveButton>
						</div>
					</form>
				</div>
			)}

			{contenidoAbierto === 'ManageUserGroups' && (
				<div className='container_form'>
					{gameUser.permissions?.hasOwnProperty('view_group') &&
					gameUser.permissions?.hasOwnProperty('add_user') &&
					gameUser.permissions?.hasOwnProperty('change_user') ? (
						<MagaUserGroups stateObjectUser={stateObjectUser} />
					) : null}
				</div>
			)}

			{contenidoAbierto === 'ManagePassword' && (
				<div className='container_form'>
					<PasswordEditForm
						stateObjectUser={stateObjectUser}
						setStateObjectUser={setStateObjectUser}
						setRefreshUsers={setRefreshUsers}
					/>
				</div>
			)}
		</>
	)
}

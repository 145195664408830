import { useTranslation } from "react-i18next"

export const BodyCollapseDecorations = ({ stateObjectDecoration }) => {
	const { t } = useTranslation()
	return (
		<div className='animationContent collapseStylesBody'>
			{stateObjectDecoration !== undefined && (
				<div className='animationContent__container'>
					<div className='animationContent__container__Left'>
						{stateObjectDecoration.animation.image_file ? (
							<a
								href={
									stateObjectDecoration.animation.image_file
								}
								target='_blank'
								rel='noreferrer noopener'
							>
								<img
									src={
										stateObjectDecoration.animation
											.image_file
									}
									alt={t("pages.editor.components.body_collapse_decorations.alt_decoration")}
									loading="lazy"
								/>
							</a>
						) : (
							<p className='noSelect'>
								{t("pages.editor.components.body_collapse_decorations.none_images_selected")}
							</p>
						)}
					</div>

					<div className='animationContent__container__Right'>
						<div className='animationContent__container__Right__Info'>
							<h4>{t("pages.editor.components.body_collapse_decorations.coordinates")}</h4>
							<p>{stateObjectDecoration.coordinates}</p>
						</div>
						<div className='animationContent__container__Right__Info'>
							<h4>{t("pages.editor.components.body_collapse_decorations.overlapping")}</h4>
							{stateObjectDecoration.is_superimposed === true ? (
								<p>{t("pages.editor.components.body_collapse_decorations.yes")}</p>
							) : (
								<p>{t("pages.editor.components.body_collapse_decorations.no")}</p>
							)}
						</div>
						<div className='animationContent__container__Right__Info'>
							<h4>{t("pages.editor.components.body_collapse_decorations.hidden")}</h4>
							{stateObjectDecoration.hidden === true ? (
								<p>{t("pages.editor.components.body_collapse_decorations.yes")}</p>
							) : (
								<p>{t("pages.editor.components.body_collapse_decorations.no")}</p>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

import { useState } from "react"

export const useLocalLevelResults = (
	axiosSupreme,
	profileData,
	sceneData,
	setReloadScene,
	goToNextScene,
	playerRef,
	doors,
	NPCS,
	decorations,
	lostItems,
	setTransitionScreen,
	transitionScreenStates
) => {
	const [levelResults, setLevelResults] = useState({
		isActive: false,
		lastDoor: sceneData.doors.find((door) => {
			return door.isAvailable === false
		}),
		open: function (scene) {
			// ----- DISABLE PLAYER MOVEMENT
			playerRef.current.enableMovement(false)

			if (sceneData.hasFinalScene) {
				// ----- DESTROY EVERYTHING BUT DECORATIONS
				for (const thing of doors.current) {
					thing.destroyStart()
				}
				for (const thing of NPCS.current.class) {
					thing.destroyStart()
				}
				for (const thing of NPCS.current.game) {
					thing.destroyStart()
				}
				for (const thing of NPCS.current.easterEgg) {
					thing.destroyStart()
				}
				for (const thing of NPCS.current.lostItem) {
					thing.destroyStart()
				}
				for (const thing of lostItems.current) {
					thing.destroyStart()
				}

				setTransitionScreen(transitionScreenStates.fade) // 1000 of duration out and in
				// scene.cameras.main.fadeOut(500)
				const timeout = setTimeout(() => {
					clearTimeout(timeout)
					//scene.cameras.main.fadeIn(500)

					// ----- DESTROY EVERYTHING BUT DECORATIONS
					for (const thing of doors.current) {
						thing.destroyEnd()
					}
					for (const thing of NPCS.current.class) {
						thing.destroyEnd()
					}
					for (const thing of NPCS.current.game) {
						thing.destroyEnd()
					}
					for (const thing of NPCS.current.easterEgg) {
						thing.destroyEnd()
					}
					for (const thing of NPCS.current.lostItem) {
						thing.destroyEnd(
							profileData,
							setReloadScene,
							scene,
							axiosSupreme
						)
					}
					for (const thing of lostItems.current) {
						thing.destroyEnd()
					}
					for (const thing of decorations.current) {
						thing.destroyEnd()
					}

					// ----- WAIT X SECONDS AND SHOW THE LEVEL RESULTS
					const timeout2 = setTimeout(() => {
						clearTimeout(timeout2)
						setTransitionScreen(transitionScreenStates.normal)

						setLevelResults((lr) => ({
							...lr,
							isActive: true,
						}))
					}, 6000)
				}, 1000)
			} else {
				const timeout2 = setTimeout(() => {
					clearTimeout(timeout2)
					setTransitionScreen(transitionScreenStates.normal)

					setLevelResults((lr) => ({
						...lr,
						isActive: true,
					}))
				}, 1000)
			}
		},
		onClose: function () {
			playerRef.current.enableMovement(true)
			setLevelResults((lr) => ({
				...lr,
				isActive: false,
			}))
		},
		onNext: function () {
			goToNextScene(
				levelResults.lastDoor?.id
					? levelResults.lastDoor.id
					: '76b3f783-07a7-4027-8970-ff4cf84755ff',
				profileData.id_profile,
				levelResults.lastDoor?.name
					? levelResults.lastDoor.name
					: 'DEFAULT WRONG DOOR',
				levelResults.lastDoor?.goesToWorld
					? levelResults.lastDoor.goesToWorld
					: true
			)
		},
	})

	return [levelResults, setLevelResults]
}

import { createContext, useContext } from 'react'

const SystemLanguageContext = createContext(null)

const useSystemLanguage = () => {
	return useContext(SystemLanguageContext)
}

const SystemLanguageProvider = ({ children }) => {
	const systemLanguage = {
		gui: {
			map: {
				aaa: 'Mapa perra',
				bbb: 'Map',
				ccc: 'Map Sueco',
				ddd: 'Map Finés',
			},
		},
		windowPopUp: {
			store: {
				aaa: 'Tienda',
				bbb: 'Store',
				ccc: 'Tienda Sueco',
				ddd: 'Tienda Finés',
			},
			equipment: {
				aaa: 'Equipamiento',
				bbb: 'Equipment',
				ccc: 'Equipment Sueco',
				ddd: 'Equipment Finés',
			},
			lostObjects: {
				aaa: 'Objetos Perdidos',
				bbb: 'Lost Objects',
				ccc: 'Objetos Perdidos Sueco',
				ddd: 'Objetos Perdidos Finés',
			},
		},
		configuration: {
			aaa: 'Configuración perra',
			bbb: 'Configuration',
			ccc: 'Configuration Sueco',
			ddd: 'Configuration Finés',

			sound: {
				aaa: 'Sonido',
				bbb: 'Sound',
				ccc: 'Sonido Sueco',
				ddd: 'Sonido Finés',

				music: {
					aaa: 'Sonido',
					bbb: 'Sound',
					ccc: 'Sonido Sueco',
					ddd: 'Sonido Finés',
				},
				soundEffects: {
					aaa: 'Effectos de Sonido',
					bbb: 'Sound Effect',
					ccc: 'Sound Effect Sueco',
					ddd: 'Sound Effect Finés',
				},
				voices: {
					aaa: 'Voces',
					bbb: 'Voices',
					ccc: 'Voices Sueco',
					ddd: 'Voices Finés',
				},
			},
		},
	}

	return (
		<SystemLanguageContext.Provider value={{ systemLanguage }}>
			{children}
		</SystemLanguageContext.Provider>
	)
}

export default SystemLanguageProvider
export { useSystemLanguage }

import { BASE_API } from '../../urls'
import { NPC } from './NPC'
import { SliderBar } from './SliderBar'

export class ClickableNPC extends NPC {
	#profileImage
	get profileImage() {
		return this.#profileImage
	}

	#contentData = {
		alreadyRequested: false,
		items: null, // [],
	}
	get contentData() {
		return this.#contentData
	}
	#onClick
	closedAfterFirstTimeCompleted = false

	#openingTimeout
	#openingBar

	constructor(
		id,
		name,
		pos,
		spriteSheets,
		type,
		dialogues,
		missions,
		profileImage
	) {
		super(id, name, pos, spriteSheets, type, dialogues, missions)
		this.#profileImage = profileImage
	}
	create(scene, bodyType, onClick, axiosSupreme) {
		super.create(scene, bodyType)

		// ----- RESIZE THE BODY BECAUSE THE LORO STAYS TO AWAY FROM IT.
		this.sprite.setBody({
			type: 'rectangle',
			width: this.sprite.width - 70,
			height: this.sprite.height - 35,
		})
		this.sprite.setStatic(true)

		// ----- SET ITS ON CLICK FUNCTION (OPEN THE DIALOGUE)
		this.#onClick = onClick

		// ----- CREATE THE AUTOMATIC OPENING BAR
		this.#openingBar = new SliderBar(
			scene,
			this.pos.x,
			this.pos.y - this.sprite.height / 2 - 10,
			'#26ABFC'
		)
		// ----- REQUEST INITIAL DIALOGUE DATA
		this.#requestContent()
	}

	async #requestContent() {
		// -----SET THE PROPER WORDS FORMAT
		let items = []
		for (const data of this.missions.content) {
			items.push({
				word: data.text,
				translation: data.text_translation,
				audio: BASE_API + data.add_ons.audio_file,
				image: BASE_API + data.add_ons.image_reference_file,
				order: data.order,
			})
		}

		// ----- SAVE THE WORDS (SECUENCE)
		this.#contentData.items = items
	}

	getActualState(missionIsCompleted) {
		this.closedAfterFirstTimeCompleted = missionIsCompleted(
			this.missions.id
		)
	}
	#onEnter() {
		if (this.#contentData.items.length === 0) {
			return
		}
		this.#onClick()
	}
	randomiseContentItems(setActivity) {
		if (this.type === NPC.types.class) return

		// const secuenceClone = this.#contentData.items.map((item) => ({
		// 	...item,
		// }))

		// if (secuenceClone.length === 0) return
		// let firstWord = secuenceClone[0].word

		// let randomisedSecuence = []
		// do {
		// 	const randomIndex = Math.round(
		// 		Math.random() * (secuenceClone.length - 1)
		// 	)
		// 	const poppedItem = secuenceClone.splice(randomIndex, 1)[0]
		// 	randomisedSecuence.push(poppedItem)
		// } while (secuenceClone.length > 0)

		// if (randomisedSecuence[0].word === firstWord) {
		// 	const firstItem = randomisedSecuence.shift()
		// 	randomisedSecuence.push(firstItem)
		// }

		// ----- SAVE THE NEW RANDOMIZED SECUENCE
		// this.#contentData.items = randomisedSecuence

		setActivity((d) => ({
			...d,
			items: this.#contentData.items,
		}))
	}
	onActivityOpen(player, setActivityState, setActivity) {
		// ----- DISABLE PLAYER MOVEMENT
		player.enableMovement(false)

		// ----- SET THE STATE OF THE NPC TO 'greetingInactive'
		this.switchStateTo('greetingInactive')

		// ----- SYNC COMPONENT WITH NPC DIALOGUE DATA
		if (this.type === NPC.types.game) this.randomiseContentItems()

		setActivity((d) => ({
			...d,
			isActive: true, // LET THE COMPONENT KNOW THAT THE DIALOGUE IS NOW ACTIVE
			items: this.#contentData.items,
			isGame: false,
			gameType: null,
		}))

		// ----- SET THE NEW CSS CLASS TO DIALOGUE
		setActivityState('show')
	}
	onActivityClosed(player, setActivityState, setActivity) {
		// ----- CSS CLOSE STATE
		setActivityState('hide')

		// ----- WAIT THE CLOSE ANIMATION TO FINISH
		const hideTimeout = setTimeout(
			() => {
				clearTimeout(hideTimeout)

				// ----- ENABLE PLAYER MOVEMENT
				player.enableMovement(true)

				// ----- MAKE THE GREETING STATE ACTIVE AGAIN
				this.switchStateTo('greetingActiveAgain')

				// ----- RESTART DIALOGUE VALUES TO NULL AGAIN
				setActivity((d) => ({
					...d,
					isActive: false,
					items: null,
					gameType: null,
				}))
			},
			this.type === NPC.types.game ? 350 : 300
		) // this delay time must match the animation time.
	}
	onStartZoneCollision(missionIsAvailable, soundAction) {
		super.onStartZoneCollision(missionIsAvailable) // ***** CONDICIONAL REDUNDANTE *****
		if (!missionIsAvailable(this.missions.id)) return

		// ----- PLAY NPC SOUND
		soundAction('NPC', this.id, 'play')

		// ----- OPENING SLIDER
		clearTimeout(this.#openingTimeout)
		this.#openingTimeout = setTimeout(() => {
			this.#onEnter()

			// ---- STOP BACKGROUND MUSIC
			soundAction('background', undefined, 'pause')

			// ----- PLAY THE GAME MUSIC
			soundAction('game', this.id, 'play')
		}, this.#openingBar.time)
		this.#openingBar.start()
	}
	onEndZoneCollision(soundAction) {
		// ----- CLOSE SLIDER
		clearTimeout(this.#openingTimeout)
		this.#openingBar.stop()

		// ---- STOP BACKGROUND MUSIC
		soundAction('background', undefined, 'play')

		// ----- PLAY THE GAME MUSIC
		soundAction('game', this.id, 'pause')

		super.onEndZoneCollision()
	}
	destroyStart() {
		super.destroyStart()
		clearTimeout(this.#openingTimeout)
		this.#openingBar.stop()
		this.#openingBar.container.setPosition(10000, 10000)
	}
	destroyEnd() {
		super.destroyEnd()
	}
}

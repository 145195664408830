import React, { useContext, useEffect, useState } from 'react'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { Pagination } from '../../searchAndPagination/Pagination'
import ModalContext from '../../../../context/editor/ModalContext'
import GroupForm from './GroupForm'
import { GroupContent } from './GroupContent'
import { API_MANAGE_GROUPS_ADMIN } from '../../../../utils/constantsAdmin'
import { LoadingIcon } from '../../LoadingIcon'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export default function GroupTable() {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// REFRESH GROUPS
	const [refreshGroups, setRefreshGroups] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)

	// GET ALL GROUPS
	useEffect(() => {
		getData()
	}, [refreshGroups])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_GROUPS_ADMIN
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const OpenAddGroupModal = () => {
		setTitleModal('Agregar Grupo')
		setContentModal(<GroupForm setRefreshGroups={setRefreshGroups} />)
		openModal()
	}

	return (
		<div className='groupContent'>
			<div className='groupContent__btn'>
				{gameUser.permissions?.hasOwnProperty('add_group') ? (
					<button className='btnAdd' onClick={OpenAddGroupModal}>
						Agregar
					</button>
				) : null}
			</div>
			{data !== undefined ? (
				<>
					{data === 'search' || data.results.length > 0 ? (
						<>
							<SearchCamp
								setData={setData}
								setRefreshDataList={setRefreshGroups}
								url={API_MANAGE_GROUPS_ADMIN}
								search={search}
								setSearch={setSearch}
							/>
							{data === 'search' ? (
								<p className='noSelect'>
									No se ha encontrado ningún sonido
								</p>
							) : null}
						</>
					) : (
						<p className='noSelect'>
							No existe ningún sonido registrado.
						</p>
					)}
					<div className='groupContent__table'>
						<table className='table'>
							<tbody>
								<tr>
									<th>Nombre del grupo</th>
									{gameUser.permissions?.hasOwnProperty(
										'change_group'
									) ||
									gameUser.permissions?.hasOwnProperty(
										'delete_group'
									) ? (
										<th>Opciones</th>
									) : null}
								</tr>
								{data !== 'search' ? (
									<>
										{data.results.map(
											(data_item, index) => (
												<GroupContent
													key={data_item.id}
													objGroup={data_item}
													setRefreshGroups={
														setRefreshGroups
													}
												/>
											)
										)}
									</>
								) : null}
							</tbody>
						</table>
					</div>
					<Pagination data={data} setData={setData}></Pagination>
				</>
			) : (
				<LoadingIcon />
			)}
		</div>
	)
}

import { useEffect, useState } from 'react'
import '../../../styles/SearchAndPagination.scss'

import { GrFormPrevious, GrFormNext } from 'react-icons/gr'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
// import { cookiesForDev } from '../../../urls'

export const Pagination = ({ data, setData }) => {
	const { gameUser, axiosSupreme } = useAuthProvider()
	const [currentPage, setCurrentPage] = useState('')
	const [disablePrevious, setDisablePrevious] = useState('')
	const [disableNext, setDisableNext] = useState('')

	const getParameterByName = (url_decode, name) => {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
			results = regex.exec(url_decode)
		return results === null
			? ''
			: decodeURIComponent(results[1].replace(/\+/g, ' '))
	}

	useEffect(() => {
		if (data.previous === null && data.next === null) {
			// SET NUMBER PAGINATION WHEN FIRST PAGE
			setCurrentPage(1)
			setDisablePrevious(true)
			setDisableNext(true)
		} else {
			// ENABLE/DISABLE BUTTON PREVIOUS/NEXT
			data.previous === null
				? setDisablePrevious(true)
				: setDisablePrevious(false)
			data.next === null ? setDisableNext(true) : setDisableNext(false)

			// SET NUMBER PAGINATION
			if (data.next !== null) {
				let next_number = getParameterByName(data.next, 'page')
				setCurrentPage(parseInt(next_number) - 1)
			} else {
				let previous_number = getParameterByName(data.previous, 'page')
				if (previous_number !== '') {
					setCurrentPage(parseInt(previous_number) + 1)
				} else {
					setCurrentPage(2)
				}
			}
		}
	})

	const setPaginate = async (type) => {
		let url
		if (type == 'previous') {
			if (data.previous !== null) {
				if (
					data.previous.startsWith('http://') &&
					!data.previous.startsWith('http://127.0.0.1')
				) {
					url = data.previous.replace('http://', 'https://')
				} else {
					url = data.previous
				}
				const result_data = await axiosSupreme('get', url, undefined)
				setData(result_data)
			}
		}
		if (type == 'next') {
			if (data.next !== null) {
				if (
					data.next.startsWith('http://') &&
					!data.next.startsWith('http://127.0.0.1')
				) {
					url = data.next.replace('http://', 'https://')
				} else {
					url = data.next
				}
				const result_data = await axiosSupreme('get', url, undefined)
				setData(result_data)
			}
		}
	}

	return (
		<div className='pagination'>
			<button
				className='pagination__btn'
				disabled={disablePrevious}
				onClick={() => setPaginate('previous')}>
				<GrFormPrevious />
			</button>
			<p className='pagination__number'>{currentPage}</p>
			<button
				className='pagination__btn'
				disabled={disableNext}
				onClick={() => setPaginate('next')}>
				<GrFormNext />
			</button>
		</div>
	)
}

import React, { useState, useEffect } from 'react'
import '../../styles/LostItems.scss'
import Coins from '../fragments/Coins'
import Spinner from '../shared/Spinner/Spinner'
import { useLostItems } from '../../context/LostItemsProvider'
import { useTranslation } from 'react-i18next'
import { t } from "i18next"

/**
 * La primera vez que se abre, siempre debería cargar el request, bueno, de hecho no.
 * La primera vez si debería cargar, pero de resto, cuando notification es false ya no.
 */

function LostItems({ profileData }) {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const {
		lostItemsData,
		updateLostItemsData,
		lostItemsThereIsAnUnseenInteraction,
		setLostItemsThereIsAnUnseenInteraction,
	} = useLostItems()

	useEffect(() => {
		if (lostItemsData && !lostItemsThereIsAnUnseenInteraction) return

		const execute = async () => {
			setLoading(true)
			await updateLostItemsData(profileData.id_profile)
			setLoading(false)
		}
		execute()

		return () => {
			setLostItemsThereIsAnUnseenInteraction(false)
		}
	}, [])

	return (
		<div className='inventory lost__items'>
			{loading ? (
				<Spinner className={'spinnerPage'} />
			) : (
				<>
					{lostItemsData?.length > 0 ? (
						lostItemsData.map((npc, i) => {
							const npcData = {
								name: npc.npc_name,
								world_name: npc.world_name,
								sub_world_name: npc.sub_world_name,
								scene_name: npc.scene_name,
							}
							return (
								<Section
									key={i}
									name={npc.npc_name}
									npcData={npcData}
									npcLocation={
										t("pages.game.components.missions.lobby") +
										npcData.world_name +
										' / ' +
										t("pages.game.components.missions.level") +
										npcData.sub_world_name +
										' / ' +
										t("pages.game.components.missions.scene") +
										npcData.scene_name
									}
									items={npc.mission_profile}></Section>
							)
						})
					) : (
						<p style={{ opacity: 0.5, textAlign: 'center' }}>
							{t("pages.game.components.missions.placeholder")}
						</p>
					)}
				</>
			)}
		</div>
	)
}

const messages = {
	lost: t("pages.game.components.missions.lost"),
	founded: t("pages.game.components.missions.founded"),
	completed: t("pages.game.components.missions.completed"),
}

function getMessage(state, npcData, itemData) {
	let message
	if (state === 'lost') {
		message =
			messages[state] +
			t("pages.game.components.missions.lobby") +
			itemData.world_name +
			' / ' +
			t("pages.game.components.missions.level") +
			itemData.sub_world_name
	} else if (state === 'founded') {
		message =
			messages[state] +
			itemData.name +
			t("pages.game.components.missions.lobby") +
			npcData.world_name +
			' / ' +
			t("pages.game.components.missions.level") +
			npcData.sub_world_name +
			' / ' +
			t("pages.game.components.missions.scene") +
			npcData.scene_name
	} else {
		message = messages[state]
	}
	return message
}

const Section = ({ name, npcData, npcLocation, items }) => {
	return (
		<div className='lost__items__section'>
			<div className='lost__items__section__header'>
				<div className='lost__items__title'>{name}</div>
				<div className='lost__items__location'>
					{t("pages.game.components.missions.i_am_in")} {npcLocation}
				</div>
			</div>
			{items.map((item, e) => {
				let state
				if (!item.object_catched) state = 'lost'
				else if (item.status === 'u') state = 'founded'
				else state = 'completed'
				const itemData = {
					world_name:
						item.mission.mission_content.mission_content_add_ons
							.lost_item.world_name,
					sub_world_name:
						item.mission.mission_content.mission_content_add_ons
							.lost_item.sub_world_name,
					scene_name:
						item.mission.mission_content.mission_content_add_ons
							.lost_item.scene_name,
				}
				const message = getMessage(state, npcData, itemData)
				return (
					<Item
						key={e}
						state={state}
						image={
							item.mission.mission_content.mission_content_add_ons
								.object_image_file
						}
						description={item.mission.mission_content.text}
						message={message}
						reward={item.mission.challenge.reward}></Item>
				)
			})}
		</div>
	)
}

const itemVisuals = {
	lost: 'lost__items__item',
	founded: 'lost__items__item',
	completed: 'lost__items__item lost__items__item__completed',
}
const imageCoverVisuals = {
	lost: 'lost__items__image__cover',
	founded: 'lost__items__image__cover lost__items__image__cover__founded',
	completed: 'lost__items__image__cover lost__items__image__cover__completed',
}
const stateVisuals = {
	lost: 'lost__items__state',
	founded: 'lost__items__state lost__items__state__founded',
	completed: 'lost__items__state lost__items__state__completed',
}
const stateNames = {
	lost: t("pages.game.components.missions.lost"),
	founded: t("pages.game.components.missions.founded"),
	completed: t("pages.game.components.missions.completed"),
}
const rewardVisuals = {
	lost: 'lost__items__reward',
	founded: 'lost__items__reward',
	completed: 'lost__items__reward lost__items__reward__completed',
}
const Item = ({ state, image, description, message, reward }) => {
	const itemVisual = itemVisuals[state]

	const imageCoverVisual = imageCoverVisuals[state]

	const stateName = stateNames[state]
	const stateVisual = stateVisuals[state]

	const rewardVisual = rewardVisuals[state]

	return (
		<div className={itemVisual}>
			<div className='lost__items__item__left'>
				<div className='lost__items__image'>
					{state !== 'lost' && (
						<img src={image} alt='lost object' loading='lazy' />
					)}
					<div className={imageCoverVisual}></div>
				</div>
			</div>
			<div className='lost__items__item__right'>
				<span className={stateVisual}>{t("pages.game.components.missions.state")} {stateName}</span>
				<span className='lost__items__description'>{description}</span>
				<span className='lost__items__tip'>{message}</span>

				<div className={rewardVisual}>
					{t("pages.game.components.missions.reward")}
					<Coins coinsAmount={reward} />
				</div>
			</div>
		</div>
	)
}

export { LostItems }

import { useContext } from "react";
import { sceneContext } from "../context/sceneContext";

export function useSceneContext () {
  const context = useContext(sceneContext)

  if (!context) {
    throw new Error('You have to wrap it in a provider')
  }

  return context
}

import { ListGames } from './Game/ListGames'
import { ListChallenges } from './Challenge/ListChallenges'
import { useState } from 'react'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ChallengeAndGameCollapse = () => {
	const {t} = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	const [activeTab, setActiveTab] = useState(
		gameUser.permissions?.hasOwnProperty('view_challenge')
			? 'challenges'
			: gameUser.permissions?.hasOwnProperty('view_game')
				? 'games'
				: ''
	)
	const handleTabClick = (tabName) => {
		setActiveTab(tabName)
	}

	return (
		<div className='challengeAndGameColl__container'>
			<div className='challengeAndGameContent'>
				<div className='challengeAndGameContent__container'>
					<nav>
						<ul>
							{gameUser.permissions?.hasOwnProperty(
								'view_challenge'
							) ? (
								<li
									className={`nav-item ${activeTab === 'challenges'
											? 'active'
											: ''
										}`}
									onClick={() =>
										handleTabClick('challenges')
									}>
									<a className='nav-link'>{t("pages.editor.components.challenge_game_collapse.challenge")}</a>
								</li>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'view_game'
							) ? (
								<li
									className={`nav-item ${activeTab === 'games' ? 'active' : ''
										}`}
									onClick={() => handleTabClick('games')}>
									<a className='nav-link'>{t("pages.editor.components.challenge_game_collapse.games")}</a>
								</li>
							) : null}
						</ul>
					</nav>
					<div>
						{activeTab === 'challenges' && (
							<div className='nav-content'>
								{gameUser.permissions?.hasOwnProperty(
									'view_challenge'
								) ? (
									<ListChallenges />
								) : null}
							</div>
						)}
						{activeTab === 'games' && (
							<div className='nav-content'>
								{gameUser.permissions?.hasOwnProperty(
									'view_game'
								) ? (
									<ListGames />
								) : null}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

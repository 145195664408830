import Phaser from 'phaser'
import { Sprite } from "./Sprite"

/**
 * Player is basically the sprite that the camera follows.
 * This class define the movement of the player with the keyboard and with the
 * virtual arrows on mobile.
 *
 * It is basically no more than that, movement, and that is all.
 */
export class Player extends Sprite {
	//#thumbstick = null
	#letterKeys = {
		left: null,
		down: null,
		right: null,
		up: null,
	}
	#keys
	#movementEnabled = true
	#keyboardIsMoving = false

	#speed = 0.3
	#vMovement = { x: 0, y: 0 }

	#animationIndex = 0
	#animationFliped = false

	#soundIsPlaying = false

	create(scene, bodyType) {
		super.create(scene, bodyType)
		// this.#letterKeys.left = scene.input.keyboard.addKey(
		// 	Phaser.Input.Keyboard.KeyCodes.A
		// )
		// this.#letterKeys.down = scene.input.keyboard.addKey(
		// 	Phaser.Input.Keyboard.KeyCodes.S
		// )
		// this.#letterKeys.right = scene.input.keyboard.addKey(
		// 	Phaser.Input.Keyboard.KeyCodes.D
		// )
		// this.#letterKeys.up = scene.input.keyboard.addKey(
		// 	Phaser.Input.Keyboard.KeyCodes.W
		// )
		this.#keys = scene.input.keyboard.createCursorKeys()
		this.sprite.setBody('circle')
		this.sprite.depth = 10
		this.sprite.setBody({
			type: 'circle',
			radius: 70,
		})
		this.sprite.body.label = 'PLAYER'
	}
	update(soundAction, t, dt) {
		this.sprite.setAngularVelocity(0)
		if (this.#movementEnabled) {
			this.#keyboardMovement(t, dt)
		}

		if (!this.#keyboardIsMoving) {
			if (this.#soundIsPlaying) {
				this.#soundIsPlaying = false
				soundAction('effect', 'flying', 'pause')
			}
			this.sprite.setFlipX(this.#animationFliped)
			this.sprite.anims.play(
				this.name + '-idle' + this.#animationIndex,
				true
			)
		} else {
			if (!this.#soundIsPlaying) {
				this.#soundIsPlaying = true
				soundAction('effect', 'flying', 'play')
			}
			this.sprite.setFlipX(this.#animationFliped)
			this.sprite.anims.play(
				this.name + '-walk' + this.#animationIndex,
				true
			)
		}
	}
	#keyboardMovement(t, dt) {
		// ----- VERTICAL MOVEMENT
		if (this.#keys.up.isDown) {
		// if (this.#keys.up.isDown || this.#letterKeys.up.isDown) {
			this.#animationIndex = 2
			this.#vMovement.y = -this.#speed
		} else if (this.#keys.down.isDown) {
		// } else if (this.#keys.down.isDown || this.#letterKeys.down.isDown) {
			this.#animationIndex = 0
			this.#vMovement.y = this.#speed
		} else this.#vMovement.y = 0

		// ----- HORIZONTAL MOVEMENT
		if (this.#keys.left.isDown) {
		// if (this.#keys.left.isDown || this.#letterKeys.left.isDown) {
			this.#animationIndex = 1
			this.#vMovement.x = -this.#speed
		} else if (this.#keys.right.isDown) {
		// } else if (this.#keys.right.isDown || this.#letterKeys.right.isDown) {
			this.#animationIndex = 1
			this.#vMovement.x = this.#speed
		} else this.#vMovement.x = 0

		// ----- IF IS MOVING TO ANY DIRECTION
		if (
			this.#keys.left.isDown ||
			this.#keys.right.isDown ||
			this.#keys.up.isDown ||
			this.#keys.down.isDown
			// this.#letterKeys.left.isDown ||
			// this.#letterKeys.down.isDown ||
			// this.#letterKeys.right.isDown ||
			// this.#letterKeys.up.isDown
		) {
			this.#keyboardIsMoving = true

			// ----- CREATE THE VELOCITY
			const vVelocity = new Phaser.Math.Vector2(
				this.#vMovement.x,
				this.#vMovement.y
			)

			// ----- CALCULATE FLIP
			const angle = Phaser.Math.RadToDeg(vVelocity.angle())
			this.#animationFliped = true
			if (angle > 90 && angle < 270) this.#animationFliped = false

			// ----- SET THE VELOCITY OF THE PLAYER
			if (vVelocity.length() > 0) vVelocity.setLength(this.#speed)
			this.sprite.setVelocity(vVelocity.x * dt, vVelocity.y * dt)

			this.pos.x = this.sprite.x
			this.pos.y = this.sprite.y
		} else {
			this.sprite.setVelocity(0, 0)
			this.#keyboardIsMoving = false
		}
	}
	enableMovement(enable) {
		this.#movementEnabled = enable
		this?.sprite?.setVelocity(0, 0)
	}
	applyNewSkin(scene, skinName, skinUrl) {
		const playerName = 'player' + skinName
		const spriteSheetName = playerName + 'SpriteSheet'

		this.name = playerName

		// comprueba que la skin no exista ya
		if (!scene.textures.list[spriteSheetName]) {
			// ----- Set the new name and url
			this.spriteSheets[0].name = spriteSheetName
			this.spriteSheets[0].url = skinUrl

			// ----- Delete created animations
			// for (const animation of this.spriteSheets[0].animations) {
				//scene.anims.remove(this.name + animation.name)
			// }

			// ----- load the new spriteSheet
			super.preload(scene)

			// ----- When finished set that new image
			scene.load.removeListener('complete')
			scene.load.on('complete', () => {
				super.createAnimations(scene)
				//this.sprite.anims.play(this.name + '-idle', true)
				//scene.textures.remove('playerSpriteSheet')
				this.sprite.setTexture(spriteSheetName)
				this.sprite.anims.play(this.name + '-idle', false)
			})

			// ----- Start the new load
			scene.load.start()
		} else {
			this.sprite.setTexture(spriteSheetName)
			this.sprite.anims.play(this.name + '-idle', false)
		}
	}
}

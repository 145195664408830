// onKeyPress={ (event) => handleKeyPressInteger(event)}
export const handleKeyPressFloat = (event) => {
	const charCode = event.charCode || event.keyCode
	if ((charCode < 48 || charCode > 57) && charCode != 46) {
		event.preventDefault()
	}
}

// onKeyPress={ (event) => handleKeyPressInteger(event)}
export const handleKeyPressInteger = (event) => {
	const charCode = event.charCode || event.keyCode
	if (charCode < 48 || charCode > 57) {
		event.preventDefault()
	}
}

// onChange = { (event) => onInputChange (event, setOrder)}
export const onInputChangeDefault = (event, setFunction) => {
	setFunction(event.target.value)
}

// onChange={(event) => onInputChangeImage(event, setErrorImage, imageFile)}
export const onInputChangeImage = (
	event,
	setError,
	refFile,
	setExistsImage
) => {
	const file = event.target.files[0]

	if (file && file.type.match(/^image\/.*/)) {
		setError('')
		if (setExistsImage !== undefined) setExistsImage(true)
	} else {
		setError('Solo se permiten imágenes.')
		refFile.current.value = null
		if (setExistsImage !== undefined) setExistsImage(false)
	}
}

export const onInputChangeVideo = (event, setError, refFile) => {
	const file = event.target.files[0]

	if (file && file.type.match(/^video\/.*/)) {
		setError('')
	} else {
		setError('Solo se permiten videos.')
		refFile.current.value = null
	}
}

export const onInputJson = (event, setError, refFile) => {
	const file = event.target.files[0]
	if (file && file.type === 'application/json') {
		setError('')
	} else {
		setError('Solo se permiten archivos con extensión .json.')
		refFile.current.value = null
	}
}

// onChange={(event) => onInputChangeSound(event, setErrorAudio, audioFile)}
export const onInputChangeSound = (event, setError, refFile) => {
	const file = event.target.files[0]

	if (file && file.type.match(/^audio\/.*/)) {
		setError('')
	} else {
		setError('Solo se permiten audios.')
		refFile.current.value = null
	}
}

export const returnProcessUrl = (url) => {
	let newUrl
	if (url.startsWith('http://') && !url.startsWith('http://127.0.0.1')) {
		newUrl = url.replace('http://', 'https://')
	} else {
		newUrl = url
	}
	return newUrl
}

export const processErrors = (object) => {
	let error = ''
	Object.keys(object).map((key) => {
		error += key
		for (let i = 0; i < object[key].length; i++) {
			error += ': \t' + object[key][0] + '\n'
		}
	})
	return error
}

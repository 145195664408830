import { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import DeleteContext from '../../../../context/editor/DeleteContext'

import { API_MANAGE_SOUNDS } from '../../../../utils/constantsAdmin'
import { FormSounds } from './FormSounds'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const ItemSound = ({ objSound, setRefreshSounds }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT SOUND
	const [stateObjectSound, setStateObjectSound] = useState(undefined)
	useEffect(() => {
		setStateObjectSound(objSound)
	}, [objSound])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE SOUND
	const UpdateSound = async () => {
		const data_result = await axiosSupreme(
			'get',
			API_MANAGE_SOUNDS + stateObjectSound.id_sound + '/',
			undefined
		)
		setTitleModal(t("pages.editor.components.item_sound.update_sound"))
		setContentModal(
			<FormSounds
				stateObjectSound={stateObjectSound}
				setStateObjectSound={setStateObjectSound}
				setRefreshSounds={setRefreshSounds}
			/>
		)
		openModal()
	}

	// DELETE SOUND
	const DeleteSound = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_SOUNDS + stateObjectSound.id_sound + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshSounds((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectSound !== undefined ? (
				<div className='starsContent__Container'>
					<div className='starsContent__Container__Left'>
						<audio
							src={stateObjectSound.audio_file}
							controls></audio>
					</div>
					<div className='starsContent__Container__Right'>
						<div className='starsContent__Container__Right__Options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_sound'
							) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteSound)
										setMessage(
											t("pages.editor.components.item_sound.update_sound") +
											stateObjectSound.name +
											'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_sound'
							) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateSound}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
						<div className='starsContent__Container__Right__Information'>
							<h4>{t("pages.editor.components.item_sound.name")}</h4>
							<p>{stateObjectSound.name}</p>
						</div>
					</div>
				</div>
			) : null}
		</>
	)
}

import { useContext, useEffect, useState } from 'react'
import { API_MANAGE_NPC_STATES } from '../../../../../utils/constantsAdmin'
import ModalContext from '../../../../../context/editor/ModalContext'
import { FormStates } from './FormStates'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import DeleteContext from '../../../../../context/editor/DeleteContext'
import AlertContext from '../../../../../context/editor/AlertContext'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const NpcStatesContent = ({ objectStates, setRefreshStatesNpc }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT STATE
	const [stateObjectState, setStateObjectState] = useState(undefined)
	useEffect(() => {
		setStateObjectState(objectStates)
	}, [objectStates])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	const UpdateStates = async () => {
		setTitleModal(t("pages.editor.components.npc_states_content.update_states"))
		setContentModal(
			<FormStates
				stateObjectState={stateObjectState}
				setStateObjectState={setStateObjectState}
				setRefreshStatesNpc={setRefreshStatesNpc}
			/>
		)
		openModal()
	}
	const DeleteStates = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_NPC_STATES + objectStates.id_state_npc + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshStatesNpc((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectState !== undefined ? (
				<div className='npcDataContet'>
					<div className='npcDataContent__data'>
						<div className='nameAndOptions'>
							<h1>{stateObjectState.name}</h1>
							<div className='options'>
								{stateObjectState.name !== 'idle' &&
									stateObjectState.name !== 'greeting' ? (
									gameUser.permissions?.hasOwnProperty(
										'add_npc_state'
									) ? (
										<button
											className='editorBtnActionDelete'
											onClick={() => {
												setFunctionDelete(
													() => DeleteStates
												)
												setMessage(
													t("pages.editor.components.npc_states_content.update_states") +
													objectStates.name +
													'?'
												)
												openDelete()
											}}>
											<p>
												<BsFillTrashFill />
											</p>
										</button>
									) : null
								) : null}
								{gameUser.permissions?.hasOwnProperty(
									'add_npc_state'
								) ? (
									<button
										className='editorBtnActionEdit'
										onClick={UpdateStates}>
										<p>
											<BsFillPencilFill />
										</p>
									</button>
								) : null}
							</div>
						</div>
						<div className='description'>
							<p>{stateObjectState.description}</p>
						</div>
					</div>
				</div>
			) : null}
		</>
	)
}

import { useState } from 'react'
import Inventory from '../components/ui/Inventory.jsx'
import { LostItems } from '../components/ui/LostItems.jsx'
import Config from '../components/ui/Config.jsx'

import lostItemsTutorialImage1Avif from '../assets/images/tutorials/lostItems/1.avif'
import lostItemsTutorialImage1 from '../assets/images/tutorials/lostItems/1.png'
import lostItemsTutorialImage2Avif from '../assets/images/tutorials/lostItems/2.avif'
import lostItemsTutorialImage2 from '../assets/images/tutorials/lostItems/2.png'
import lostItemsTutorialImage3Avif from '../assets/images/tutorials/lostItems/3.avif'
import lostItemsTutorialImage3 from '../assets/images/tutorials/lostItems/3.png'
import lostItemsTutorialImage4Avif from '../assets/images/tutorials/lostItems/4.avif'
import lostItemsTutorialImage4 from '../assets/images/tutorials/lostItems/4.png'
import lostItemsTutorialImage5Avif from '../assets/images/tutorials/lostItems/5.avif'
import lostItemsTutorialImage5 from '../assets/images/tutorials/lostItems/5.png'
import lostItemsTutorialImage6Avif from '../assets/images/tutorials/lostItems/6.avif'
import lostItemsTutorialImage6 from '../assets/images/tutorials/lostItems/6.png'

import mainGameplayImage1 from '../assets/images/tutorials/mainGameplay/1.avif'
import mainGameplayImage1Avif from '../assets/images/tutorials/mainGameplay/1.avif'
import mainGameplayImage2 from '../assets/images/tutorials/mainGameplay/2.avif'
import mainGameplayImage2Avif from '../assets/images/tutorials/mainGameplay/2.avif'
import mainGameplayImage3 from '../assets/images/tutorials/mainGameplay/3.avif'
import mainGameplayImage3Avif from '../assets/images/tutorials/mainGameplay/3.avif'
import mainGameplayImage4 from '../assets/images/tutorials/mainGameplay/4.avif'
import mainGameplayImage4Avif from '../assets/images/tutorials/mainGameplay/4.avif'
import mainGameplayImage5 from '../assets/images/tutorials/mainGameplay/5.avif'
import mainGameplayImage5Avif from '../assets/images/tutorials/mainGameplay/5.avif'
import mainGameplayImage6 from '../assets/images/tutorials/mainGameplay/6.avif'
import mainGameplayImage6Avif from '../assets/images/tutorials/mainGameplay/6.avif'
import mainGameplayImage7 from '../assets/images/tutorials/mainGameplay/7.avif'
import mainGameplayImage7Avif from '../assets/images/tutorials/mainGameplay/7.avif'
import mainGameplayImage8 from '../assets/images/tutorials/mainGameplay/8.avif'
import mainGameplayImage8Avif from '../assets/images/tutorials/mainGameplay/8.avif'
import mainGameplayImage9 from '../assets/images/tutorials/mainGameplay/9.avif'
import mainGameplayImage9Avif from '../assets/images/tutorials/mainGameplay/9.avif'
import mainGameplayImage10 from '../assets/images/tutorials/mainGameplay/10.avif'
import mainGameplayImage10Avif from '../assets/images/tutorials/mainGameplay/10.avif'

import nestIconAvif from '../assets/images/nest.avif'
import nestIconPng from '../assets/images/nest.png'
import lockGameicon from '../assets/images/lockGame.png'
import lockGameiconAvif from '../assets/images/lockGame.avif'
import coinIcon from '../assets/icons/coin.svg'
import storeIcon from '../assets/icons/store.png'
import storeIconAvif from '../assets/icons/store.avif'
import { useTranslation } from 'react-i18next'

export const useLocalWindowPopUp = (soundAction) => {
	const { t } = useTranslation()
	const windowPopUpData = {
		inventory: {
			component: Inventory,
			tabs: [
				{
					name: t("pages.game.components.tabs.equipment"),
				},
				{
					name: t("pages.game.components.tabs.store"),
				},
			],
			showCoins: true,
			tutorial: null,
		},
		lostItems: {
			component: LostItems,
			tabs: [
				{
					name: t("pages.game.components.tabs.missions"),
				},
			],
			showCoins: true,
			tutorial: {
				isConfig: false,
				secuence: [
					{
						image: lostItemsTutorialImage1,
						imageAvif: lostItemsTutorialImage1Avif,
						text: (
							<p>{t("pages.game.components.lost_items_tutorial.text_1")}</p>
						),
					},
					{
						image: lostItemsTutorialImage2,
						imageAvif: lostItemsTutorialImage2Avif,
						text: (
							<p>{t("pages.game.components.lost_items_tutorial.text_2")}</p>
						),
					},
					{
						image: lostItemsTutorialImage3,
						imageAvif: lostItemsTutorialImage3Avif,
						text: (
							<p>{t("pages.game.components.lost_items_tutorial.text_3")}</p>
						),
					},
					{
						image: lostItemsTutorialImage4,
						imageAvif: lostItemsTutorialImage4Avif,
						text: (
							<p>{t("pages.game.components.lost_items_tutorial.text_4")}</p>
						),
					},
					{
						image: lostItemsTutorialImage5,
						imageAvif: lostItemsTutorialImage5Avif,
						text: (
							<p>{t("pages.game.components.lost_items_tutorial.text_5")}</p>
						),
					},
					{
						image: lostItemsTutorialImage6,
						imageAvif: lostItemsTutorialImage6Avif,
						text: (
							<p>{t("pages.game.components.lost_items_tutorial.text_6")}</p>
						),
					},
				].reverse(),
				lastList: null,
			},
		},
		config: {
			component: Config,
			tabs: [
				{
					name: t("pages.game.components.tabs.configuration"),
				},
			],
			showCoins: false,
			tutorial: {
				isConfig: true,
				secuence: [
					{
						image: mainGameplayImage1,
						imageAvif: mainGameplayImage1Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_1")}</p>
						),
					},
					{
						image: mainGameplayImage2,
						imageAvif: mainGameplayImage2Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_2")}</p>
						),
					},
					{
						image: mainGameplayImage3,
						imageAvif: mainGameplayImage3Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_3")}</p>
						),
					},
					{
						image: mainGameplayImage4,
						imageAvif: mainGameplayImage4Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_4")}</p>
						),
					},
					{
						image: mainGameplayImage5,
						imageAvif: mainGameplayImage5Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_5")}</p>
						),
					},
					{
						image: mainGameplayImage6,
						imageAvif: mainGameplayImage6Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_6")}</p>
						),
					},
					{
						image: mainGameplayImage7,
						imageAvif: mainGameplayImage7Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_7")}</p>
						),
					},
					{
						image: mainGameplayImage8,
						imageAvif: mainGameplayImage8Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_8")}</p>
						),
					},
					{
						image: mainGameplayImage9,
						imageAvif: mainGameplayImage9Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_9")}</p>
						),
					},
					{
						image: mainGameplayImage10,
						imageAvif: mainGameplayImage10Avif,
						text: (
							<p>{t("pages.game.components.main_tutorial_game.text_10")}</p>
						),
					},
				].reverse(),
				lastList: [
					{
						image: nestIconPng,
						imageAvif: nestIconAvif,
						text: t("pages.game.components.main_tutorial_game.text_11"),
					},
					{
						image: lockGameicon,
						imageAvif: lockGameiconAvif,
						text: t("pages.game.components.main_tutorial_game.text_12"),
					},
					{
						image: coinIcon,
						text: t("pages.game.components.main_tutorial_game.text_13"),
					},
					{
						image: storeIcon,
						imageAvif: storeIconAvif,
						text: t("pages.game.components.main_tutorial_game.text_14"),
					},
				],
			},
		},
	}
	const [windowIsActive, setWindowIsActive] = useState(false)
	const [windowCategoryKey, setWindowCategoryKey] = useState(null)
	const closeWindowPopUp = (e) => {
		e.stopPropagation()
		setWindowIsActive(false)
		if (e.target.localName !== 'button') {
			soundAction('effect', 'TLSG2', 'play')
		}
	}

	return [
		windowPopUpData,
		windowIsActive,
		setWindowIsActive,
		windowCategoryKey,
		setWindowCategoryKey,
		closeWindowPopUp,
	]
}

const zoomMovementRange = 1
let zoomBoundaries = { out: 1.5, in: 0.5 }
let zoomAmount = 0.1
let zoom = localStorage.getItem('GAME_ZOOM')
	? Number(localStorage.getItem('GAME_ZOOM'))
	: 1

/**
 * @param {Object} scene the actual scene
 * @param {Object} object the object you want the camera to follow
 * @param {Object} game the phaser game
 */
function createCamera(scene, object) {
	scene.cameras.main.startFollow(object)
	scene.cameras.main.zoom = zoom
}

/**
 * This only works for the scene #1.
 * Resize the camera so it has the right zoom depending on mobile or desktop
 * @param {Object} game
 */
function zoomCamera(scene, zoomIn) {
	if (zoomIn) zoom += zoomAmount
	else zoom -= zoomAmount

	setZoom(scene)
	return zoom
}

function setZoom(scene) {
	if (zoom < zoomBoundaries.out) zoom = zoomBoundaries.out
	else if (zoom > zoomBoundaries.in) zoom = zoomBoundaries.in
	scene.cameras.main.zoom = zoom
	localStorage.setItem('GAME_ZOOM', zoom)
}

function setZoomBoundaries(scene, map) {
	//
	// ----- 1. USEFULL VARS
	const dimentions = {
		width: 'width',
		height: 'height',
	}

	const oppositeDimentions = {
		[dimentions.width]: dimentions.height,
		[dimentions.height]: dimentions.width,
	}

	const rectangles = {
		map: 'map',
		window: 'window',
	}

	// ----- 2. BLANK SPACE IF ZOOM IN / BUT ADDITIONAL MAP SPACE IF ZOOM OUT.
	// I let the name blank space, but it also means additional map space if zoom out.
	const blankSpace = {
		[dimentions.width]: map.width - window.innerWidth,
		[dimentions.height]: map.height - window.innerHeight,
	}

	// ----- 3. MAIN FUNCTIONS
	function theOppositeSideEndsSmallerThanItsWindowDimentionAfterCalculation(
		side,
		additionalPercentage
	) {
		const sideIsWidth = side === dimentions.width
		const finalZoom = 1 + additionalPercentage
		const oppositeMapDimention = sideIsWidth ? map.height : map.width
		const oppositeMapDimentionAfterFinalZoomOut = Math.round(
			oppositeMapDimention * finalZoom
		)
		const windowRespectiveDimention = sideIsWidth
			? Math.round(window.innerHeight)
			: Math.round(window.innerWidth)

		return oppositeMapDimentionAfterFinalZoomOut < windowRespectiveDimention
	}

	function calculateSmallerSideAdditionalPercentage(
		smallerSide,
		smallerRectangle
	) {
		// ----- 1. HOW MUCH DO WE NEED TO ZOOM IN, IN ORDER TO COVER THE WHITE SPACE?
		// or HOW MUCH DO WE NEED TO ZOOM OUT, IN ORDER TO SHOW A GOOD PIECE OF THE MAP?
		//
		// Lets take this example in consideration (FOR WITDH):
		//  - Window (12cm)
		//  - Map (10cm)
		//  - BlankSpace will be (2cm) basic math, ofcourse :v
		//
		// Now, knowing that, how much do we need to increase the width of the map to cover
		// the blank space?
		// Answer: Well... the blank space width itself. 2cm :v
		// Well, we are not really increasing the size of the map, we are moving the camera
		// and we cannot say to the camera to zoom 2cm. (that would be incorrect)
		// We need to talk to the camera in terms of percentage, not size.
		// Those 2 extra cm TRANSLATED to percentage is calculated through this formula:
		//
		// Formula: 2cm to a zoom percentage: (blankSpace.width * 100) / map.width
		// Now, translated to english: (what percentage is this number * 100) / of this number.
		// That is how much to zoom in C:
		// 2cm is the 20% of 10cm, so we need to zoom in -.2 (negative 20%)
		//
		let additionalPercentage = 0
		if (smallerSide === dimentions.width) {
			additionalPercentage = (blankSpace.width * 100) / map.width
		} else {
			additionalPercentage = (blankSpace.height * 100) / map.height
		}

		// ----- 2. FORMAT THE PERCENTAGE: EX FROM 10% TO .1 BASICALLY :v
		additionalPercentage = additionalPercentage / 100
		additionalPercentage =
			smallerRectangle === rectangles.map
				? Math.abs(additionalPercentage)
				: additionalPercentage * -1

		// ----- 3. THERE IS A SPECIAL CASE WITH ZOOM OUT. (THE FUNCTION NAME SAYS IT ALL :v)
		if (smallerRectangle === rectangles.window) {
			if (
				theOppositeSideEndsSmallerThanItsWindowDimentionAfterCalculation(
					smallerSide,
					additionalPercentage
				)
			) {
				// ----- 4. BASICALLY THE RIGHT CALCULATION WAS WITH THE OPPOSITE SIDE.
				additionalPercentage = calculateSmallerSideAdditionalPercentage(
					oppositeDimentions[smallerSide],
					smallerRectangle
				)
			}
		}
		return additionalPercentage
	}

	// ----- 4. IS MAP SMALLER THAN WINDOW OR WINDOW THAN MAP? (even if only one side)
	let additionalPercentage = 0
	let smallerRectangle
	// if is negative (lower than 0) then blank space exists
	if (blankSpace.width < 0 || blankSpace.height < 0) {
		smallerRectangle = rectangles.map

		if (blankSpace.width < blankSpace.height) {
			additionalPercentage = calculateSmallerSideAdditionalPercentage(
				dimentions.width,
				smallerRectangle
			)
		} else {
			additionalPercentage = calculateSmallerSideAdditionalPercentage(
				dimentions.height,
				smallerRectangle
			)
		}
	} else {
		smallerRectangle = rectangles.window

		if (blankSpace.width > blankSpace.height) {
			// ***** IF BUGS THEN CHANGE > FOR < *****
			additionalPercentage = calculateSmallerSideAdditionalPercentage(
				dimentions.width,
				smallerRectangle
			)
		} else {
			additionalPercentage = calculateSmallerSideAdditionalPercentage(
				dimentions.height,
				smallerRectangle
			)
		}
	}

	// Calcular los limites del zoom in/out basandonos en el zoom para que quede igual
	// a las dimensiones del navegador.
	let myZoom = 1 + additionalPercentage
	if (myZoom !== 1) {
		zoomBoundaries.out = myZoom
		zoomBoundaries.in = myZoom + zoomMovementRange
	} else {
		const halfMovementRange = zoomMovementRange / 2
		zoomBoundaries.out = myZoom - halfMovementRange
		zoomBoundaries.in = myZoom + halfMovementRange
	}

	// ---- set the zoom
	setZoom(scene)
}

function setCameraBounds(scene, map) {
	let h = { x1: 0, x2: map.width * zoom }
	let v = { y1: 0, y2: map.height * zoom }

	h = { x1: 0, x2: map.width }
	v = { y1: 0, y2: map.height }

	scene.cameras.main.setBounds(h.x1, v.y1, h.x2, v.y2)
}

export { createCamera, zoomCamera, setCameraBounds, setZoomBoundaries }

import {
	API_PROGRESS_BAR,
	API_PROGRESS_BAR_CLOSE,
	API_UPDATE_MISSION,
	API_LEVEL_RESUME,
	API_LEVEL_RESUME_CLOSE,
} from '../utils/constants'

import { BASE_API } from '../urls'
let updateProgresBarDataLimitCallsAmount = 15
let updateProgresBarDataCounter = 0

/**
 * - CALLS THE UPDATED PROGRESS BAR API DATA
 * - PARSES THAT DATA (CIRCLES)
 * - UNLOCKS THE DOORS
 * - SHOWS THE LEVEL RESULTS DIV
 */
const updateProgresBarData = async (
	axiosSupreme,
	initData,
	setProgressBarData,
	progressBarDataRef,
	levelResults,
	levelResume,
	sceneData,
	profileData,
	doors,
	scene
) => {
	try {
		// ----- #1. THE API REQUEST
		let progressBarDataRequested
		if (initData) progressBarDataRequested = initData
		else {
			const CLOSE_API = profileData.id_profile + API_PROGRESS_BAR_CLOSE
			progressBarDataRequested = await axiosSupreme(
				'get',
				API_PROGRESS_BAR,
				CLOSE_API
			)
		}
		/* {
			"id_mission_profile": "d2e5c012-690c-4864-a17d-7a4a745cbdaa",
			"status": "u",
			"xp": 1,
			"stars": 1,
			"reward": 1,
			"profile": "4ba9224a-41cc-4ce4-87cb-922592f9c3c2",
			"mission": "5cf82e32-7105-4448-b147-6991fede522c"
		} */

		// ----- #2. PARSE THE DATA
		let progressBarCircles = []
		let sceneCompleted = true // If all missions were completed
		for (const requestedData of progressBarDataRequested) {
			if (requestedData.status !== 'c') sceneCompleted = false
			const circleData = {
				state: requestedData.status,
				image: BASE_API + requestedData.image,
				NPCName: requestedData.NPCName,
				missionID: requestedData.mission,
				missionProfileID: requestedData.id_mission_profile,
				missionType: requestedData.categoryMission,
			}
			progressBarCircles.push(circleData)
		}
		setProgressBarData(progressBarCircles)
		progressBarDataRef.current = progressBarCircles

		// ----- 3. IF ALL OF THE MISSIONS OF THE SCENE WERE COMPLETED
		if (sceneCompleted) {
			// ----- 4. UNLOCK THE EXIT DOOR
			for (const door of doors) {
				door.enable()
			}

			// ----- 5. SHOW THE LEVEL RESULTS IF THIS IS THE FIRST TIME THE LEVEL GETS COMPLETED
			openLevelResultsIfFirstTimeLevelCompleted(
				axiosSupreme,
				scene,
				levelResults,
				levelResume,
				profileData,
				sceneData.subWorld
			)
		}
	} catch (error) {
		console.error(error)
		if (
			updateProgresBarDataCounter < updateProgresBarDataLimitCallsAmount
		) {
			updateProgresBarDataCounter = updateProgresBarDataCounter + 1
			updateProgresBarData(
				axiosSupreme,
				undefined, // init data
				setProgressBarData,
				progressBarDataRef,
				levelResults,
				levelResume,
				sceneData,
				profileData,
				doors,
				scene
			)
		} else {
			updateProgresBarDataCounter = 0
		}
	}
}

/**
 * INFORM THE SERVER THAT THE MISSION WAS COMPLETED AND AUTOMATICALLY UPDATE THE PROGRESS BAR UI CIRCLES
 *
 * @param {String} currentNPCID The ID of the NPC that the user is interacting in this moment
 * @param {String} missionState the actual state of the mission, so the server updates the progress
 * bar making it the next one: e.j: actual mission state: locked, the server takes this and transforms
 * it to the next one: unlocked, and then it calls the previous function updateProgressBarData again.
 * @returns {Null|Promise} If the mission doesn't exists null, else the promise where the new data
 * of the actual mission (circle) comes.
 */
const updateMissionState = async (
	axiosSupreme,
	setProgressBarData,
	progressBarDataRef,
	updateProfileData,
	levelResults,
	levelResume,
	sceneData,
	profileData,
	doors,
	scene,
	missionID,
	earnedStars,
	skipUpdateProgressBar
) => {
	// ----- #1. CHECKS IF THE MISSION DOES EXISTS AND GETS THE MISSION_PROFILE_ID
	let missionExists = false
	let missionProfileID
	for (const circle of progressBarDataRef.current) {
		if (missionID === circle.missionID) {
			missionExists = true
			missionProfileID = circle.missionProfileID
		}
	}
	// GET OUT IF THE MISSION DOES NOT EXIST
	if (!missionExists) return

	// ----- #2. INFORM TO THE SERVER THAT THE MISSION WAS COMPLETED
	const THE_API =
		API_UPDATE_MISSION + missionID + '/' + profileData.id_profile + '/'
	const obj = { stars: earnedStars }
	const updateMission = await axiosSupreme('patch', THE_API, obj)

	// ----- #3. UPDATE THE PROFILE
	await updateProfileData()

	// ----- #4. IF IT IS NOT NECCESSARY TO UPDATE THE PROGRESS BAR UI CIRCLES
	// THEN RETURN (USUALLY WHEN UPDATE ONLY XP)
	if (skipUpdateProgressBar) return

	// ----- #5. UPDATE THE PROGRESS BAR UI CIRCLES
	updateProgresBarData(
		axiosSupreme,
		undefined, // init data
		setProgressBarData,
		progressBarDataRef,
		levelResults,
		levelResume,
		sceneData,
		profileData,
		doors,
		scene
	)
}

// Functions called from NPCs or objects different than the progress bar itself.
const progressBarManager = {
	isNPCMissionUnlocked(progressBarDataRef, NPCMissionID) {
		let isAvailable = false
		for (const mission of progressBarDataRef.current) {
			if (mission.missionID === NPCMissionID && mission.state !== 'l') {
				isAvailable = true
				break
			}
		}
		return isAvailable
	},
	isNPCMissionCompleted(progressBarDataRef, NPCMissionID) {
		let is = false
		for (const mission of progressBarDataRef.current) {
			if (mission.missionID === NPCMissionID && mission.state === 'c') {
				is = true
				break
			}
		}
		return is
	},
}

/**
 * Si el nivel no estaba completado cuando entró a esta escena pero luego
 * si apareció completado entonces mostrar los resultados.
 * Por el contrario si cuando entró ya estaba completado entonces no muestra ni madres.
 */
const openLevelResultsIfFirstTimeLevelCompleted = async (
	axiosSupreme,
	scene,
	levelResults,
	levelResume,
	profileData,
	subWorld
) => {
	try {
		const levelResumeUrl =
			API_LEVEL_RESUME +
			profileData.id_profile +
			API_LEVEL_RESUME_CLOSE +
			subWorld.id_sub_world +
			'/'
		const levelResumeRequest = await axiosSupreme('get', levelResumeUrl)

		// Si es la primera vez que el mapa es completado entonces muestra el anuncio
		if (levelResumeRequest.level_complete && !levelResume.level_complete) {
			//***** TEST DESCOMMENT
			// if (true) {
			const timeout = setTimeout(() => {
				clearTimeout(timeout)
				levelResults.open(scene)
			}, 4200)
		}
	} catch (error) {
		console.error(error)
		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			openLevelResultsIfFirstTimeLevelCompleted(
				axiosSupreme,
				scene,
				levelResume,
				profileData,
				subWorld
			)
		}, 2000)
	}
}

export { updateProgresBarData, updateMissionState, progressBarManager }

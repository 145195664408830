import { useContext, useEffect, useRef, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import {
	API_MANAGE_DOORS,
	API_MANAGE_SUBWORLD_DOORS,
	GET_DOORS_BY_SCENE,
	GET_SUBWORLD_DOORS_BY_SCENE,
} from '../../../../utils/constantsAdmin'
import SaveButton from '../../SaveButton'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import {
	handleKeyPressFloat,
	handleKeyPressInteger,
	onInputChangeDefault,
	onInputChangeImage,
	processErrors,
	returnProcessUrl,
} from '../../../../utils/general_functions_forms'
import { LoadingIcon } from '../../LoadingIcon'
import { ManageFile } from '../ManageFile'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export default function FormDoorSceneWorld({
	idScene,
	stateObjectDoor,
	setStateObjectDoor,
	setRefreshDoors,
}) {
	const { t } = useTranslation()
	const { gameUser, axiosSupreme } = useAuthProvider()
	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [name, setName] = useState('')
	const [badgeXPosition, setBadgeXPosition] = useState('')
	const [badgeYPosition, setBadgeYPosition] = useState('')
	const badgeFile = useRef(null)
	const [coordinatesXPosition, setCoordinatesXPosition] = useState('')
	const [coordinatesYPosition, setCoordinatesYPosition] = useState('')
	const doorFile = useRef(null)
	const [frameSizeX, setFrameSizeX] = useState('')
	const [frameSizeY, setFrameSizeY] = useState('')
	const [requiredStarsPercentage, setRequiredStarsPercentage] = useState('')
	const [dependency, setDependency] = useState('')
	const [imageDependency, setImageDependency] = useState(undefined)

	// LIST DEPENDENCY
	const [listDependency, setListDependency] = useState('')

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// ERROR FOR FORM
	const [errorImage, setErrorImage] = useState('')

	// SET LEVEL WHEN UPDATE
	useEffect(() => {
		if (stateObjectDoor !== undefined) {
			setName(stateObjectDoor.name)
			if (stateObjectDoor.coordinates) {
				setCoordinatesXPosition(
					stateObjectDoor.coordinates
						.replace('[', '')
						.replace(']', '')
						.split(',')[0]
				)
				setCoordinatesYPosition(
					stateObjectDoor.coordinates
						.replace('[', '')
						.replace(']', '')
						.split(',')[1]
				)
			}
			if (stateObjectDoor.frame_size) {
				setFrameSizeX(
					stateObjectDoor.frame_size
						.replace('[', '')
						.replace(']', '')
						.split(',')[0]
				)
				setFrameSizeY(
					stateObjectDoor.frame_size
						.replace('[', '')
						.replace(']', '')
						.split(',')[1]
				)
			}
			if (gettypeContextSpace() === 'World') {
				if (stateObjectDoor.badge_coordinates) {
					setBadgeXPosition(
						stateObjectDoor.badge_coordinates
							.replace('[', '')
							.replace(']', '')
							.split(',')[0]
					)
					setBadgeYPosition(
						stateObjectDoor.badge_coordinates
							.replace('[', '')
							.replace(']', '')
							.split(',')[1]
					)
				}
			}
			setRequiredStarsPercentage(
				stateObjectDoor.required_stars_percentage
			)
			setDependencyValue()
		} else {
			clearFields()
		}
	}, [stateObjectDoor])

	const setDependencyValue = async () => {
		if (gettypeContextSpace() === 'World') {
			await fetchOptionsDoors(
				axiosSupreme,
				GET_DOORS_BY_SCENE + idScene + '/',
				setListDependency,
				gettypeContextSpace(),
				stateObjectDoor
			)
		} else if (gettypeContextSpace() === 'Subworld') {
			await fetchOptionsDoors(
				axiosSupreme,
				GET_SUBWORLD_DOORS_BY_SCENE + idScene + '/',
				setListDependency,
				gettypeContextSpace(),
				stateObjectDoor
			)
		}
		if (stateObjectDoor.door_dependency) {
			let idDoor =
				gettypeContextSpace() === 'World'
					? stateObjectDoor.door_dependency.id_world_door
					: stateObjectDoor.door_dependency.id_sub_world_door
			setDependency(idDoor)
			returnDependency(
				axiosSupreme,
				idDoor,
				gettypeContextSpace(),
				setImageDependency
			)
		}
	}

	// SUBMIT FORM DOOR
	const onSubmitDoor = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		let formData = new FormData()
		formData.append('name', event.target.elements.name.value)
		if (event.target.elements.door_file.value) {
			formData.append(
				'door_file',
				event.target.elements.door_file.files[0]
			)
		}
		formData.append(
			'frame_size',
			'[' +
			event.target.elements.frameSizeX.value +
			',' +
			event.target.elements.frameSizeY.value +
			']'
		)
		if (
			event.target.elements.coordinatesXPosition.value &&
			event.target.elements.coordinatesYPosition.value
		) {
			formData.append(
				'coordinates',
				'[' +
				event.target.elements.coordinatesXPosition.value +
				',' +
				event.target.elements.coordinatesYPosition.value +
				']'
			)
		}
		if (gettypeContextSpace() === 'World') {
			if (
				event.target.elements.badgeXPosition.value &&
				event.target.elements.badgeYPosition.value
			) {
				formData.append(
					'badge_coordinates',
					'[' +
					event.target.elements.badgeXPosition.value +
					',' +
					event.target.elements.badgeYPosition.value +
					']'
				)
			}
			if (event.target.elements.badge_file.value) {
				formData.append(
					'badge_file',
					event.target.elements.badge_file.files[0]
				)
			}
		}
		if (event.target.elements.door_dependency.value) {
			formData.append(
				'door_dependency',
				event.target.elements.door_dependency.value
			)
		}
		formData.append(
			'required_stars_percentage',
			event.target.elements.required_stars_percentage.value
		)
		formData.append(
			gettypeContextSpace() == 'World'
				? 'world_scene'
				: 'sub_world_scene',
			idScene
		)

		let urlRequest =
			gettypeContextSpace() == 'World'
				? API_MANAGE_DOORS
				: API_MANAGE_SUBWORLD_DOORS
		if (stateObjectDoor === undefined) {
			// SAVE
			manageDoor(event, urlRequest, formData, 'add')
		} else {
			// UPDATE
			manageDoor(
				event,
				(urlRequest +=
					(gettypeContextSpace() == 'World'
						? stateObjectDoor.id_world_door
						: stateObjectDoor.id_sub_world_door) + '/'),
				formData,
				'update'
			)
		}
	}

	// FUNCTION FOR SAVE OR UPDATE DOOR
	const manageDoor = async (event, url, obj, action) => {
		try {
			// PROCESS DATA
			let result_data =
				action === 'add'
					? await axiosSupreme('post', url, obj)
					: await axiosSupreme('patch', url, obj)
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add'
					? t("common.added")
					: t("common.modified")
			)
			showAlert()
			if (action === 'add') setRefreshDoors((prev) => !prev)
			if (action === 'update') getNewObject()
			setStateLoading(false)
			openModal()
		} catch (errorPromise) {
			setTypeMessage('error')
			setMessage(t("errors.request_error"))
			if (errorPromise.response.status === 403) {
				setMessage(
					t("common.insufficient_permissions")
				)
			}
			if (errorPromise.response.status === 400) {
				let error = processErrors(errorPromise.response.data)
				setMessage(error)
			}
			showAlert()
		}
		setStateLoading(false)
	}

	const getNewObject = async () => {
		let urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_DOORS
				: API_MANAGE_SUBWORLD_DOORS
		const result_data = await axiosSupreme(
			'get',
			(urlRequest +=
				(gettypeContextSpace() === 'World'
					? stateObjectDoor.id_world_door
					: stateObjectDoor.id_sub_world_door) + '/'),
			undefined
		)
		setStateObjectDoor(result_data)
	}

	const onChangeDependency = (event, setFunction) => {
		if (event.target.value !== '') {
			setFunction(event.target.value)
			setImageDependency(listDependency[event.target.value].door_file)
		} else {
			setFunction('')
			setImageDependency(undefined)
		}
	}

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setName('')
		setCoordinatesXPosition('')
		setCoordinatesYPosition('')
		doorFile.current.value = null
		setFrameSizeX('')
		setFrameSizeY('')
		setRequiredStarsPercentage('')
		setDependency('')
		if (gettypeContextSpace() === 'World') {
			badgeFile.current.value = null
			setBadgeXPosition('')
			setBadgeYPosition('')
		}
	}

	return (
		<form onSubmit={onSubmitDoor}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_door_scene_world.name")}
				</label>
				<input
					maxLength='45'
					name='name'
					className='admin__container__inputs__in'
					id='name'
					type='text'
					placeholder={t("pages.editor.components.form_door_scene_world.name_placeholder")}
					autoComplete='off'
					value={name}
					onChange={(event) => onInputChangeDefault(event, setName)}
					required></input>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_door_scene_world.image_door")}
				</label>
				{stateObjectDoor !== undefined ? (
					<>
						{stateObjectDoor.door_file !== null ? (
							<label htmlFor=''>
								{t("common.current_file")}
								<a
									href={stateObjectDoor.door_file}
									target='_blank'
									rel='noopener noreferrer'>
									{t("common.open")}
								</a>
							</label>
						) : null}
					</>
				) : null}
				<input
					name='door_file'
					className='admin__container__inputs__in'
					id='door_file'
					type='file'
					ref={doorFile}
					autoComplete='off'
					onChange={(event) =>
						onInputChangeImage(event, setErrorImage, doorFile)
					}
					accept='image/*'
					required={stateObjectDoor === undefined}></input>
				{errorImage && <p>{errorImage}</p>}
			</div>

			<div
				className='admin__container__inputsCoordenadas'
				id='admin__container__inputsCoordenadas'>
				<label
					className='admin__container__inputsCoordenadas__title'
					forhtml='admin__container__inputsCoordenadas__title'>
					{t("pages.editor.components.form_door_scene_world.frame_size")}
				</label>
				<div className='admin__container__inputsCoordenadas__columns'>
					<input
						maxLength='45'
						name='frameSizeX'
						className='admin__container__inputsCoordenadas__columns__in'
						id='frameSizeX'
						type='text'
						placeholder={t("pages.editor.components.form_door_scene_world.placeholder_width")}
						autoComplete='off'
						value={frameSizeX}
						onChange={(event) =>
							onInputChangeDefault(event, setFrameSizeX)
						}
						onKeyPress={(event) => handleKeyPressFloat(event)}
						required></input>
					<input
						maxLength='45'
						name='frameSizeY'
						className='admin__container__inputsCoordenadas__columns__in'
						id='frameSizeY'
						type='text'
						placeholder={t("pages.editor.components.form_door_scene_world.placeholder_height")}
						autoComplete='off'
						value={frameSizeY}
						onChange={(event) =>
							onInputChangeDefault(event, setFrameSizeY)
						}
						onKeyPress={(event) => handleKeyPressFloat(event)}
						required></input>
				</div>
			</div>

			<div
				className='admin__container__inputsCoordenadas'
				id='admin__container__inputsCoordenadas'>
				<label
					className='admin__container__inputsCoordenadas__title'
					forhtml='admin__container__inputsCoordenadas__title'>
					{t("pages.editor.components.form_door_scene_world.door_coordinates")}
				</label>
				<div className='admin__container__inputsCoordenadas__columns'>
					<input
						maxLength='45'
						name='coordinatesXPosition'
						className='admin__container__inputsCoordenadas__columns__in'
						id='coordinatesXPosition'
						type='text'
						placeholder={t("pages.editor.components.form_door_scene_world.x_position")}
						autoComplete='off'
						value={coordinatesXPosition}
						onChange={(event) =>
							onInputChangeDefault(event, setCoordinatesXPosition)
						}
						onKeyPress={(event) => handleKeyPressFloat(event)}
						required></input>
					<input
						maxLength='45'
						name='coordinatesYPosition'
						className='admin__container__inputsCoordenadas__columns__in'
						id='coordinatesYPosition'
						type='text'
						placeholder={t("pages.editor.components.form_door_scene_world.y_position")}
						autoComplete='off'
						value={coordinatesYPosition}
						onChange={(event) =>
							onInputChangeDefault(event, setCoordinatesYPosition)
						}
						onKeyPress={(event) => handleKeyPressFloat(event)}
						required></input>
				</div>
			</div>

			{gettypeContextSpace() === 'World' ? (
				<>
					<div
						className='admin__container__inputs'
						id='admin__container__inputs'>
						<label
							className='admin__container__inputs__title'
							forhtml='admin__container__inputs__title'>
							{t("pages.editor.components.form_door_scene_world.badge_image")}
						</label>
						{stateObjectDoor !== undefined ? (
							<>
								{stateObjectDoor.badge_file !== null ? (
									<ManageFile
										pathFile={stateObjectDoor.badge_file}
										attribute='badge_file'
										url={
											gettypeContextSpace() === 'World'
												? API_MANAGE_DOORS +
												stateObjectDoor.id_world_door +
												'/'
												: API_MANAGE_SUBWORLD_DOORS +
												stateObjectDoor.id_sub_world_door +
												'/'
										}
										getNewObject={getNewObject}
										setStateObject={setStateObjectDoor}
									/>
								) : null}
							</>
						) : null}
						<input
							name='badge_file'
							className='admin__container__inputs__in'
							id='badge_file'
							type='file'
							ref={badgeFile}
							autoComplete='off'
							onChange={(event) =>
								onInputChangeImage(
									event,
									setErrorImage,
									badgeFile
								)
							}
							accept='image/*'></input>
						{errorImage && <p>{errorImage}</p>}
					</div>

					<div
						className='admin__container__inputsCoordenadas'
						id='admin__container__inputsCoordenadas'>
						<label
							className='admin__container__inputsCoordenadas__title'
							forhtml='admin__container__inputsCoordenadas__title'>
							{t("pages.editor.components.form_door_scene_world.badge_coordinates")}
						</label>
						<div className='admin__container__inputsCoordenadas__columns'>
							<input
								maxLength='45'
								name='badgeXPosition'
								className='admin__container__inputsCoordenadas__columns__in'
								id='badgeXPosition'
								type='text'
								placeholder={t("pages.editor.components.form_door_scene_world.x_position")}
								autoComplete='off'
								value={badgeXPosition}
								onChange={(event) =>
									onInputChangeDefault(
										event,
										setBadgeXPosition
									)
								}
								onKeyPress={(event) =>
									handleKeyPressFloat(event)
								}></input>
							<input
								maxLength='45'
								name='badgeYPosition'
								className='admin__container__inputsCoordenadas__columns__in'
								id='badgeYPosition'
								type='text'
								placeholder={t("pages.editor.components.form_door_scene_world.y_position")}
								autoComplete='off'
								value={badgeYPosition}
								onChange={(event) =>
									onInputChangeDefault(
										event,
										setBadgeYPosition
									)
								}
								onKeyPress={(event) =>
									handleKeyPressFloat(event)
								}></input>
						</div>
					</div>
				</>
			) : null}

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_door_scene_world.door_dependency_and_placeholder")}
				</label>
				<select
					name='door_dependency'
					className='admin__container__inputs__in'
					id='door_dependency'
					placeholder={t("pages.editor.components.form_door_scene_world.door_dependency_and_placeholder")}
					autoComplete='off'
					value={dependency}
					onChange={(event) =>
						onChangeDependency(event, setDependency)
					}>
					<option value=''>{t("pages.editor.components.form_door_scene_world.select_dependency")}</option>
					{Object.keys(listDependency).map((key) => (
						<option key={key} value={key}>
							{listDependency[key].name}
						</option>
					))}
				</select>
				{imageDependency !== undefined && (
					<div className='admin__container__img'>
						<img src={imageDependency} alt={t("pages.editor.components.form_door_scene_world.door_dependency_and_placeholder")} loading='lazy' />
					</div>
				)}
			</div>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					{t("pages.editor.components.form_door_scene_world.stars_percentage_required")}
				</label>
				<input
					maxLength='45'
					name='required_stars_percentage'
					className='admin__container__inputs__in'
					id='required_stars_percentage'
					type='text'
					placeholder={t("pages.editor.components.form_door_scene_world.stars_percentage_required_placeholder")}
					autoComplete='off'
					value={requiredStarsPercentage}
					onChange={(event) =>
						onInputChangeDefault(event, setRequiredStarsPercentage)
					}
					onKeyPress={(event) => handleKeyPressInteger(event)}
					required></input>
			</div>

			{stateLoading === false ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : (
				<LoadingIcon />
			)}
		</form>
	)
}

const fetchOptionsDoors = async (
	axiosSupreme,
	url,
	setList,
	typeWorld,
	objectDoor
) => {
	url = returnProcessUrl(url)
	let result_data = await axiosSupreme('get', url, undefined)
	result_data.results.map((data_item) => {
		let idDoor =
			objectDoor !== undefined
				? typeWorld === 'World'
					? objectDoor.id_world_door
					: objectDoor.id_sub_world_door
				: undefined
		let idDoorIteration =
			typeWorld === 'World'
				? data_item.id_world_door
				: data_item.id_sub_world_door
		if (idDoor !== idDoorIteration) {
			setList((prevData) => ({
				...prevData,
				[idDoorIteration]: {
					name: data_item.name,
					door_file: data_item.door_file,
				},
			}))
		}
	})
	if (result_data.next) {
		fetchOptionsDoors(axiosSupreme, result_data.next, setList)
	}
}

const returnDependency = async (
	axiosSupreme,
	idDoorDependency,
	type_world,
	setImage
) => {
	const result_data =
		type_world === 'World'
			? await axiosSupreme(
				'get',
				API_MANAGE_DOORS + idDoorDependency + '/',
				undefined
			)
			: await axiosSupreme(
				'get',
				API_MANAGE_SUBWORLD_DOORS + idDoorDependency + '/',
				undefined
			)
	setImage(result_data.door_file)
}

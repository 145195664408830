import React, { useContext, useEffect, useState } from 'react'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import {
	API_MANAGE_STORY_TELLER,
	API_MANAGE_STORY_TELLER_SUB_WORLD,
} from '../../../../utils/constantsAdmin'
import ModalContext from '../../../../context/editor/ModalContext'

import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { BodyCollapseStoryTeller } from './BodyCollapseStoryTeller'
import { FormStoryTeller } from './FormStoryTeller'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { toggleCollapse } from '../../../../utils/general_functions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const CollapseStoryTeller = ({
	idScene,
	objectStoryTeller,
	setRefreshStorysTeller,
	activeCollapseStoryTeller,
	setActiveCollapseStoryTeller,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT STORY TELLER
	const [idStoryTeller, setIdStoryTeller] = useState(undefined)
	const [stateObjectStoryTeller, setStateObjectStoryTeller] =
		useState(undefined)
	useEffect(() => {
		setStateObjectStoryTeller(objectStoryTeller)
		setIdStoryTeller(
			gettypeContextSpace() === 'World'
				? objectStoryTeller.id_storyteller
				: objectStoryTeller.id_storyteller
		)
	}, [objectStoryTeller])

	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE STORY TELLER
	const UpdateStoryTeller = async () => {
		setTitleModal(t("pages.editor.components.collapse_storyteller.update_storyteller"))
		setContentModal(
			<FormStoryTeller
				idScene={idScene}
				stateObjectStoryTeller={stateObjectStoryTeller}
				setStateObjectStoryTeller={setStateObjectStoryTeller}
				setRefreshStorysTeller={setRefreshStorysTeller}
			/>
		)
		openModal()
	}

	// DELETE DECORATION
	const DeleteStoryTeller = async () => {
		const urlRequest =
			gettypeContextSpace() === 'World'
				? API_MANAGE_STORY_TELLER +
				stateObjectStoryTeller.id_storyteller +
				'/'
				: API_MANAGE_STORY_TELLER_SUB_WORLD +
				stateObjectStoryTeller.id_storyteller +
				'/'
		const result_data = await axiosSupreme('delete', urlRequest, undefined)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshStorysTeller((prev) => !prev)
			openDelete()
		}
	}

	return (
		<div className='storyTellerComponents'>
			{stateObjectStoryTeller !== undefined ? (
				<>
					<div className='storyTellerComponents__button collapseStylesBtn'>
						{activeCollapseStoryTeller === idStoryTeller ? (
							<div className='arrowOpenCollapse'>
								<FaChevronUp />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronDown />
							</div>
						)}
						<div
							className='storyTellerComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapseStoryTeller,
									setActiveCollapseStoryTeller,
									gettypeContextSpace() === 'World'
										? stateObjectStoryTeller.id_storyteller
										: stateObjectStoryTeller.id_storyteller
								)
							}>
							<h2>{stateObjectStoryTeller.name}</h2>
						</div>
						<div className='storyTellerComponents__button__options options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_storyteller'
							) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(
											() => DeleteStoryTeller
										)
										setMessage(
											t("pages.editor.components.collapse_storyteller.confirm_deleting_1") +
											stateObjectStoryTeller.name +
											t("pages.editor.components.collapse_storyteller.confirm_deleting_2")
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_storyteller'
							) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateStoryTeller}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
					</div>
					{activeCollapseStoryTeller === idStoryTeller && (
						<BodyCollapseStoryTeller
							stateObjectStoryTeller={
								stateObjectStoryTeller
							}></BodyCollapseStoryTeller>
					)}
				</>
			) : null}
		</div>
	)
}

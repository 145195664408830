import React, { useEffect, useState } from 'react'

import { API_MANAGE_ADD_PERMISSION } from '../../../../utils/constantsAdmin'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const PermissionsToAdd = ({
	idGroup,
	objectNoPerms,
	setRefreshNoPerms,
	setRefreshPerms,
}) => {
	const { gameUser, axiosSupreme } = useAuthProvider()
	// SET OBJECT PERMS
	const [stateObjectNoPerms, setStateObjectNoPerms] = useState(undefined)
	useEffect(() => {
		setStateObjectNoPerms(objectNoPerms)
	}, [stateObjectNoPerms])

	const AddPermission = async () => {
		// MAKE DICT
		let formData = new FormData()
		formData.append('id_group', idGroup)
		formData.append('id_permission', stateObjectNoPerms.id)

		const result_data = await axiosSupreme(
			'put',
			API_MANAGE_ADD_PERMISSION,
			formData
		)
		setRefreshNoPerms((prev) => !prev)
		setRefreshPerms((prev) => !prev)
	}
	return (
		<>
			{stateObjectNoPerms !== undefined ? (
				<div className='perms__container__content__toAdd__content'>
					<div className='text'>
						<h1>{stateObjectNoPerms.name}</h1>
					</div>
					<div className='btn'>
						<button className='btnMas' onClick={AddPermission}>
							+
						</button>
					</div>
				</div>
			) : null}
		</>
	)
}

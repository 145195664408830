import { createContext } from 'react'

export const soundActionContext = createContext(null)

export function SoundActionProvider({
	soundAction,
	playAudioBackground,
	pauseAudioBackground,
	playAudioAmbientSound,
	pauseAudioAmbientSound,
	changeVolumeOfAudios,
	children,
}) {
	return (
		<soundActionContext.Provider
			value={{
				soundAction,
				playAudioBackground,
				pauseAudioBackground,
				playAudioAmbientSound,
				pauseAudioAmbientSound,
				changeVolumeOfAudios,
			}}>
			{children}
		</soundActionContext.Provider>
	)
}

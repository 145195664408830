import { useTranslation } from "react-i18next"

export const ContentMissionBodyCollapse = ({ stateObjectContentMission }) => {
	const { t } = useTranslation()
	return (
		<div className='missionContentData collapseStylesBody'>
			<div className='missionContentData__container'>
				{(stateObjectContentMission.audio_file ||
					stateObjectContentMission.image_reference_file) && (
						<div className='missionContentData__container__Left'>
							{stateObjectContentMission.audio_file && (
								<div className='info'>
									<h1>{t("pages.editor.components.content_mission_body_collapse.audio")}</h1>
									<audio
										src={stateObjectContentMission.audio_file}
										controls></audio>
								</div>
							)}
							{stateObjectContentMission.image_reference_file && (
								<div className='info'>
									<h1>{t("pages.editor.components.content_mission_body_collapse.image_reference")}</h1>
									<a
										href={
											stateObjectContentMission.image_reference_file
										}
										target='_blank'
										rel="noreferrer noopener"
									>
										<img
											src={
												stateObjectContentMission.image_reference_file
											}
											alt={t("pages.editor.components.content_mission_body_collapse.alt_reference")}
											loading="lazy"
										/>
									</a>
								</div>
							)}
						</div>
					)}

				<div className='missionContentData__container__Right'>
					<div className='info'>
						<h1>{t("pages.editor.components.content_mission_body_collapse.order")}</h1>
						<p>{stateObjectContentMission.mission_content.order}</p>
					</div>
					<div className='info'>
						<h1>{t("pages.editor.components.content_mission_body_collapse.text")}</h1>
						<p>{stateObjectContentMission.mission_content.text}</p>
					</div>
					<div className='info'>
						<h1>{t("pages.editor.components.content_mission_body_collapse.finnish_text")}</h1>
						<p>
							{
								stateObjectContentMission.mission_content
									.text_translation
							}
						</p>
					</div>
				</div>
			</div>

			{stateObjectContentMission.object_image_file ||
				stateObjectContentMission.frame_size ||
				stateObjectContentMission.frames_amount ||
				stateObjectContentMission.coordinates ? (
				<h1 className='missionContentData__titleSection'>
					{t("pages.editor.components.content_mission_body_collapse.lost_object")}
				</h1>
			) : null}

			<div className='missionContentData__container'>
				<div className='missionContentData__container__Left'>
					{stateObjectContentMission.object_image_file && (
						<div className='info'>
							<h1>{t("pages.editor.components.content_mission_body_collapse.object_image")}</h1>
							<a
								href={
									stateObjectContentMission.object_image_file
								}
								target='_blank'
								rel="noreferrer noopener"
							>
								<img
									src={
										stateObjectContentMission.object_image_file
									}
									alt={t("pages.editor.components.content_mission_body_collapse.alt_object")}
									loading="lazy"
								/>
							</a>
						</div>
					)}
				</div>

				<div className='missionContentData__container__Right'>
					{stateObjectContentMission.frame_size ? (
						<div className='info'>
							<h1>{t("pages.editor.components.content_mission_body_collapse.frame_size")}</h1>
							<p>{stateObjectContentMission.frame_size}</p>
						</div>
					) : null}

					{stateObjectContentMission.frames_amount ? (
						<div className='info'>
							<h1>{t("pages.editor.components.content_mission_body_collapse.amount_frames")}</h1>
							<p>{stateObjectContentMission.frames_amount}</p>
						</div>
					) : null}

					{stateObjectContentMission.coordinates ? (
						<div className='info'>
							<h1>{t("pages.editor.components.content_mission_body_collapse.coordinates")}</h1>
							<p>{stateObjectContentMission.coordinates}</p>
						</div>
					) : null}

					{stateObjectContentMission.sub_world_scene ? (
						<div className='info'>
							<h1>{t("pages.editor.components.content_mission_body_collapse.location")}</h1>
							<p>{`${stateObjectContentMission.sub_world_scene.sub_world.world.level_language.name} / ${stateObjectContentMission.sub_world_scene.sub_world.world.name} /  ${stateObjectContentMission.sub_world_scene.sub_world.name} /  ${stateObjectContentMission.sub_world_scene.name}`}</p>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}

import React, { useState } from 'react'
import '../../../styles/UserAndGroupsAdmin.scss'
import UserTable from './UserContent/UserTable'
import GroupTable from './GroupsContent/GroupTable'
import Modal from '../Modal'
import ModalDelete from '../ModalDelete'
import { Alert } from '../Alert'
import { BackButton } from '../BackButton'
import TitleAdministratorAndMenu from '../TitleAdministratorAndMenu'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import { ErrorPerms } from '../ErrorPerms'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'

export default function UserAndGroupAdministrator() {
	return useAuthNavigator(<PrivatePage />)
}

function PrivatePage() {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// NAVTAB
	const [activeTab, setActiveTab] = useState(
		gameUser.permissions?.hasOwnProperty('view_user')
			? 'users'
			: gameUser.permissions?.hasOwnProperty('view_group')
			? 'groups'
			: ''
	)
	const handleTabClick = (tabName) => {
		setActiveTab(tabName)
	}

	return (
		<div className='userAndGroupsAdmin'>
			<Alert></Alert>
			<Modal></Modal>
			<ModalDelete></ModalDelete>
			<BackButton url={'/'}></BackButton>
			<div className='userAndGroupsAdmin__container'>
				<TitleAdministratorAndMenu name='Usuarios y Grupos'></TitleAdministratorAndMenu>
				{gameUser.permissions?.hasOwnProperty(
					'view_users_groups_administration'
				) &&
				(gameUser.permissions?.hasOwnProperty('view_user') ||
					gameUser.permissions?.hasOwnProperty('view_group')) ? (
					<>
						<nav>
							<ul>
								{gameUser.permissions?.hasOwnProperty(
									'view_user'
								) ? (
									<li
										className={`nav-item ${
											activeTab === 'users'
												? 'active'
												: ''
										}`}
										onClick={() => handleTabClick('users')}>
										<a className='nav-link' href='#users'>
											Usuarios
										</a>
									</li>
								) : null}
								{gameUser.permissions?.hasOwnProperty(
									'view_group'
								) ? (
									<li
										className={`nav-item ${
											activeTab === 'groups'
												? 'active'
												: ''
										}`}
										onClick={() =>
											handleTabClick('groups')
										}>
										<a className='nav-link' href='#groups'>
											Grupos
										</a>
									</li>
								) : null}
							</ul>
						</nav>
						<div>
							{activeTab === 'users' && (
								<div className='nav-content'>
									{gameUser.permissions?.hasOwnProperty(
										'view_user'
									) ? (
										<UserTable></UserTable>
									) : null}
								</div>
							)}
							{activeTab === 'groups' && (
								<div className='nav-content'>
									{gameUser.permissions?.hasOwnProperty(
										'view_group'
									) ? (
										<GroupTable></GroupTable>
									) : null}
								</div>
							)}
						</div>
					</>
				) : (
					<ErrorPerms />
				)}
			</div>
		</div>
	)
}

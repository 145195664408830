import React, { useEffect, useState } from 'react'
import {
	API_MANAGE_PERMISSIONS_BY_GROUP,
	API_MANAGE_PERMISSIONS_EXCEPT_BY_GROUP,
} from '../../../../utils/constantsAdmin'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { PermissionsToAdd } from './PermissionsToAdd'
import { Pagination } from '../../searchAndPagination/Pagination'
import PermissionsAdded from './PermissionsAdded'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const ManagePermissions = ({ stateObjectGroup }) => {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// VALUES SEARCH
	const [searchTwo, setSearchTwo] = useState('')


	// DATA
	const [data, setData] = useState(undefined)
	const [data_noPerms, setData_noPerms] = useState(undefined)

	// REFRESH PERMS
	const [refreshPerms, setRefreshPerms] = useState(false)

	// GET ALL PERMS BY GROUP
	useEffect(() => {
		if (stateObjectGroup !== undefined) {
			getDataPermissions()
		}
	}, [refreshPerms])

	const getDataPermissions = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_PERMISSIONS_BY_GROUP + stateObjectGroup.id + '/'
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// REFRESH PERMS
	const [refreshNoPerms, setRefreshNoPerms] = useState(false)

	// GET ALL PERMS BY GROUP
	useEffect(() => {
		if (stateObjectGroup !== undefined) {
			getDataNoPermissions()
		}
	}, [refreshNoPerms])

	const getDataNoPermissions = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_PERMISSIONS_EXCEPT_BY_GROUP +
					stateObjectGroup.id +
					'/'
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	return (
		<div className='perms__container'>
			<div className='perms__container__title'>
				<h1>Gestionar permisos para el grupo...</h1>
			</div>
			<div className='perms__container__content'>
				<div className='perms__container__content__toAdd'>
					<div className='perms__container__content__toAdd__title'>
						<h1>Agregar</h1>
					</div>
					{data_noPerms !== undefined ? (
						<>
							{data_noPerms === 'search' ||
							data_noPerms.results.length > 0 ? (
								<>
									<SearchCamp
										setData={setData_noPerms}
										setRefreshDataList={setRefreshNoPerms}
										url={
											API_MANAGE_PERMISSIONS_EXCEPT_BY_GROUP +
											stateObjectGroup.id +
											'/'
										}
										search={search}
										setSearch={setSearch}
									/>
									{data_noPerms === 'search' ? (
										<p className='noSelect'>
											No se ha encontrado ninguna
											animación
										</p>
									) : null}
								</>
							) : (
								<p className='noSelect'>
									No existe ninguna animación registrada.
								</p>
							)}

							{data_noPerms !== 'search' ? (
								<>
									{data_noPerms.results.map(
										(data_item, index) => (
											<PermissionsToAdd
												key={data_item.id}
												idGroup={stateObjectGroup.id}
												objectNoPerms={data_item}
												setRefreshNoPerms={
													setRefreshNoPerms
												}
												setRefreshPerms={
													setRefreshPerms
												}
											/>
										)
									)}
									<Pagination
										data={data_noPerms}
										setData={setData_noPerms}></Pagination>
								</>
							) : null}
						</>
					) : null}
				</div>
				<div className='perms__container__content__toDel'>
					<div className='perms__container__content__toDel__title'>
						<h1>Remover</h1>
					</div>
					{data !== undefined ? (
						<>
							{data === 'search' || data.results.length > 0 ? (
								<>
									<SearchCamp
										setData={setData}
										setRefreshDataList={setRefreshPerms}
										url={
											API_MANAGE_PERMISSIONS_BY_GROUP +
											stateObjectGroup.id +
											'/'
										}
										search={searchTwo}
										setSearch={setSearchTwo}
									/>
									{data === 'search' ? (
										<p className='noSelect'>
											No se ha encontrado ninguna
											animación
										</p>
									) : null}
								</>
							) : (
								<p className='noSelect'>
									No existe ninguna animación registrada.
								</p>
							)}

							{data !== 'search' ? (
								<>
									{data.results.map((data_item, index) => (
										<PermissionsAdded
											key={data_item.id}
											idGroup={stateObjectGroup.id}
											objectPerms={data_item}
											setRefreshPerms={setRefreshPerms}
											setRefreshNoPerms={
												setRefreshNoPerms
											}
										/>
									))}
									<Pagination
										data={data}
										setData={setData}></Pagination>
								</>
							) : null}
						</>
					) : null}
				</div>
			</div>
		</div>
	)
}

import { NPC } from './NPC'
import { StringTag } from './StringTag'
import { Zone } from './Zone'

export class EasterEgg extends NPC {
	#textTag
	#audioZone
	#audioZoneSize = { x: 400, y: 400 }

	create(scene, bodyType) {
		super.create(scene, bodyType, true)

		// ----- CREATE AUDIO ZONE
		this.#audioZone = new Zone(
			scene,
			'EASTER EGG AUDIO ZONE',
			this.id,
			this.pos,
			this.#audioZoneSize,
			'circle'
		)
		const letterPos =
			this.pos.x > 1000
				? { x: this.pos.x - this.sprite.width / 2 - 30, y: this.pos.y }
				: { x: this.pos.x + this.sprite.width / 2 + 30, y: this.pos.y }

		const letterOrigin =
			this.pos.x > 1000 ? { x: 1, y: 0.5 } : { x: 0, y: 0.5 }
		this.#textTag = new StringTag(
			letterPos,
			letterOrigin,
			this.dialogues[0].translation,
			//'ESTO ES UN PRUEBA DE SALTO DE LINEA &nbsp; 15\xA0minutos bla bla 15\xA0minutos',
			'map__easter__egg__letter',
			true
		)
		this.#textTag.create(scene)
		this.#textTag.enableVisibility(false)
	}
	switchStateTo(state) {
		if (state === 'normal') {
			this.sprite.anims.play(this.name + '-idle')
			this.#textTag.enableVisibility(false)
		} else if (state === 'greeting') {
			this.sprite.anims.play(this.name + '-greeting')
			this.#textTag.enableVisibility(true)
		}
	}
	onStartZoneCollision(missionIsAvailable, soundAction, missionIsCompleted) {
		this.switchStateTo('greeting')
		// soundAction('NPC', this.id, 'pause')
		if (!missionIsCompleted(this.missions.id)) {
			soundAction('effect', 'TLSI7', 'play')
			const timeout = setTimeout(() => {
				clearTimeout(timeout)
				soundAction('effect', 'TLSI6', 'pause')
			}, 1500)
		}
	}
	onEndZoneCollision(scene, updateMission, missionIsCompleted, soundAction) {
		this.switchStateTo('normal')

		if (!missionIsCompleted(this.missions.id)) {
			soundAction('effect', 'TLSI5', 'play')
			updateMission(this.missions.id)
			this.getVisualReward(scene)
		}
	}
	onStartAudioZoneCollision(missionIsCompleted, soundAction) {
		if (missionIsCompleted(this.missions.id)) return

		// ----- PLAY THE SOUND
		// soundAction('NPC', this.id, 'play')
		soundAction('effect', 'TLSI6', 'play')
	}
	onEndAudioZoneCollision(soundAction) {
		// soundAction('NPC', this.id, 'pause')
		soundAction('effect', 'TLSI6', 'pause')
	}
	destroyStart() {
		super.destroyStart()
		this.#textTag.container.setPosition(10000, 10000)
	}
	destroyEnd() {
		super.destroyEnd()
	}
}

/**
 * This component takes the data of a 'map' and the data of the current user and
 * creates a new phaser canvas that is the hole game: map, npcs, decorations, animations,
 * collissions and the player.
 *
 * #1 - It starts iterating over the list of everything that should be in the map, creating
 * all of the instances. {type: npc, name: 'Felipe', create: (spritesheet)...,
 * preload()..., onActivityOpen: ()....}
 * #2 - It creates the Phaser canvas in a useEffect, where all of these objects are going to be shown.
 * #3 - In a Phaser preload function it starts iterating over all of the objects, pre-loading
 * its spritesheets.
 * #4 - Once all the images were loaded, then it iterates again over all of the objects creating
 * the animations and drawing the images on the Phaser canvas.
 * #5 - It informs the LoadingScreen that the game is ready to be played, and the Platform
 * component animates from opacity: 0 to opacity: 1.
 * #6 - The user can start playing, moving the Loro around and interacting with NPCs and Doors.
 */

/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react'
import Phaser from 'phaser'

import { Toaster } from 'react-hot-toast'

// --------------------------------------------------------------------------- COMPONENTS
import Activity from './ui/Activity'
import ActivityProvider from '../provider/ActivityProvider'
import MissionBubble from './gui/MissionBubble.jsx'
import WindowPopUp from './ui/WindowPopUp'
import Storyteller from './ui/Storyteller'
import LevelResults from './ui/LevelResults'

import { newGame, destroyGame } from '../phaser/NewGame'
import preload from '../phaser/Preload.js'
import create from '../phaser/Create.js'
import update from '../phaser/Update.js'

// --------------------------------------------------------------------------- LIBRARY
import { NPCGame } from '../phaser/Classes.js'

import { useUpdateProfileContext } from './SceneDataFetch'
import '../styles/Scene.scss'

import BasicDataBar from './gui/BasicDataBar'
import MenuBar from './gui/MenuBar'
import { useAuthProvider } from '../context/AuthProvider/AuthProvider'

import { progressBarManager } from '../sceneCores/progressBar'
import { useLostItems } from '../context/LostItemsProvider.jsx'
import ZoomButtons from './gui/ZoomButtons.jsx'
import TutorialSecuence from './ui/TutorialSecuence.jsx'

import { useLocalMenuBar } from '../hooks/useLocalMenuBar.js'
import { useLocalSounds } from '../hooks/useLocalSounds.js'
import { useLocalStoryteller } from '../hooks/useLocalStoryteller.js'
import { useLocalActivity } from '../hooks/useLocalActivity.js'
import { useLocalProgressBar } from '../hooks/useLocalProgressBar.js'
import { useLocalLevelResults } from '../hooks/useLocalLevelResults.js'
import { useLocalWindowPopUp } from '../hooks/useLocalWindowPopUp.js'
import { useTutorialSecuence } from '../hooks/useTutorialSecuence.js'
import { useLocalGameObjects } from '../hooks/useLocalGameObjects.js'
import { useLocalTransitionScreen } from '../hooks/useLocalTransitionScreen.js'
import { useLocalFillProgressBarAtInit } from '../hooks/useLocalFillProgressBarAtInit.js'
import { SoundActionProvider } from '../context/SoundActionContext.js'
import { TutorialContext } from '../context/TutorialContext.js'
import { useSceneContext } from '../hooks/useSceneContext.js'
import { setZoomBoundaries } from '../phaser/Camera.js'

/**
 * Everithing you can see on the screen starting from the Phase canvas is direct child of this Component.
 * @param {Object} sceneData This contains lists of data of everything that goes in the map:
 * NPCs, Doors, Decorations, Map Background and sounds. (its images, sounds and info)
 * @param {Function} goToNextScene Basically destroys this component and the phaser game/canvas,
 * and depending on the data of the user it goes to the next map (this component again but with
 * different data).
 * @returns
 */
function Scene({
	levelResume,
	profileData,
	sceneData,
	actualDoor,
	goToNextScene,
	updateLoadingBarPercentage,
	loadingBarWasCompleted,
}) {
	const { axiosSupreme } = useAuthProvider()
	const [, updateProfileData] = useUpdateProfileContext()
	const [reloadScene, setReloadScene] = useState(false)
	const {
		lostItemsThereIsAnUnseenInteraction,
		setLostItemsThereIsAnUnseenInteraction,
	} = useLostItems()

	const { setApplyNewSkin } = useSceneContext()

	/**
	 * Los datos se guardan en windowpopup, asi que lo único que necesito, es saber
	 * que window pop up está abierto y eso es todo.
	 */

	// ----- SOUNDS
	const [
		soundAction,
		playAudioBackground,
		pauseAudioBackground,
		playAudioAmbientSound,
		pauseAudioAmbientSound,
		changeVolumeOfAudios,
	] = useLocalSounds(sceneData)

	// ----- STORYTELLER
	const [storyteller] = useLocalStoryteller(sceneData)

	// ----- WINDOW POPUP
	const [
		windowPopUpData,
		windowIsActive,
		setWindowIsActive,
		windowCategoryKey,
		setWindowCategoryKey,
		closeWindowPopUp,
	] = useLocalWindowPopUp(soundAction)

	// ----- MENU BAR
	const [menuBar] = useLocalMenuBar(
		setReloadScene,
		setWindowCategoryKey,
		setWindowIsActive,
		lostItemsThereIsAnUnseenInteraction,
		soundAction
	)

	// ----- ACTIVITY (CLASS / GAME)
	const [
		activityState,
		setActivityState,
		activity,
		setActivity,
		activityFunctions,
		setActivityFunctions,
	] = useLocalActivity()

	// ----- PROGRESS BAR
	const [progressBarData, setProgressBarData, progressBarDataRef] =
		useLocalProgressBar()

	// ----- TRANSITION SCREEN
	const [transitionScreenStates, transitionScreen, setTransitionScreen] =
		useLocalTransitionScreen()

	// ----- GAME OBJECTS
	const [playerRef, doors, NPCS, lostItems, decorations] =
		useLocalGameObjects(sceneData, actualDoor)

	// ------ LEVEL RESULTS
	const [levelResults] = useLocalLevelResults(
		axiosSupreme,
		profileData,
		sceneData,
		setReloadScene,
		goToNextScene,
		playerRef,
		doors,
		NPCS,
		decorations,
		lostItems,
		setTransitionScreen,
		transitionScreenStates
	)

	const [tutorialSecuence] = useTutorialSecuence(playerRef, profileData)

	const missionIsCompleted = (missionID) => {
		return progressBarManager.isNPCMissionCompleted(
			progressBarDataRef,
			missionID
		)
	}
	const missionIsAvailable = (missionID) => {
		return progressBarManager.isNPCMissionUnlocked(
			progressBarDataRef,
			missionID
		)
	}

	// ----- FILL PROGRESS BAR AT INIT
	const [, setPhaserCreateAlreadyCalled] = useLocalFillProgressBarAtInit(
		axiosSupreme,
		profileData,
		sceneData,
		actualDoor,
		doors,
		setProgressBarData,
		progressBarDataRef,
		levelResults,
		levelResume,
		missionIsCompleted,
		NPCS
	)

	// ----- ZOOM
	const [onZoomClick, setOnZoomClick] = useState(() => {
		return () => {}
	})

	// ----------------------------------------- ***** $PHASER *****
	/**
	 * This use effect syncronises the component with an exterlan React technology (Phaser)
	 * It container all the need to play, creates Phaser canvas, destroys Phaser canvas,
	 * establishes the preload, create and update functions for the proper functionality of the game.
	 */
	useEffect(() => {
		// ---------------- ***** CREATE PHASER GAME **********
		let game = newGame(Phaser, preloadF, createF, updateF, sceneData)
		function preloadF() {
			preload(
				this, // the scene
				updateLoadingBarPercentage,
				loadingBarWasCompleted,
				sceneData,
				playerRef.current,
				doors.current,
				NPCS.current,
				lostItems.current,
				decorations.current
			)
		}
		function createF() {
			create(
				setPhaserCreateAlreadyCalled,
				game,
				this,
				axiosSupreme,

				levelResume,
				profileData,
				sceneData,
				goToNextScene,
				setReloadScene,
				updateProfileData,

				setProgressBarData,
				progressBarDataRef,
				missionIsAvailable,
				missionIsCompleted,
				setActivity,
				setActivityFunctions,
				setActivityState,
				setLostItemsThereIsAnUnseenInteraction,
				setApplyNewSkin,
				levelResults,
				soundAction,
				setOnZoomClick,

				playerRef.current,
				doors.current,
				NPCS.current,
				lostItems.current,
				decorations.current,
				handlerResizeGame
			)
			//levelResults.open()
		}
		function updateF(t, dt) {
			update(playerRef.current, soundAction, t, dt)
		}

		const resizeGame = (scene, map) => {
			if (game.scale) return
			// game.scale.resize(window.innerWidth, window.innerHeight)
			setZoomBoundaries(scene, map)
			// const zoom = resizeCamera(scene)
			// calculateCameraBounds(scene, map, zoom)
		}

		function handlerResizeGame(scene, map) {
			window.addEventListener('resize', () => resizeGame(scene, map))
		}

		return () => {
			/**
			 * Destroys the Phaser game and the canvas, and tells the LoadingScreen to 0% loaded.
			 * DESTROY PHASER GAME WHEN NOT RENDERING THIS COMPONENT ANYMORE
			 */
			game.scene.scenes[0].matter.world.removeAllListeners()
			destroyGame(game)
			window.removeEventListener('resize', resizeGame)
		}
	}, [])

	return (
		<SoundActionProvider
			soundAction={soundAction}
			playAudioBackground={playAudioBackground}
			pauseAudioBackground={pauseAudioBackground}
			playAudioAmbientSound={playAudioAmbientSound}
			pauseAudioAmbientSound={pauseAudioAmbientSound}
			changeVolumeOfAudios={changeVolumeOfAudios}>
			<TutorialContext.Provider value={{ tutorialSecuence }}>
				<div
					className='scene'
					id='scene'
					style={{ zIndex: '1000000 !important' }}>
					<Toaster />
					{levelResults.isActive ? (
						<LevelResults
							data={levelResume}
							onClose={levelResults.onClose}
							onNext={levelResults.onNext}
						/>
					) : (
						<>
							{!(
								windowIsActive &&
								windowCategoryKey === 'inventory'
							) && (
								<BasicDataBar
									profileData={profileData}
									sceneData={sceneData}
									onConfigClick={() => {
										setWindowIsActive(true)
										setWindowCategoryKey('config')
										setReloadScene((rs) => !rs)
										soundAction('effect', 'TLSG1', 'play')
									}}
									activityIsActive={activity.isActive}
								/>
							)}
							<ZoomButtons onClick={onZoomClick} />
							<MenuBar reloadScene={reloadScene} data={menuBar} />
							{progressBarData && (
								<MissionBubble
									data={progressBarData}
									activityIsActive={activity.isActive}
								/>
							)}

							{windowIsActive && (
								<WindowPopUp
									data={windowPopUpData[windowCategoryKey]}
									onClose={closeWindowPopUp}
									profileData={profileData}
								/>
							)}

							{activity.isActive && (
								<ActivityProvider
									items={activity.items}
									isGame={activity.isGame}>
									<Activity
										visual={activityState}
										playgroundData={{
											gameTypes: NPCGame.gameTypes,
											actualGameType: activity.gameType,
										}}
										onComplete={
											activityFunctions.onComplete
										}
										onClose={activityFunctions.onClose}
										randomiseWords={
											activityFunctions.randomiseContentItems
										}
									/>
								</ActivityProvider>
							)}

							{storyteller.isActive && (
								<Storyteller
									storytellerData={sceneData.storyteller}
									profileData={profileData}
									close={storyteller.close}
								/>
							)}

							{tutorialSecuence.isActive && (
								<TutorialSecuence
									isConfig={
										windowCategoryKey
											? windowPopUpData[windowCategoryKey]
													?.tutorial?.isConfig
											: windowPopUpData['config']
													?.tutorial?.isConfig
									}
									secuence={
										windowCategoryKey
											? windowPopUpData[windowCategoryKey]
													?.tutorial?.secuence
											: windowPopUpData['config']
													?.tutorial?.secuence
									}
									lastList={
										windowCategoryKey
											? windowPopUpData[windowCategoryKey]
													?.tutorial?.lastList
											: windowPopUpData['config']
													?.tutorial?.lastList
									}
									close={tutorialSecuence.onClose}
								/>
							)}
						</>
					)}
				</div>
				<div className={transitionScreen}></div>
			</TutorialContext.Provider>
		</SoundActionProvider>
	)
}

export { Scene }

import { createContext, useState } from "react";

export const sceneContext = createContext(null)

export function SceneProvider ({ children }) {
  // ----- SKINS
	const [applyNewSkin, setApplyNewSkin] = useState(() => () => {})

  return (
    <sceneContext.Provider value={{ applyNewSkin, setApplyNewSkin }}>
      {children}
    </sceneContext.Provider>
  )
}

import { useRef, useState, useContext, createContext } from 'react'
import '../../styles/Inventory.scss'

import {
	API_EQUIPMENT,
	API_EQUIPMENT_SELECT,
} from '../../utils/constants'

import { useSoundAction } from '../../hooks/useSoundAction'
import Spinner from '../shared/Spinner/Spinner'
import { useAuthProvider } from '../../context/AuthProvider/AuthProvider'
import { BASE_API } from '../../urls'
import { sceneContext } from '../../context/sceneContext'
import { useTranslation } from 'react-i18next'

const equipmentContext = createContext(null)

let equipmentData = []

const Equipment = ({ profileData, storeChanged, setStoreChanged }) => {
	const { axiosSupreme } = useAuthProvider()
	const { applyNewSkin } = useContext(sceneContext)
	const [reload, setReload] = useState(false)

	function selectItem(itemID) {
		// Deselect the current selected, and select the new one
		for (const item of equipmentData.results) {
			if (itemID !== item.id_item_profile) {
				item.on_use_status = false
			} else if (itemID === item.id_item_profile) {
				item.on_use_status = true
				// ----- APPLY THE SKIN *****
				// console.log({ image_file: item.item.image_file, item })
				applyNewSkin(item.item.name, item.item.image_file.replace('http', 'https'))
			}
		}
		setStoreChanged(true)

		// ----- Inform the backend about the new change
		updateSelectedItem(itemID)
	}

	async function updateSelectedItem(itemID) {
		try {
			await axiosSupreme(
				'patch',
				BASE_API + API_EQUIPMENT_SELECT +
				itemID +
				'/',
				{
					profile: profileData.id_profile,
					on_use_status: true
				}
			)
		} catch (error) {
			console.error('SEND ITEM SELECTED ERROR: ', error)
		}
	}

	async function updateEquipmentData(tryAgainOnError) {
		try {
			equipmentData = await axiosSupreme(
				'get',
				BASE_API + API_EQUIPMENT,
				profileData.id_profile + '/'
			)
			setReload(!reload)
		} catch (error) {
			console.error('EQUIPMENT DATA ERROR: ', error)
		}
	}

	const once = useRef(false)
	if (!once.current) {
		once.current = true

		async function awaitRequests() {
			if (equipmentData.results?.length === 0) {
				await updateEquipmentData()
			} else if (storeChanged) {
				equipmentData.results = []
				setReload(true)
				await updateEquipmentData()
				setStoreChanged(false)
			}
		}
		awaitRequests()
	}

	return (
		<equipmentContext.Provider value={{ selectItem }}>
			{equipmentData.results?.length > 0 ? (
				<div className='inventory'>
					{
						equipmentData.results != null && equipmentData.results?.length === 0
							? null
							: <Section
								items={equipmentData.results}
							></Section>
					}
				</div>
			) : (
				<div className='inventory'>
					<Spinner className={'spinnerPage'} />
				</div>
			)}
		</equipmentContext.Provider>
	)
}

const Section = ({ items }) => {
	return (
		<div className='inventory__section'>
			<div className='inventory__section__items'>
				{items.map((item, index) => (
					<Item
						key={index + item.item.id_item}
						id={item.id_item_profile}
						name={item.item.name}
						image={item.item.image_preview_file}
						selected={item.on_use_status} // ---------------
					></Item>
				))}
			</div>
		</div>
	)
}

const itemStates = {
	normal: 'inventory__section__items__item',
	selected:
		'inventory__section__items__item inventory__section__items__item__selected', // -------
}

const Item = ({ name, id, image, selected }) => {
	// --------
	const actualState = selected ? itemStates.selected : itemStates.normal // -----
	return (
		<div
			className={actualState}
			id={'inventory__section__items__item' + id}>
			<div className='inventory__section__items__item__image__container'>
				<img src={image} alt='product item' loading='lazy' />
			</div>
			<div className='inventory__section__items__item__name'>
				<span>{name}</span>
			</div>
			<SelectedButton
				id={id}
				selected={selected}
			/>
		</div>
	)
}

const SelectedButton = ({ id, selected }) => {
	const { t } = useTranslation()
	const { soundAction } = useSoundAction()
	const { selectItem } = useContext(equipmentContext)

	return (
		<button
			className={
				'inventory__section__items__item__button inventory__section__items__item__button__select' +
				(selected
					? ' inventory__section__items__item__button__select__selected'
					: '')
			}
			onClick={() => {
				soundAction('effect', 'TLSI2', 'play')
				selectItem(id)
			}}
			disabled={selected}>
			{selected ? t("common.btn_selected") : t("common.btn_select")}
		</button>
	)
}

export default Equipment

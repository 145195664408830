import { useNavigate } from 'react-router-dom'
import '../../styles/Offcanvas.scss'
import { RiArrowGoBackFill } from 'react-icons/ri'

export const BackButton = ({ url }) => {
	const navigate = useNavigate()
	return (
		<button className='buttonBack' onClick={() => navigate(url)}>
			<RiArrowGoBackFill></RiArrowGoBackFill>
		</button>
	)
}

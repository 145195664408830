import coinIcon from '../../assets/icons/coin.svg'

const Coins = ({ size = 20, coinsAmount }) => {
	return (
		<span style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
			<img src={coinIcon} width={size} height={size} alt='coins' />
			<span>{coinsAmount}</span>
		</span>
	)
}

export default Coins

import { useTranslation } from 'react-i18next'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { ListDialogues } from '../Dialogues/ListDialogues'

export const NpcBodyComposeCollapse = ({ itemCompose, setStatusGreeting }) => {
	// AUTH PERMISSION
	const { t } = useTranslation()
	const { gameUser } = useAuthProvider()

	return (
		<div className='npcSceneContent collapseStylesBody'>
			<div className='npcSceneContent__container'>
				<div className='npcSceneContent__container__media'>
					<a
						href={itemCompose.image_file}
						rel='noreferrer noopener'
						target='_blank'>
						<img src={itemCompose.image_file} alt={t("pages.editor.components.npc_body_compose_collapse.alt_npc_scene")} loading='lazy' />
					</a>
				</div>

				{itemCompose.npc_state === 'greeting' && (
					gameUser.permissions?.hasOwnProperty('view_dialogue') && (
						<ListDialogues
							itemCompose={itemCompose}
							setStatusGreeting={setStatusGreeting}
						/>
					)
				)}
			</div>
		</div>
	)
}

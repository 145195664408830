import { useTranslation } from 'react-i18next'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { ListAnimations } from './ListAnimations'

export const AnimationsContent = () => {
	// AUTH PERMISSION
	const { gameUser } = useAuthProvider()
	const { t } = useTranslation()

	return (
		<div className='adminDesign__container__mainContainerBank'>
			<div className="adminDesign__container__mainContainerBank__Item">
				<div className='adminDesign__container__mainContainerBank__Item__title'>
					<h1>{t("pages.editor.components.animation_content.bank_animations")}</h1>
				</div>
				<div className='adminDesign__container__mainContainerBank__Item__content'>
					<hr />
					{gameUser.permissions?.hasOwnProperty(
						'view_animation'
					) ? (
						<ListAnimations />
					) : (
						<h2 className='error'>
							{t("common.insufficient_permissions")}
						</h2>
					)}
				</div>
			</div>
		</div>
	)
}

import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import es from "./i18n/es.json"
import en from "./i18n/en.json"
import fi from "./i18n/fi.json"
import sv from "./i18n/sv.json"
import de from "./i18n/de.json"

const lang = localStorage.getItem("lang") ?? navigator.language.split("-")[0]

console.log(lang)

i18n.use(initReactI18next).init({
    resources: {
        es,
        en,
        fi,
        sv,
        de
    },
    lng: lang,
    fallbackLng: "es",
    interpolation: false
})

export default i18n
import React, { useContext, useEffect, useState } from 'react'
import SaveButton from '../../SaveButton'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { API_MANAGE_USERS_ADMIN } from '../../../../utils/constantsAdmin'

import { onInputChangeDefault } from '../../../../utils/general_functions_forms'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const PasswordEditForm = ({
	stateObjectUser,
	setStateObjectUser,
	setRefreshUsers,
}) => {
	const { gameUser, axiosSupreme } = useAuthProvider()
	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// MODAL
	const { openModal } = useContext(ModalContext)

	// VALUES FORM
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	// ACTIVE BUTTON SAVE
	const [activeButtonSubmit, setActiveButtonSubmit] = useState(false)
	// ERRORS FORM
	const [errorSamePassword, setErrorSamePassword] = useState(false)
	const [errorUppercase, setErrorUppercase] = useState(false)
	const [errorLowercase, setLowercase] = useState(false)
	const [errorSpecialCharacter, setErrorSpecialCharacter] = useState(false)
	const [errorNumber, setErrorNumber] = useState(false)
	const [errorLength, setErrorLength] = useState(false)

	// CLEAR VALUES FOR FIELDS
	const clearFields = () => {
		setPassword('')
	}

	// LOADING
	const [stateLoading, setStateLoading] = useState(false)

	// SUBMIT FORM ANIMATION
	const onSubmitPassword = async (event) => {
		event.preventDefault()
		setStateLoading(true)
		// MAKE DICT
		const formData = {
			password: event.target.elements.password.value,
		}
		managePassword(
			API_MANAGE_USERS_ADMIN + stateObjectUser.id + '/',
			formData,
			'update'
		)
	}

	// FUNCTION FOR SAVE OR UPDATE SOUND
	const managePassword = async (url, obj, action) => {
		// PROCESS DATA
		let result_data = await axiosSupreme('patch', url, obj)
		if (result_data.hasOwnProperty('response')) {
			if (result_data.response.status === 403) {
				setTypeMessage('error')
				setMessage(
					'No tienes los permisos suficientes para realizar esta acción'
				)
			}
			if (result_data.response.status === 400) {
				setTypeMessage('error')
				let error = ''
				Object.keys(result_data.response.data).map((key) => {
					error += key
					for (
						let i = 0;
						i < result_data.response.data[key].length;
						i++
					) {
						error +=
							': \t' + result_data.response.data[key][0] + '\n'
					}
					error += '\n'
					return error
				})
				setTypeMessage('error')
				setMessage(error)
			}
			showAlert()
			setStateLoading(false)
		} else {
			clearFields()
			setTypeMessage('success')
			setMessage(
				action === 'add'
					? 'Agregado correctamente'
					: 'Actualizado correctamente'
			)
			showAlert()
			if (action === 'add') setRefreshUsers((prev) => !prev)
			if (action === 'update') setStateObjectUser(result_data)
			setStateLoading(false)
			openModal()
		}
	}

	// VALIDATE PASSWORD
	const validatePassword = () => {
		let error = false
		if (password.match(/[A-Z]/)) {
			setErrorUppercase(true)
		} else {
			setErrorUppercase(false)
			error = true
		}

		if (password.match(/[a-z]/)) {
			setLowercase(true)
		} else {
			setLowercase(false)
			error = true
		}

		if (password.match(/[0-9]/)) {
			setErrorNumber(true)
		} else {
			setErrorNumber(false)
			error = true
		}

		if (password.match(/[!\@\#\$\%\^\&\*\(\)\_\-\+\=\?\>\<\.\,]/)) {
			setErrorSpecialCharacter(true)
		} else {
			setErrorSpecialCharacter(false)
			error = true
		}

		if (password.length >= 8) {
			setErrorLength(true)
		} else {
			setErrorLength(false)
			error = true
		}

		if (password === confirmPassword && error === false) {
			setErrorSamePassword(true)
			setActiveButtonSubmit(true)
		} else {
			setActiveButtonSubmit(false)
			setErrorSamePassword(false)
		}
	}

	return (
		<form onSubmit={onSubmitPassword}>
			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					Nueva Contraseña*
				</label>
				<input
					maxLength='300'
					name='password'
					className='admin__container__inputs__in'
					id='password'
					type='password'
					placeholder='Contraseña'
					autoComplete='off'
					value={password}
					onChange={(event) =>
						onInputChangeDefault(event, setPassword)
					}
					onKeyUp={validatePassword}
					required></input>
			</div>

			<div
				className='admin__container__inputs'
				id='admin__container__inputs'>
				<label
					className='admin__container__inputs__title'
					forhtml='admin__container__inputs__title'>
					Repita la contraseña*
				</label>
				<input
					maxLength='300'
					name='confirm_password'
					className='admin__container__inputs__in'
					id='confirm_password'
					type='password'
					placeholder='Contraseña'
					autoComplete='off'
					required
					value={confirmPassword}
					onChange={(event) =>
						onInputChangeDefault(event, setConfirmPassword)
					}
					onKeyUp={validatePassword}></input>
			</div>

			<div className='passwordRequeriments'>
				<p>
					<strong>Requisitos para asignar una contraseña:</strong>
				</p>
				<ul>
					<li>
						<label>
							<i>
								{errorUppercase ? (
									<AiOutlineCheck />
								) : (
									<AiOutlineClose />
								)}
							</i>{' '}
							&nbsp;&nbsp; Al menos una letra en mayúscula.
						</label>
					</li>
					<li>
						<label>
							<i>
								{errorLowercase ? (
									<AiOutlineCheck />
								) : (
									<AiOutlineClose />
								)}
							</i>{' '}
							&nbsp;&nbsp; Al menos una letra en minúscula.
						</label>
					</li>
					<li>
						<label>
							<i>
								{errorSpecialCharacter ? (
									<AiOutlineCheck />
								) : (
									<AiOutlineClose />
								)}
							</i>{' '}
							&nbsp;&nbsp; Al menos un carácter especial.
						</label>
					</li>
					<li>
						<label>
							<i>
								{errorNumber ? (
									<AiOutlineCheck />
								) : (
									<AiOutlineClose />
								)}
							</i>{' '}
							&nbsp;&nbsp; Al menos un número.
						</label>
					</li>
					<li>
						<label>
							<i>
								{errorLength ? (
									<AiOutlineCheck />
								) : (
									<AiOutlineClose />
								)}
							</i>{' '}
							&nbsp;&nbsp; Largo mínimo de 8 caracteres.
						</label>
					</li>
					<li>
						<label>
							<i>
								{errorSamePassword ? (
									<AiOutlineCheck />
								) : (
									<AiOutlineClose />
								)}
							</i>{' '}
							&nbsp;&nbsp; Confirmación de contraseña
						</label>
					</li>
				</ul>
			</div>

			{activeButtonSubmit ? (
				<div className='admin__container__boxBtn'>
					<SaveButton></SaveButton>
				</div>
			) : null}
		</form>
	)
}

import React, { useContext, useEffect, useState } from 'react'
import { API_MANAGE_STORY_TELLER_CONTENT } from '../../../../utils/constantsAdmin'
import AlertContext from '../../../../context/editor/AlertContext'
import ModalContext from '../../../../context/editor/ModalContext'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { StoryTellerContentBodyCollapse } from './StoryTellerContentBodyCollapse'
import { FormStoryTellerContent } from './FormStoryTellerContent'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { toggleCollapse } from '../../../../utils/general_functions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const StoryTellerContentCollapse = ({
	id_storyteller,
	objectStoryTellerContent,
	setRefreshStoryTellerContents,
	activeCollapseStoryTellerContent,
	setActiveCollapseStoryTellerContent,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT ITEM
	const [stateObjectStoryTellerContent, setStateObjectStoryTellerContent] =
		useState(undefined)
	useEffect(() => {
		setStateObjectStoryTellerContent(objectStoryTellerContent)
	}, [objectStoryTellerContent])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE DIALOGUE
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)
	const UpdateStoryTellerContent = async () => {
		setTitleModal(t("pages.editor.components.storyteller_content_collapse.update_paragraph"))
		setContentModal(
			<FormStoryTellerContent
				id_storyteller={id_storyteller}
				stateObjectStoryTellerContent={stateObjectStoryTellerContent}
				setStateObjectStoryTellerContent={
					setStateObjectStoryTellerContent
				}
				setRefreshStoryTellerContents={setRefreshStoryTellerContents}
			/>
		)

		openModal()
	}

	// DELETE DECORATION
	const DeleteStoryTeller = async () => {
		const urlRequest =
			API_MANAGE_STORY_TELLER_CONTENT +
			stateObjectStoryTellerContent.id_storyteller_content +
			'/'
		const result_data = await axiosSupreme('delete', urlRequest, undefined)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshStoryTellerContents((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectStoryTellerContent !== undefined ? (
				<div className='storyTellerContentTwo'>
					<div className='storyTellerContentTwo__button collapseStylesBtn'>
						{activeCollapseStoryTellerContent === id_storyteller ? (
							<div className='arrowOpenCollapse'>
								<FaChevronDown />{' '}
							</div>
						) : (
							<div className='arrowOpenCollapse'>
								{' '}
								<FaChevronUp />
							</div>
						)}
						<div
							className='storyTellerContentTwo__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapseStoryTellerContent,
									setActiveCollapseStoryTellerContent,
									stateObjectStoryTellerContent.id_storyteller_content
								)
							}>
							<h2>
								{stateObjectStoryTellerContent.order}.
								{stateObjectStoryTellerContent.text}
							</h2>
						</div>
						<div className='storyTellerContentTwo__button__options options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_storyteller_content'
							) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(
											() => DeleteStoryTeller
										)
										setMessage(
											t("pages.editor.components.storyteller_content_collapse.confirm_deleting_1") +
											stateObjectStoryTellerContent.text +
											t("pages.editor.components.storyteller_content_collapse.confirm_deleting_2")
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />{' '}
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_storyteller_content'
							) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateStoryTellerContent}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
					</div>
					{activeCollapseStoryTellerContent ===
						objectStoryTellerContent.id_storyteller_content && (
							<StoryTellerContentBodyCollapse
								stateObjectStoryTellerContent={
									stateObjectStoryTellerContent
								}
							/>
						)}
				</div>
			) : null}
		</>
	)
}

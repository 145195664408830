import React, { useContext, useEffect, useState } from 'react'
import { API_MANAGE_NPC_COMPOSE } from '../../../../utils/constantsAdmin'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { FormNpcCompose } from './FormNpcCompose'
import ModalContext from '../../../../context/editor/ModalContext'
import AlertContext from '../../../../context/editor/AlertContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const NpcComposeContent = ({
	idNpc,
	objectNpcCompose,
	setRefreshNpcsCompose,
}) => {
	const {t} = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT NPC COMPOSE
	const [stateObjectNpcCompose, setStateObjectNpcCompose] =
		useState(undefined)
	useEffect(() => {
		setStateObjectNpcCompose(objectNpcCompose)
	}, [objectNpcCompose])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	//UPDATE NPCS Compose
	const UpdateNpcCompose = async () => {
		setTitleModal(t("pages.editor.components.npc_compose_content.update_npc_compose"))
		setContentModal(
			<FormNpcCompose
				idNpc={idNpc}
				stateObjectNpcCompose={stateObjectNpcCompose}
				setStateObjectNpcCompose={setStateObjectNpcCompose}
				setRefreshNpcsCompose={setRefreshNpcsCompose}
			/>
		)
		openModal()
	}

	const DeleteNpcCompose = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_NPC_COMPOSE + stateObjectNpcCompose.id_npc_compose + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshNpcsCompose((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectNpcCompose != null && (
				<div className='dataNpcCompose__content'>
					<div className='dataNpcCompose__content__nameAndOptions'>
						<h1>{stateObjectNpcCompose.name}</h1>
						<div className='dataNpcCompose__content__nameAndOptions__options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_npc_compose'
							) && (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(
											() => DeleteNpcCompose
										)
										setMessage(
											t("pages.editor.components.npc_compose_content.confirm_deleting_1") +
												stateObjectNpcCompose.name +
												'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />{' '}
									</p>
								</button>
							)}
							{gameUser.permissions?.hasOwnProperty(
								'change_npc_compose'
							) && (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateNpcCompose}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							)}
						</div>
					</div>
					<div className='dataNpcCompose__content__data'>
						<div className='dataNpcCompose__content__data__media'>
							<a href={stateObjectNpcCompose.image_file}>
								<img
									src={stateObjectNpcCompose.image_file}
									alt={t("pages.editor.components.npc_compose_content.alt_state_obj_npc")}
									loading='lazy'
								/>
							</a>
						</div>
						<div className='dataNpcCompose__content__data__inf'>
							<div className='dataNpcCompose__content__data__inf__info'>
								<h1>{t("pages.editor.components.npc_compose_content.frame_size")}</h1>
								<p>{stateObjectNpcCompose.frame_size}</p>
							</div>
							<div className='dataNpcCompose__content__data__inf__info'>
								<h1>{t("pages.editor.components.npc_compose_content.amount_frames")}</h1>
								<p>{stateObjectNpcCompose.frames_amount}</p>
							</div>
							<div className='dataNpcCompose__content__data__inf__info'>
								<h1>{t("pages.editor.components.npc_compose_content.frame_duration")}</h1>
								<p>{stateObjectNpcCompose.frames_duration}</p>
							</div>
							<div className='dataNpcCompose__content__data__inf__info'>
								<h1>{t("pages.editor.components.npc_compose_content.outfit")}</h1>
								<p>{stateObjectNpcCompose.npc_outfit.name}</p>
							</div>
							<div className='dataNpcCompose__content__data__inf__info'>
								<h1>{t("pages.editor.components.npc_compose_content.pose")}</h1>
								<p>{stateObjectNpcCompose.npc_pose.name}</p>
							</div>
							<div className='dataNpcCompose__content__data__inf__info'>
								<h1>{t("pages.editor.components.npc_compose_content.state")}</h1>
								<p>{stateObjectNpcCompose.npc_state.name}</p>
							</div>
						</div>
						<div className='dataNpcCompose__content__data__inf'>
							<div className='dataNpcCompose__content__data__inf__info__sound'>
								<h1>{t("pages.editor.components.npc_compose_content.sound")}</h1>
								{stateObjectNpcCompose.sound ? (
									<audio
										src={
											stateObjectNpcCompose.sound
												.audio_file
										}
										controls></audio>
								) : (
									<p>{t("pages.editor.components.npc_compose_content.none")}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

import React, { useState, useRef } from 'react'
import '../../styles/Memory.scss'

import { useGameStatus } from '../../provider/ActivityProvider'
import Lives from '../fragments/Lives'
import AudioGame from '../fragments/AudioGame'
import { useSoundAction } from '../../hooks/useSoundAction'

/**
 * @type {Array<String>} colors the colors the cards are going to take when completed,
 * so the user can differenciate between the pairs he already did.
 */
const colors = [
	'#ADF1F7',
	'#ADF7D8',
	'#CFCCFF',
	'#F2B7F7',
	'#BDD9FF',
	'#ADF1F7',
	'#ADF7D8',
	'#CFCCFF',
	'#F2B7F7',
	'#BDD9FF',
	'#ADF1F7',
	'#ADF7D8',
	'#CFCCFF',
	'#BDD9FF',
]

// ------------------------------------------------------------- COMPONENTS STATES
/**
 * @typedef {Object} states Contains the different classNames (visual states) a component can have.
 */
const cardStates = {
	normal: 'playground__memory__cards__section__card',
	wrong: 'playground__memory__cards__section__card playground__memory__cards__section__card__wrong',
}
const cardCoverStates = {
	covered:
		'playground__memory__cards__section__card__cover playground__memory__cards__section__card__cover__covered',
	coveredWithoutHover:
		'playground__memory__cards__section__card__cover playground__memory__cards__section__card__cover__covered__without__hover',
	coveredDisabled:
		'playground__memory__cards__section__card__cover playground__memory__cards__section__card__cover__covered__disabled',
	uncovered:
		'playground__memory__cards__section__card__cover playground__memory__cards__section__card__cover__uncovered',
}
const textStates = {
	visible: 'playground__memory__cards__section__card__image__text',
	invisible:
		'playground__memory__cards__section__card__image__text playground__memory__cards__section__card__image__text__invisible',
}

// ------------------------------------------------------------- COMPONENTS

/**
 * Renders a game with the words in wordsData passed by the Playground Component
 * @param {Array<Object>} wordsData Each object has a word, translation, audio, image, etc...
 * @returns {JSX}
 */
const Memory = ({ wordsData }) => {
	const [_, setGameStatus, gameMistakesCounter] = useGameStatus()
	const { soundAction } = useSoundAction()

	/**
	 * @type {String} audioSRC the url of the audio of the word.
	 * @type {String} finalGameState The state of the game inProgress, gameOver, completed.
	 * @type {Number} mistakesCounterr The number of times the user have commited a mistake.
	 * @type {Number} heartsAmount  the amount if times the user can commit mistakes without loosing.
	 */
	const [audioSRC, setAudioSRC] = useState('')
	const [finalGameState, setFinalGameState] = useState('inProgress') // gameOver, completed
	const [mistakesCounter, setMistakesCounter] = useState(0)

	/**
	 * @type {Number} pairCardsAmount the number of pairs
	 * @type {Number} completedPairsCounter the number of pairs the user has completed
	 * @type {Number} currentSelectedCardsIndex The user has to select two cards, this variable
	 * remembers the first one selected (its position in cards :v)
	 * @type {Array<Array<Object>, Array<Object>} cards An array with all of the cards, this then
	 * represents the grid on the frontend
	 * @typedef card
	 * @property {String} state the visual state of the card (className)
	 * @property {Object} styleCompletedBackgroundColor the style argument of the div
	 * @property {Object} positionInWordsData literally the pointer to wordsData which contains
	 * the word, translation, image and audio.
	 */
	const pairCardsAmount = wordsData.length
	const [completedPairsCounter, setCompletedPairsCounter] = useState(0)
	const completedPairsCounterRef = useRef(completedPairsCounter)
	const [currentSelectedCardsIndex, setCurrentSelectedCardsIndex] = useState(
		[]
	) // [1, 5] then we see if those belogs to the same group (pair) in wordsData
	const [cards, setCards] = useState([])
	// CREATE THE CARDS IN THE FIRST AND ONLY IN THE FIRST RENDER.
	if (cards.length === 0) {
		let newCards = []

		/**
		 * ----- #1 CREATE A PLAIN ARRAY WITH ALL THE CARDS IN wordsData.
		 * For each element in wordsData, create two.
		 * Each card will have:
		 *  - One of six states: covered, coveredWithoutHover, coveredDisabled, uncovered, completed, wrong.
		 *  - An number pointing to its position in wordsData
		 */
		let index = 0
		for (const _ of wordsData) {
			const newCard = {
				state: 'covered',
				styleCompletedBackgroundColor: { backgroundColor: '' },
				positionInWordsData: index,
			}
			newCards.push(newCard)
			index++
		}
		newCards = newCards.concat(newCards.slice())
		/**
		 * ----- #2 SHUFFLE THAT ARRAY :D
		 * At the moment the array looks like this:
		 * [{pair1Card1}, {pair1Card2}, {pair2Card1}, {pair2Card2}...]
		 * Algorithm: Push a Removed random card in a range of the array,
		 * Range: Pushed cards go lasts. Each pushed card, reduces the range by 1,
		 *     so it always removes a dont randomise card before.
		 */
		let alreadyRandomisedCardsAmount = 1 // It starts with one :v... I know, I know.
		do {
			const rangeOfNewCardsToPickARandomCard =
				newCards.length - alreadyRandomisedCardsAmount
			const randomIndex = Math.round(
				Math.random() * rangeOfNewCardsToPickARandomCard
			)
			newCards.push(newCards.splice(randomIndex, 1)[0])
			alreadyRandomisedCardsAmount++
		} while (alreadyRandomisedCardsAmount <= newCards.length)
		// ----- #3 SET THE BRAND NEW CARDS, OOUUUUU completed
		setCards(newCards)
	}

	const audioAlreadyStartedRef = useRef(false)
	const audioAlreadyStartedTimeoutRef = useRef(null)
	const audioAlreadyStartedTimeLimit = 2000

	/**
	 * This controls the entire logic of the interaction when the user presses the cards.
	 * @param {Number} cardPosition The index of the card in the list of cards
	 */
	function onCardClicked(cardIndex) {
		// So... what do I want to do now?

		// ----- #1 GET THE SELECTED CARD.
		const selectedCard = {
			...cards.find((_, index) => index === cardIndex),
		}
		// ----- #2 RETURN IF:
		// SELECTED CARD STATE IS NOT EQUAL TO COVERED.
		if (selectedCard.state !== 'covered') return
		// CURRENT SELECTED CARDS INDEX .LENGTH IS ALREADY 2 (ONE PAIR AT A TIME)
		if (currentSelectedCardsIndex.length >= 2) return
		// FINAL GAME STATE IS NOT EQUAL TO inProgress
		if (finalGameState !== 'inProgress') return
		// -----------------------------------------------------------

		soundAction('effect', 'TLIJ4', 'play')
		/**
		 * Debería esperar un momento, y luego si hacer que todo esto ocurra.
		 */

		// ----- #3 UNCOVER THE COVERED SELECTED CARD.
		setCards(
			cards.map((card, index) => {
				if (cardIndex !== index) return card

				return { ...card, state: 'uncovered' }
			})
		)

		// ----- #4 ADD THIS CARD INDEX TO currentSelectedCardsIndex :v
		setCurrentSelectedCardsIndex([...currentSelectedCardsIndex, cardIndex])
		setAudioSRC(wordsData[selectedCard.positionInWordsData].audio)

		// ----- #5 SOME PAIR LOGIC...
		// IF THIS IS THE FIRST SELECTED CARD THEN RETURN.
		if (currentSelectedCardsIndex[0] === undefined) return
		// ELSE IF THIS IS THE SECOND, THEN SEE IF THEY ARE A VALID PAIR.
		else {
			const firstSelectedCard = {
				...cards.find(
					(_, index) => index === currentSelectedCardsIndex[0]
				),
			}
			const secondSelectedCard = { ...selectedCard }
			if (
				firstSelectedCard.positionInWordsData ===
				secondSelectedCard.positionInWordsData
			) {
				// VALID PAIR
				// ----- #5.5 PLAY THE AUDIO
				soundAction('effect', 'TLIJ5', 'play')

				// ----- #6 CHANGE THE STATE OF BOTH CARDS TO: 'completed'
				setCards(
					cards.map((card, index) => {
						if (
							index !== cardIndex &&
							index !== currentSelectedCardsIndex[0]
						)
							return card

						return {
							...card,
							state: 'completed',
							styleCompletedBackgroundColor: {
								...card.styleCompletedBackgroundColor,
								backgroundColor: colors[completedPairsCounter],
							},
						}
					})
				)

				// ----- #7 INCREASE THE COMPLETED PAIRS AMOUNT
				setCompletedPairsCounter(completedPairsCounter + 1)
				completedPairsCounterRef.current =
					completedPairsCounterRef.current + 1
				if (completedPairsCounter + 1 >= pairCardsAmount) {
					startFinishing()
				}

				// ----- #8 RESTART CURRENT SELECTED CARDS INDEX
				setCurrentSelectedCardsIndex([])
			} else {
				// IN-VALID PAIR
				// playAudio('error')

				// ----- #6 DISABLE THE HOVER ON ALL COVERED CARDS.
				setCards((crds) =>
					crds.map((card, index) => {
						if (card.state !== 'covered') return card

						return {
							...card,
							state: 'coveredWithoutHover',
						}
					})
				)

				// ----- #6 START VISUALIZE UNCOVERED CARDS WHEN WRONG TIMER
				const delayForVisualizeUncoveredCardsWhenWrong = setTimeout(
					() => {
						clearTimeout(delayForVisualizeUncoveredCardsWhenWrong)

						// ----- #6 DISABLE THE HOVER ON ALL COVERED CARDS.
						setCards((crds) =>
							crds.map((card, index) => {
								if (card.state !== 'coveredWithoutHover')
									return card
								// HERE ARE ONLY COVERED CARDS
								return {
									...card,
									state: 'covered',
								}
							})
						)

						// ----- #9 RETURN CURRENT SELECTED WRONG CARDS TO COVERED
						setCards((crds) =>
							crds.map((card, index) => {
								if (
									index !== cardIndex &&
									index !== currentSelectedCardsIndex[0]
								)
									return card

								return { ...card, state: 'covered' }
							})
						)

						// ----- #10 INCREASE THE MISTAKES COUNTER
						gameMistakesCounter.current += 1
						setMistakesCounter(mistakesCounter + 1)

						// ----- #8 RESTART CURRENT SELECTED CARDS INDEX
						setCurrentSelectedCardsIndex([])
					},
					2000
				)
			}
		}
	}

	const onDeath = () => {
		setCards((crds) =>
			crds.map((card, index) => {
				if (card.state !== 'covered') return card

				// HERE ARE ONLY COVERED CARDS
				return {
					...card,
					state: 'coveredDisabled',
				}
			})
		)

		setFinalGameState('gameOver')
		setGameStatus('gameOver')
	}

	const startFinishing = () => {
		audioAlreadyStartedRef.current = false
		audioAlreadyStartedTimeoutRef.current = setTimeout(() => {
			clearTimeout(audioAlreadyStartedTimeoutRef.current)

			// Si entra aquí, es porque el audio nunca arrancó.
			// asi de debemos ganar manualmente
			if (!audioAlreadyStartedRef.current) win()
		}, audioAlreadyStartedTimeLimit)
	}

	const handleAudioStart = (audioIndex) => {
		if (audioIndex !== pairCardsAmount) return

		clearTimeout(audioAlreadyStartedTimeoutRef.current)
		audioAlreadyStartedRef.current = true
	}

	const handleAudioEnd = (audioIndex) => {
		if (audioIndex !== pairCardsAmount) return

		clearTimeout(audioAlreadyStartedTimeoutRef.current)
		win()
	}

	const win = () => {
		setFinalGameState('completed')
		setGameStatus('completed')
	}

	const gridSizes = {
		2: {
			columns: 2,
			rows: 2,
			lives: 2,
		},
		3: {
			columns: 3,
			rows: 2,
			lives: 4,
		},
		4: {
			columns: 3,
			rows: 3,
			lives: 5,
		},
		5: {
			columns: 4,
			rows: 3,
			lives: 7,
		},
		6: {
			columns: 4,
			rows: 3,
			lives: 9,
		},
		7: {
			columns: 5,
			rows: 3,
			lives: 11,
		},
		8: {
			columns: 6,
			rows: 3,
			lives: 13,
		},
	}

	return (
		<div
			className='playground__game playground__memory'
			id='playground__memory'>
			<Lives
				amount={gridSizes[wordsData.length].lives}
				mistakesCounter={mistakesCounter}
				onDeath={onDeath}
				columnsAmount={gridSizes[wordsData.length].lives}
			/>
			<div
				className='playground__memory__cards__section'
				id='playground__memory__cards__section'
				style={{
					gridTemplateColumns:
						'repeat(' +
						gridSizes[wordsData.length].columns +
						', 1fr)',
					gridTemplateRows:
						'repeat(' +
						gridSizes[wordsData.length].rows +
						', minmax(0, 1fr))',
				}}>
				{cards.map((card, i) => {
					const cardData = wordsData[card.positionInWordsData]
					return (
						<Card
							key={i}
							index={i}
							state={card.state}
							data={cardData}
							onCardClicked={onCardClicked}
							styleCompletedBackgroundColor={
								card.styleCompletedBackgroundColor
							}></Card>
					)
				})}
			</div>
			<AudioGame
				key={completedPairsCounterRef.current}
				src={audioSRC}
				index={completedPairsCounterRef.current}
				handleAudioStart={handleAudioStart}
				handleAudioEnd={handleAudioEnd}></AudioGame>
		</div>
	)
}

/**
 *
 * @param {Number} index the index of the card in cards
 * @param {String} state the visual state of the card (not className)
 * @param {Object} data the object that contains the wordsData: name, translation, image, audio.
 * @param {Function} onCardClicked The function to execute when the user clicks the card
 * @param {Object} styleCompletedBackgroundColor The style of the div
 * @returns
 */
const Card = ({
	index,
	state,
	data,
	onCardClicked,
	styleCompletedBackgroundColor,
}) => {
	/**
	 * STATES: COVERED, COVERED-DISABLED, UNCOVERED, WRONG, completed
	 * @type {String} actualCardState The className calculated with the state
	 */
	let actualCardState
	if (state !== 'wrong') actualCardState = cardStates.normal
	else actualCardState = cardStates.wrong

	/**
	 * @type {String} actualCardCoverState The className calculated with the state
	 */
	let actualCardCoverState
	let actualTextState = textStates.invisible
	if (state === 'covered') actualCardCoverState = cardCoverStates.covered
	else if (state === 'coveredWithoutHover')
		actualCardCoverState = cardCoverStates.coveredWithoutHover
	else if (state === 'coveredDisabled')
		actualCardCoverState = cardCoverStates.coveredDisabled
	else {
		actualCardCoverState = cardCoverStates.uncovered
		actualTextState = textStates.visible
	}

	return (
		<div
			className={actualCardState}
			onMouseDown={() => {
				onCardClicked(index)
			}}>
			<div className='playground__memory__cards__section__card__image'>
				<img src={data.image} alt='text support' />
				<div className={actualTextState}>
					<div
						className='playground__memory__cards__section__card__image__text__center'
						style={styleCompletedBackgroundColor}>
						{data.word}
					</div>
				</div>
			</div>
			<div className={actualCardCoverState} />
		</div>
	)
}

export default Memory

import React, { useContext, useEffect, useState } from 'react'
import { BsCircleFill, BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import ModalContext from '../../../../../context/editor/ModalContext'
import { FormCategories } from './FormCategories'
import DeleteContext from '../../../../../context/editor/DeleteContext'

import { API_MANAGE_STORE_CATEGORY } from '../../../../../utils/constantsAdmin'
import AlertContext from '../../../../../context/editor/AlertContext'
import { useAuthProvider } from '../../../../../context/AuthProvider/AuthProvider'

import { useTranslation } from 'react-i18next'

export const CategoriesContent = ({ objectCategory, setRefreshCategories }) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT CATEGORY
	const [stateObjectCategory, setStateObjectCategory] = useState(undefined)
	useEffect(() => {
		setStateObjectCategory(objectCategory)
	}, [objectCategory])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE CATEGORIES
	const UpdateCategory = async () => {
		setTitleModal(t("pages.editor.components.categories_content.update_categories"))
		setContentModal(
			<FormCategories
				stateObjectCategory={stateObjectCategory}
				setStateObjectCategory={setStateObjectCategory}
				setRefreshCategories={setRefreshCategories}
			/>
		)
		openModal()
	}

	const DeleteCategory = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_STORE_CATEGORY + objectCategory.id_item_category + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshCategories((prev) => !prev)
			openDelete()
		}
	}

	return (
		<div className='categories'>
			{stateObjectCategory !== undefined ? (
				<>
					<div className='categories__nameAndOptions'>
						<div className='nameAndUsable'>
							{stateObjectCategory.usable === true ? (
								<p className='circleOn'>
									<BsCircleFill></BsCircleFill>
								</p>
							) : (
								<p className='circleOff'>
									<BsCircleFill></BsCircleFill>
								</p>
							)}
							<h1>{stateObjectCategory.name}</h1>
						</div>
						<div className='categories__nameAndOptions__options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_item_category'
							) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteCategory)
										setMessage(t("pages.editor.components.categories_content.confirm_deleting_1") +
											stateObjectCategory.name +
											'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />{' '}
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_item_category'
							) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateCategory}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
					</div>
					<div className='categories__description'>
						<p>{stateObjectCategory.description}</p>
					</div>
				</>
			) : null}
		</div>
	)
}

import { ListNpcs } from '../../../components/editor/design/NPC/NPCs/ListNpcs'
import TitleAdministratorAndMenu from '../../../components/editor/TitleAdministratorAndMenu'
import { Alert } from '../../../components/editor/Alert'
import Modal from '../../../components/editor/Modal'
import { useAuthProvider } from '../../../context/AuthProvider/AuthProvider'
import { NpcStateCollapse } from '../../../components/editor/design/NPC/NpcStateCollapse'
import { BackButton } from '../../../components/editor/BackButton'
import ModalDelete from '../../../components/editor/ModalDelete'
import { ErrorPerms } from '../../../components/editor/ErrorPerms'
import useAuthNavigator from '../../../context/AuthProvider/useAuthNavigator'

export default function ManageNpc() {
	return useAuthNavigator(<PrivatePage />)
}
const PrivatePage = () => {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// RESET SCROLL
	window.scroll(0, 0)

	return (
		<div className='npcList'>
			<Alert></Alert>
			<Modal></Modal>
			<ModalDelete></ModalDelete>
			<TitleAdministratorAndMenu name='NPCS'></TitleAdministratorAndMenu>
			<BackButton url={'/manage-scene-world'}></BackButton>
			{gameUser.permissions?.hasOwnProperty(
				'view_designer_administration'
			) &&
			(gameUser.permissions?.hasOwnProperty('view_npc_outfit') ||
				gameUser.permissions?.hasOwnProperty('view_npc_pose') ||
				gameUser.permissions?.hasOwnProperty('view_npc_state') ||
				gameUser.permissions?.hasOwnProperty('view_npc')) ? (
				<div className='adminD'>
					<div className='adminDesign'>
						<div className='adminDesign__container ExtendManageExternal'>
							<div className='adminDesign__container__mainContainerBank'>
								<div className='adminDesign__container__mainContainerBank__Item'>
									<div className='adminDesign__container__mainContainerBank__Item__content'>
										<NpcStateCollapse />
										{gameUser.permissions?.hasOwnProperty(
											'view_npc'
										) ? (
											<ListNpcs />
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<ErrorPerms />
			)}
		</div>
	)
}

import { API_UPDATE_MISSION } from "../../utils/constants"
import { NPC } from "./NPC"
import { StringTag } from "./StringTag"

export class NPCLostItem extends NPC {
	#textTag
	#messages = {
		first: '',
		deliver:
			'Muchísimas gracias, no lo habría recuperado sino fuera por ti.',
		nothing:
			'Oh no, he perdido un par de cosas. Si me ayudas a encontrarlas te recompesaré.',
		complete: 'Eres increible, he recuperado todas mis cosas gracias a ti.',
		alreadyCompleted: 'No tengo más cosas perdidas...',
	}

	create(scene, bodyType) {
		super.create(scene, bodyType)

		// ----- RESIZE THE BODY BECAUSE THE LORO STAYS TO AWAY FROM IT.
		this.sprite.setBody({
			type: 'rectangle',
			width: this.sprite.width - 70,
			height: this.sprite.height - 35,
		})
		this.sprite.setStatic(true)

		// ----- CREATE TEXT TAG
		this.#textTag = new StringTag(
			{ x: this.pos.x, y: this.pos.y - this.sprite.height / 2 - 10 },
			{ x: 0.5, y: 1 },
			this.dialogues[0].translation,
			'map__npc__top__text',
			true
		)
		this.#textTag.create(scene)
		this.#textTag.enableVisibility(false)
	}
	switchStateTo(state) {
		if (state === 'greeting') {
			this.greetingTag.element.setHTML('...')
			this.greetingTag.element.setPosition(-180, 0)
		}
	}
	async deliver(
		scene,
		profileData,
		setReloadScene,
		axiosSupreme,
		setLostItemsThereIsAnUnseenInteraction,
		updateProfileData
	) {
		try {
			const itemDeliveredUrl =
				API_UPDATE_MISSION +
				this.missions.id +
				'/' +
				profileData.id_profile +
				'/'
			const itemDeliveredRequest = await axiosSupreme(
				'patch',
				itemDeliveredUrl,
				{ stars: 0 }
			)
			// ----- SHOW THE LOADING TEXT
			this.greetingTag.element.setHTML(
				this.#messages[itemDeliveredRequest.status]
			)
			this.greetingTag.element.setPosition(0, 0)

			// ----- SHOW THE LOST ITEMS NOTIFICATION CIRCLE
			if (itemDeliveredRequest.status === 'nothing') return
			if (itemDeliveredRequest.status === 'alreadyCompleted') return

			if (itemDeliveredRequest.status === 'complete') {
				this.feedbackTags.play(scene, false, [
					itemDeliveredRequest.data.reward,
					'XP:' + itemDeliveredRequest.data.xp,
				])

				updateProfileData()
			}
			setLostItemsThereIsAnUnseenInteraction(true)
			setReloadScene((rs) => !rs)
		} catch (error) {
			console.error(error)
			this.deliver(
				scene,
				profileData,
				setReloadScene,
				axiosSupreme,
				setLostItemsThereIsAnUnseenInteraction,
				updateProfileData
			)
		}
	}
	onStartZoneCollision(
		profileData,
		setReloadScene,
		scene,
		axiosSupreme,
		setLostItemsThereIsAnUnseenInteraction,
		updateProfileData
	) {
		super.switchStateTo('greeting')
		this.switchStateTo('greeting')
		this.deliver(
			scene,
			profileData,
			setReloadScene,
			axiosSupreme,
			setLostItemsThereIsAnUnseenInteraction,
			updateProfileData
		)
	}
	onEndZoneCollision() {
		super.switchStateTo('normal')
	}
	destroyStart() {
		super.destroyStart()
		this.#textTag.container.setPosition(10000, 10000)
	}
	destroyEnd(profileData, setReloadScene, scene, axiosSupreme) {
		super.destroyEnd()
		// this.deliver(scene, profileData, setReloadScene, axiosSupreme) // ****** REVISAR LA COHERENCIA DE LLAMAR ESTA LINEA.
	}
}

import { useEffect, useRef, useState } from 'react'
import '../../styles/Hangman.scss'

import boton from '../../assets/icons/boton.png'
import { useGameStatus } from '../../provider/ActivityProvider'
import Lives from '../fragments/Lives'
import ImageOnFlex1, { PanelOfWords } from '../fragments/ImageOnFlex1'
import AudioHelpButton from '../fragments/AudioHelpButton'
import AudioGame from '../fragments/AudioGame'
import { useSoundAction } from '../../hooks/useSoundAction'

const alphabet = 'abcdefghijklmnopqrstuvwxyzåäö'

/**
 * Renders a game with the words in wordsData passed by the Playground Component
 * @param {Array<Object>} wordsData Each object has a word, translation, audio, image, etc...
 * @returns {JSX}
 */
const Hangman = ({ wordsData }) => {
	const [, setGameStatus] = useGameStatus()

	/**
	 * @type {Number} currentWordIndex the index of the current word
	 * @type {Number} instantForProgressWordIndex like currentWordIndex but changes faster (doesn't waits
	 * for timeouts when the user completes the word)
	 * @type {Number} opacity the transparency of the main div
	 */
	const [currentWordIndex, setCurrentWordIndex] = useState(0)
	const [instantForProgressWordIndex, setInstantForProgressWordIndex] =
		useState(0)
	const [opacity, setOpacity] = useState(1)

	/**
	 * SYNC WITH THE WORDS LIST
	 */
	const nextInProgressBar = () => {
		// INCREMENT THE PROGRESS WORD INDEX
		const nextInstant = instantForProgressWordIndex + 1
		setInstantForProgressWordIndex(nextInstant)

		// IF ALL OF THE WORDS WERE COMPLETED THEN INFORM THE DIALOGUE PROVIDER
		if (nextInstant >= wordsData.length) {
			setGameStatus('completed')
		}
	}

	/**
	 * Destroy this block and create another with different data
	 * @returns
	 */
	const goToNextSection = () => {
		if (currentWordIndex >= wordsData.length - 1) return

		// ANIMATE THE CONTAINER TO BE INVISIBLE
		setOpacity(0)
		// WAIT X TIME AND
		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			// INCREASE THE CURRENT WORD INDEX
			setCurrentWordIndex(currentWordIndex + 1)

			// WAITS X TIME AND SHOW THE CONTAINER AGAIN
			const timeout2 = setTimeout(() => {
				clearTimeout(timeout2)
				setOpacity(1)
			}, 700)
		}, 1600)
	}

	return (
		<div className='playground__game playground__hangman'>
			<div
				className='playground__hangman__section'
				style={{
					transition: 'opacity 600ms ease-in',
					opacity: opacity,
				}}>
				<HangmanSection
					key={currentWordIndex}
					wordsData={wordsData}
					currentWordIndex={currentWordIndex}
					goToNextSection={goToNextSection}
					nextInProgressBar={nextInProgressBar}
				/>
			</div>
		</div>
	)
}

/**
 * Renders a div with all of the content, this gets destroyed and the created again with the new data.
 * @param {Array<Object>} wordsData (@type wordsData)
 * @param {Number} currentWordIndex the index of the current word
 * @param {Function} goToNextSection function that destroys and creates this component
 * @param {Function} nextInProgressBar sync the words list
 * @returns {JSX}
 */
const HangmanSection = ({
	wordsData,
	currentWordIndex,
	goToNextSection,
	nextInProgressBar,
}) => {
	const [, setGameStatus, gameMistakesCounter] = useGameStatus()
	const { soundAction } = useSoundAction()
	const [showPanel, setShowPanel] = useState(false)
	// ------------------------------------------------------------------------------ $EVERYWHERE

	function unique(str) {
		return String.prototype.concat.call(...new Set(str))
	}
	const actualWordUniqueLetters = unique(wordsData[currentWordIndex].word)

	/**
	 * @type {Boolean} isGameOver if the game is over or not
	 * @type {String} descriptiveImageSrc the url of the image of the word
	 * @type {Array} secretWord the data of the secret word
	 * @type {Array} secretLetters the data of the secret letters
	 */
	const [isGameOver, setGameOver] = useState(false)
	const [descriptiveImageSrc, setDescriptiveImageSrc] = useState('')
	const [secretWord, setSecretWord] = useState([])
	const [secretLetters, setSecretLetters] = useState([])

	/**
	 * @type {Number} additionalLettersAmount the amount of letter options
	 * @type {Boolean} letterOptionsInputDisabled if the letter buttons are enabled or not
	 * @type {Array<Object>} letterOptions all of the data of the letter options
	 */
	const additionalLettersAmount =
		actualWordUniqueLetters.length % 2 === 0 ? 4 : 3
	const [letterOptionsInputDisabled, setLetterOptionsInputDisabled] =
		useState(true)
	const [letterOptions, setLetterOptions] = useState([])

	const [mistakesCounter, setMistakesCounter] = useState(0)

	/**
	 * @type {String} audioSrc the url of the actual word
	 * @type {Function} secretWordNormalAgainTimeout programm a time over which the secret word
	 * is normal again
	 */
	const [audioSrc, setAudioSrc] = useState('')
	const secretWordNormalAgainTimeout = useRef(setTimeout(() => {}, 0))

	// ------------------------------------------------------------------------------ $BIRTH
	// They are something now, they have an initial state and some knowledge
	// they already learned.

	/**
	 * CREATE ALL OF THE STRUCTURE OF THE GAME
	 */
	useEffect(() => {
		birthOfMatter()
	}, [])

	function birthOfMatter() {
		setGameOver(false)

		setDescriptiveImageSrc(wordsData[currentWordIndex].image)

		setSecretWord(secretWordBirth(wordsData[currentWordIndex].word))
		setSecretLetters(secretLettersBirth(wordsData[currentWordIndex].word))

		setMistakesCounter(0)

		setLetterOptionsInputDisabled(false)
		setLetterOptions(letterOptionsBirth(actualWordUniqueLetters))
	}

	/**
	 * Create the data of the secret word
	 * @param {String} word the actual word the user have to complete
	 * @returns (@type secretWord)
	 */
	const secretWordBirth = (word) => {
		const newSecretWord = {}
		newSecretWord.state = secretWordStates.normal
		newSecretWord.word = word
		return newSecretWord
	}

	/**
	 * Create the data of the secret letters
	 * @param {String} word the actual word the user have to complete
	 * @returns
	 */
	const secretLettersBirth = (word) => {
		const newSecretLetters = []
		for (const letter of word) {
			const newSecretLetter = {}
			if (letter === ' ') {
				newSecretLetter.state = secretLetterStates.founded
				newSecretLetter.value = letter
				newSecretLetter.letter = letter
				newSecretLetter.done = true
			} else {
				newSecretLetter.state = secretLetterStates.normal
				newSecretLetter.value = letter
				newSecretLetter.letter = '_'
				newSecretLetter.done = false
			}
			newSecretLetters.push(newSecretLetter)
		}

		return newSecretLetters
	}

	/**
	 * Create the data of the letter options
	 * @param {String} word the actual word the user have to complete
	 * @returns
	 */
	const letterOptionsBirth = (word) => {
		// LIST OF LETTERS
		// This list is going to have unique letters, no repeated letters
		// because each option must be unique, I dont want to press the same wrong letter two times.
		const letters = []

		// Fill the letters array with the letters of the word... so the user can actually
		// complete the word.
		for (const letter of word) {
			if (!letters.includes(letter)) {
				letters.push(letter)
			}
		}

		// The rest of the letters fill them with random different letters
		for (
			let num = 0;
			num < additionalLettersAmount; // *** SI LA PALABRA TIENE MAS LETRAS DIFERENTES QUE EL NUMERO DE OPCIONES ESTO FALLA.
			num++
		) {
			let randomLetter = ''
			do {
				randomLetter =
					alphabet[Math.round(Math.random() * (alphabet.length - 1))]
			} while (letters.includes(randomLetter))
			letters.push(randomLetter)
		}

		// RANDOM THE LIST OF THE LETTERS
		let randomLetters = []

		while (letters.length > 0) {
			const randomPosition = Math.round(
				Math.random() * (letters.length - 1)
			)
			// At random position pop 1 item.
			const poppedLetter = letters.splice(randomPosition, 1)[0]
			randomLetters.push(poppedLetter)
		}
		// THE BIRTH -----------

		const newLetterOptions = []

		for (const letter of randomLetters) {
			const newLetterOption = {}
			if (letter === ' ') {
				newLetterOption.state = letterOptionStates.disabled
				newLetterOption.letter = '___'
				newLetterOption.disabled = true
			} else {
				newLetterOption.state = letterOptionStates.active
				newLetterOption.letter = letter
				newLetterOption.disabled = false
			}

			newLetterOptions.push(newLetterOption)
		}

		return newLetterOptions
	}

	// ------------------------------------------------------------------------------ $REACTIONS
	// This is a very important place, here is where matter reacts to everything
	// that happens, here is where they change its state, and made other to change
	// it as well. They learn new things, they see, they know, they react.

	/**
	 * Called each time a user clicks a letter option.
	 * @param {Object} e the HTML event triggered when the user clicks a letter option
	 */
	function letterOptionReaction(e) {
		const letterOptionID = parseInt(e.currentTarget.id)
		const letterOption = letterOptions[letterOptionID]

		if (letterOption.disabled || letterOptionsInputDisabled || isGameOver)
			return

		// cancel all posible timers and establish the new value.
		clearTimeout(secretWordNormalAgainTimeout.current)
		setSecretWord({ ...secretWord, state: secretWordStates.normal })

		// Disable the selected letter forever :c
		setLetterOptions(
			letterOptions.map((l, i) => {
				if (i !== letterOptionID) return l
				return {
					...l,
					state: letterOptionStates.disabled,
					disabled: true,
				}
			})
		)

		// Verificar la palabra secreta contiene la letra de la opcion.
		const letterInSecretWord = secretWord.word.includes(letterOption.letter)
		if (letterInSecretWord) correctLetterSelection(letterOption)
		else incorretLetterSelection()
	}

	/**
	 * Called when the user selects a letter that is in the word
	 * @param {Object} letterOption contains all of the data of the letter option
	 */
	const correctLetterSelection = (letterOption) => {
		soundAction('effect', 'TLIJ2', 'play')

		// Animate the founded letter in the secret word and calculate if secret word is completed.
		let secretWordCompleted = true
		setSecretLetters(
			secretLetters.map((sl) => {
				if (sl.value !== letterOption.letter) {
					if (!sl.done) secretWordCompleted = false
					return sl
				}
				return {
					...sl,
					state: secretLetterStates.founded,
					letter: sl.value,
					done: true,
				}
			})
		)

		if (secretWordCompleted) {
			wordCompleted()
		}
	}

	/**
	 * Called when the user selects a letter that is not in the word
	 */
	const incorretLetterSelection = () => {
		// Animate the secret word to incorrect and then return it to normal
		setSecretWord({ ...secretWord, state: secretWordStates.incorrect })
		secretWordNormalAgainTimeout.current = setTimeout(() => {
			clearTimeout(secretWordNormalAgainTimeout.current)
			setSecretWord({ ...secretWord, state: secretWordStates.normal })
		}, 1000)

		gameMistakesCounter.current += 1
		setMistakesCounter(mistakesCounter + 1)
	}

	const audioAlreadyStartedRef = useRef(false)
	const audioAlreadyStartedTimeoutRef = useRef(null)
	const audioAlreadyStartedTimeLimit = 2000
	const wordWasCompletedRef = useRef(false)
	/**
	 * Called when the user selects all of the letters in the word
	 */
	const wordCompleted = () => {
		wordWasCompletedRef.current = true
		setAudioSrc(wordsData[currentWordIndex].audio)

		setGameOver(true)
		setSecretWord({ ...secretWord, state: secretWordStates.completed })
		disableAllLetterOptions()

		audioAlreadyStartedRef.current = false
		audioAlreadyStartedTimeoutRef.curret = setTimeout(() => {
			clearTimeout(audioAlreadyStartedTimeoutRef.curret)

			// Si entra aquí, es porque el audio nunca arrancó.
			// asi de debemos ganar manualmente
			if (!audioAlreadyStartedRef.current) win()
		}, audioAlreadyStartedTimeLimit)
		// const timeout = setTimeout(() => {
		// 	clearTimeout(timeout)
		// 	win()
		// }, 800)
	}

	const onDeath = () => {
		// Cancel both timeouts and gameover.
		clearTimeout(secretWordNormalAgainTimeout.current)
		setGameOver(true)
		disableAllLetterOptions()
		setGameStatus('gameOver')
	}

	/**
	 * Called when the user completes the word selecting all the right letter options
	 */
	function win() {
		// nextInProgressBar()
		// const timeout = setTimeout(() => {
		// 	goToNextSection()
		// 	clearTimeout(timeout)
		// }, 4000)

		nextInProgressBar()
		goToNextSection()

		/**
		 * Básicamente necesito esperar a que el audio termine de sonar
		 * para ejecutar goToNextSection
		 * Y si es la ultima palabra entonces
		 *
		 * En otras palabras, win se ejecuta desde onAudioEnd
		 * o desde un timeout en caso de que se demore mucho en comenzar.
		 */
	}

	/**
	 * Disable all letter options, so the user cannot click on any of them
	 */
	const disableAllLetterOptions = () => {
		// FOR EACH LETTER OPTION OF OPTIONS
		setLetterOptions(
			letterOptions.map((letterOption) => {
				// DISABLE THE OPTION
				return {
					...letterOption,
					state: letterOptionStates.disabled,
					disabled: true,
				}
			})
		)
	}

	/**
	 * Called when the audio starts.
	 * Sets the NPCprofile expression to talking.
	 */
	const handleAudioStart = (audioIndex) => {
		clearTimeout(audioAlreadyStartedTimeoutRef.current)
		audioAlreadyStartedRef.current = true
	}

	/**
	 * Called when the audio ends.
	 */
	const handleAudioEnd = () => {
		if (!wordWasCompletedRef.current) return
		clearTimeout(audioAlreadyStartedTimeoutRef.current)
		win()
	}

	// ------------------------------------------------------------------------------ $STATES
	const secretWordStates = {
		normal: 'playground__hangman__section__secret__word',
		incorrect:
			'playground__hangman__section__secret__word playground__hangman__section__secret__word__animation__incorrect',
		completed:
			'playground__hangman__section__secret__word playground__hangman__section__secret__word__animation__completed',
		isGameOver:
			'playground__hangman__section__secret__word playground__hangman__section__secret__word__animation__game__over',
	}

	const secretLetterStates = {
		normal: 'playground__hangman__section__secret__word__letter',
		founded:
			'playground__hangman__section__secret__word__letter playground__hangman__section__secret__word__letter__animation__founded',
	}

	const letterOptionStates = {
		// inactive:
		//   'playground__hangman__section__letter__options__section__grid__letter__option',
		active: 'playground__hangman__section__letter__options__section__grid__letter__option playground__hangman__section__letter__options__section__grid__letter__option__active',
		disabled:
			'playground__hangman__section__letter__options__section__grid__letter__option playground__hangman__section__letter__options__section__grid__letter__option__disabled',
	}

	// ------------------------------------------------------------------------------ $SPACE
	// Here is where all matter lives, this is the last step, the result
	// the why of everything else. The purpose of all the code behind this one.

	const oneRef = useRef(null)
	const once = useRef(null)

	useEffect(() => {
		if (!oneRef.current) return
		if (secretLetters.length === 0) return
		if (once.current) return
		once.current = true
		setTimeout(() => {
			oneRef.current.style.flex = 'none'
			// oneRef.current.style.width = '50px'
			// oneRef.current.style.backgroundColor = 'gray'
		}, 15)
	}, [secretLetters])

	/**
	 * Cómo sé cuantas letras unicas tiene la palabra?
	 */

	const handlerShowPanel = () => {
		setShowPanel(prevShowPanel => !prevShowPanel)
	}

	return (
		<>
			<div className='playground__hangman__section__top'>
				<PanelOfWords
					wordsData={wordsData}
					isPanelFloating={true}
					showPanel={showPanel}
					handlerShowPanel={handlerShowPanel}
				/>

				<button
					className={`
						playground__hangman__section__top__button_panel
						${showPanel ? 'playground__hangman__section__top__button_panel__hidden' : ''}
					`}
					onClick={handlerShowPanel}
				>
					<img src={boton} alt="botón para desplegar el panel" />
				</button>
				<div
					className='playground__hangman__section__top__one'
					ref={oneRef}>
					{secretLetters.length > 0 && (
						<ImageOnFlex1 src={descriptiveImageSrc}>
							<AudioHelpButton
								src={
									wordsData[currentWordIndex].audio
								}></AudioHelpButton>
						</ImageOnFlex1>
					)}
					<SecretWord word={secretWord} letters={secretLetters} />
				</div>
				<Lives
					amount={additionalLettersAmount}
					mistakesCounter={mistakesCounter}
					onDeath={onDeath}
					columnsAmount={1}
				/>
			</div>
			<LetterOptions
				options={letterOptions}
				onClick={letterOptionReaction}
			/>
			<AudioGame
				src={audioSrc}
				handleAudioStart={handleAudioStart}
				handleAudioEnd={handleAudioEnd}></AudioGame>
		</>
	)
}

//
//
//
//
// ------------------------------------------------------------------------------ $COMPONENTS

const SecretWord = ({ word, letters }) => {
	return (
		<div className={word.state}>
			{letters.map((secretLetter, index) => {
				return (
					<div
						key={index}
						className='playground__hangman__section__secret__word__container'>
						<div className={secretLetter.state}>
							{secretLetter.letter}
						</div>
						{secretLetter.letter !== ' ' ? (
							<div className='playground__hangman__section__secret__word__underline' />
						) : (
							''
						)}
					</div>
				)
			})}
		</div>
	)
}

const LetterOptions = ({ options, onClick }) => {
	return (
		<div className='playground__hangman__section__letter__options__section'>
			<div
				className='playground__hangman__section__letter__options__section__grid'
				style={{
					gridTemplateColumns:
						'repeat(' + options.length / 2 + ', 1fr)',
				}}>
				{options.map((letterOption, index) => {
					return (
						<div
							key={index}
							id={index}
							className={letterOption.state}
							onClick={onClick}
							onKeyUp={onClick}
							role='button'
							tabIndex={-5}>
							{letterOption.letter}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default Hangman

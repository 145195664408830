import { useEffect, useState } from 'react'
import '../../../../styles/Permissions.scss'

import { API_MANAGE_DELETE_PERMISSION } from '../../../../utils/constantsAdmin'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
export default function PermissionsAdded({
	idGroup,
	objectPerms,
	setRefreshPerms,
	setRefreshNoPerms,
}) {
	const { gameUser, axiosSupreme } = useAuthProvider()
	// SET OBJECT PERMS
	const [stateObjectPerms, setStateObjectPerms] = useState(undefined)
	useEffect(() => {
		setStateObjectPerms(objectPerms)
	}, [objectPerms])

	const deletePermission = async () => {
		// MAKE DICT
		let formData = new FormData()
		formData.append('id_group', idGroup)
		formData.append('id_permission', stateObjectPerms.id)

		const result_data = await axiosSupreme(
			'put',
			API_MANAGE_DELETE_PERMISSION,
			formData
		)
		setRefreshPerms((prev) => !prev)
		setRefreshNoPerms((prev) => !prev)
	}

	return (
		<>
			{stateObjectPerms !== undefined ? (
				<div className='perms__container__content__toDel__content'>
					<div className='text'>
						<h1>{stateObjectPerms.name}</h1>
					</div>
					<div className='btn'>
						<button className='btnMenos' onClick={deletePermission}>
							-
						</button>
					</div>
				</div>
			) : null}
		</>
	)
}

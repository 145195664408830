import { useEffect, useState } from 'react'
import { useSoundAction } from '../../hooks/useSoundAction'

import '../../styles/LevelResults.scss'

import startFull from '../../assets/icons/starFull.png'
import perfectIcon1 from '../../assets/images/GameResults/Perfect/P1.png'
import perfectIcon2 from '../../assets/images/GameResults/Perfect/P2.png'
import perfectIcon3 from '../../assets/images/GameResults/Perfect/P3.png'
import perfectIcon4 from '../../assets/images/GameResults/Perfect/P4.png'
import perfectIcon5 from '../../assets/images/GameResults/Perfect/P5.png'
import perfectIcon6 from '../../assets/images/GameResults/Perfect/P6.png'
import perfectIcon7 from '../../assets/images/GameResults/Perfect/P7.png'

const perfectIcons = [
	perfectIcon1,
	perfectIcon2,
	perfectIcon3,
	perfectIcon4,
	perfectIcon5,
	perfectIcon6,
	perfectIcon7,
]

const LevelResults = ({ data, onClose, onNext }) => {
	const [containerStyle, setContainerStyle] = useState({
		opacity: 0,
	})
	const { soundAction } = useSoundAction()

	function handleOnClose() {
		setContainerStyle((cs) => ({
			...cs,
			opacity: 0,
		}))
		const timeout = setTimeout(() => {
			clearTimeout(timeout)
			onClose()
		}, 500)
	}
	useEffect(() => {
		soundAction('effect', 'TLSI10', 'play')
		setContainerStyle((cs) => ({
			...cs,
			opacity: 1,
		}))
	}, [])
	/**
	 * Basicamente cambiar la opacidad cuando entra y cuando sale
	 */
	return (
		<section className='level__results' style={containerStyle}>
			<div className='level__results__card'>
				<h1>¡FELICITACIONES!</h1>
				<h2>NIVEL COMPLETADO</h2>
				<div className='level__results__card__image'>
					<img
						src={
							perfectIcons[
								Math.round(
									Math.random() * (perfectIcons.length - 1)
								)
							]
						}
						alt='resultado del nivel'
					/>
				</div>
				{data.total_stars_profile !== 0 && data.total_stars !== 0 && (
					<div className='level__results__card__stars'>
						<div className='level__results__card__stars__image'>
							<img src={startFull} alt='star' />
						</div>
						<div className='level__results__card__stars__percentage'>
							{data.total_stars_profile}/{data.total_stars}
						</div>
					</div>
				)}
				<div className='level__results__card__buttons'>
					<button
						className='level__results__card__buttons__complete'
						// className='level__results__card__buttons__try__again'
						// disabled={true} //{containerStyle.opacity !== 1}
						onClick={handleOnClose}>Cerrar</button>
					{/* <button
						className='level__results__card__buttons__complete'
						disabled={containerStyle.opacity !== 1}
						onClick={() => {
							onNext()
							soundAction('effect', 'TLSG8', 'play')
						}}>
						Siguiente
					</button> */}
				</div>
			</div>
		</section>
	)
}

export default LevelResults

import { useContext, useEffect, useState } from 'react'
import AlertContext from '../../../../context/editor/AlertContext'
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs'
import { DialogueBodyCollapse } from './DialogueBodyCollapse'
import { API_MANAGE_DIALOGUES } from '../../../../utils/constantsAdmin'
import { FormDialogues } from './FormDialogues'
import ModalContext from '../../../../context/editor/ModalContext'
import DeleteContext from '../../../../context/editor/DeleteContext'
import { toggleCollapse } from '../../../../utils/general_functions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import { useTranslation } from 'react-i18next'

export const DialogueCollapse = ({
	id_npc_compose_sub_world_scene_npc_appearance,
	objectDialogue,
	setRefreshDialogues,
	activeCollapseDialogue,
	setActiveCollapseDialogue,
}) => {
	const {t} = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// SET OBJECT ITEM
	const [stateObjectDialogue, setStateObjectDialogue] = useState(undefined)
	useEffect(() => {
		setStateObjectDialogue(objectDialogue)
	}, [objectDialogue])

	// ALERT
	const {
		showAlert,
		setMessage: setMessageAlert,
		setTypeMessage,
	} = useContext(AlertContext)

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// DELETE
	const { openDelete, setMessage, setFunctionDelete } =
		useContext(DeleteContext)

	// UPDATE DIALOGUE
	const UpdateDialogue = async () => {
		setTitleModal(t("pages.editor.components.dialogue_collapse.title"))
		setContentModal(
			<FormDialogues
				id_npc_compose_sub_world_scene_npc_appearance={
					id_npc_compose_sub_world_scene_npc_appearance
				}
				stateObjectDialogue={stateObjectDialogue}
				setStateObjectDialogue={setStateObjectDialogue}
				setRefreshDialogues={setRefreshDialogues}
			/>
		)
		openModal()
	}

	const DeleteDialogue = async () => {
		const result_data = await axiosSupreme(
			'delete',
			API_MANAGE_DIALOGUES + stateObjectDialogue.id_dialogue + '/',
			undefined
		)
		if (result_data.hasOwnProperty('response')) {
			openDelete()
			setTypeMessage('error')
			setMessageAlert(
				t("common.cannot_delete")
			)
			showAlert()
		} else {
			setRefreshDialogues((prev) => !prev)
			openDelete()
		}
	}

	return (
		<>
			{stateObjectDialogue !== undefined ? (
				<div className='dialoguesComponents'>
					<div className='dialoguesComponents__button collapseStylesBtn'>
						<div className='arrowOpenCollapse'>
							{activeCollapseDialogue ===
							stateObjectDialogue.id_dialogue ? (
								<FaChevronUp />
							) : (
								<FaChevronDown />
							)}
						</div>
						<div
							className='dialoguesComponents__button__title title'
							onClick={() =>
								toggleCollapse(
									activeCollapseDialogue,
									setActiveCollapseDialogue,
									stateObjectDialogue.id_dialogue
								)
							}>
							<h2>
								{stateObjectDialogue.order}.
								{stateObjectDialogue.text}
							</h2>
						</div>
						<div className='dialoguesComponents__button__options options'>
							{gameUser.permissions?.hasOwnProperty(
								'delete_dialogue'
							) ? (
								<button
									className='editorBtnActionDelete'
									onClick={() => {
										setFunctionDelete(() => DeleteDialogue)
										setMessage(
											t("pages.editor.components.dialogue_collapse.confirm_deleting_1") +
												stateObjectDialogue.text +
												'?'
										)
										openDelete()
									}}>
									<p>
										<BsFillTrashFill />{' '}
									</p>
								</button>
							) : null}
							{gameUser.permissions?.hasOwnProperty(
								'change_dialogue'
							) ? (
								<button
									className='editorBtnActionEdit'
									onClick={UpdateDialogue}>
									<p>
										<BsFillPencilFill />
									</p>
								</button>
							) : null}
						</div>
					</div>
					{activeCollapseDialogue ===
						stateObjectDialogue.id_dialogue && (
						<DialogueBodyCollapse
							stateObjectDialogue={stateObjectDialogue}
						/>
					)}
				</div>
			) : null}
		</>
	)
}

import React, { useEffect, useState } from 'react'
import {
	API_MANAGE_GROUP_BY_USER,
	API_MANAGE_GROUP_EXCEPT_BY_USER,
} from '../../../../utils/constantsAdmin'
import { SearchCamp } from '../../searchAndPagination/SearchCamp'
import { GroupToAdd } from './GroupToAdd'
import { Pagination } from '../../searchAndPagination/Pagination'
import { GroupAdded } from './GroupAdded'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'

export const MagaUserGroups = ({ stateObjectUser }) => {
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()


	// VALUES SEARCH
	const [search, setSearch] = useState('')

	// VALUES SEARCH
	const [searchTwo, setSearchTwo] = useState('')

	// REFRESH GROUPS
	const [refreshUserGroup, setRefreshUserGroup] = useState(false)

	// DATA
	const [data, setData] = useState(undefined)
	const [dataNoGroups, setDataNoGroups] = useState(undefined)

	// GET ALL PERMS BY GROUP
	useEffect(() => {
		if (stateObjectUser !== undefined) {
			getData()
		}
	}, [refreshUserGroup])

	const getData = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_GROUP_BY_USER + stateObjectUser.id + '/'
			)
			setData(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	// REFRESH NO GROUPS
	const [refreshNoGroups, setRefreshNoGroups] = useState(false)

	// GET ALL GROUPS BY USERS
	useEffect(() => {
		if (stateObjectUser !== undefined) {
			getDataNoGroups()
		}
	}, [refreshNoGroups])

	const getDataNoGroups = async () => {
		try {
			const result_data = await axiosSupreme(
				'get',
				API_MANAGE_GROUP_EXCEPT_BY_USER + stateObjectUser.id + '/'
			)
			setDataNoGroups(result_data)
		} catch (e) {
			console.log('Error get Data: ', e)
		}
	}

	return (
		<div className='groups__container'>
			<div className='groups__container__title'>
				<h1>Gestionar grupos para el usuario</h1>
			</div>
			<div className='groups__container__content'>
				<div className='groups__container__content__toAdd'>
					<div className='groups__container__content__toAdd__title'>
						<h1>Agregar</h1>
					</div>
					{dataNoGroups !== undefined ? (
						<>
							{dataNoGroups === 'search' ||
							dataNoGroups.results.length > 0 ? (
								<>
									<SearchCamp
										setData={setDataNoGroups}
										setRefreshDataList={setRefreshNoGroups}
										url={
											API_MANAGE_GROUP_EXCEPT_BY_USER +
											stateObjectUser.id +
											'/'
										}
										search={search}
										setSearch={setSearch}
									/>
									{dataNoGroups === 'search' ? (
										<p className='noSelect'>
											No se ha encontrado ninguna
											animación
										</p>
									) : null}
								</>
							) : (
								<p className='noSelect'>
									No existe ninguna animación registrada.
								</p>
							)}

							{dataNoGroups !== 'search' ? (
								<>
									{dataNoGroups.results.map(
										(data_item, index) => (
											<GroupToAdd
												key={data_item.id}
												idUser={stateObjectUser.id}
												objectNoGroups={data_item}
												setRefreshNoGroups={
													setRefreshNoGroups
												}
												setRefreshUserGroup={
													setRefreshUserGroup
												}
											/>
										)
									)}
									<Pagination
										data={dataNoGroups}
										setData={setDataNoGroups}></Pagination>
								</>
							) : null}
						</>
					) : null}
				</div>
				<div className='groups__container__content__toDel'>
					<div className='groups__container__content__toDel__title'>
						<h1>Remover</h1>
					</div>
					{data !== undefined ? (
						<>
							{data === 'search' || data.results.length > 0 ? (
								<>
									<SearchCamp
										setData={setData}
										setRefreshDataList={setRefreshUserGroup}
										url={
											API_MANAGE_GROUP_BY_USER +
											stateObjectUser.id +
											'/'
										}
										search={searchTwo}
										setSearch={setSearchTwo}
									/>
									{data === 'search' ? (
										<p className='noSelect'>
											No se ha encontrado ninguna
											animación
										</p>
									) : null}
								</>
							) : (
								<p className='noSelect'>
									No existe ninguna animación registrada.
								</p>
							)}

							{data !== 'search' ? (
								<>
									{data.results.map((data_item, index) => (
										<GroupAdded
											key={data_item.id}
											idUser={stateObjectUser.id}
											objectGroups={data_item}
											setRefreshUserGroup={
												setRefreshUserGroup
											}
											setRefreshNoGroups={
												setRefreshNoGroups
											}
										/>
									))}
									<Pagination
										data={data}
										setData={setData}></Pagination>
								</>
							) : null}
						</>
					) : null}
				</div>
			</div>
		</div>
	)
}

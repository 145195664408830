import { Sprite } from "./Sprite"
import { Zone } from "./Zone"

/**
 * This class generates a sprite with a zone, for any kind of interaction when collided
 */
export class SpriteWithZoneAndCollisions extends Sprite {
	#zone
	get zone() {
		return this.#zone
	}

	create(scene, bodyType, zoneSize, label, isCircle) {
		super.create(scene, bodyType)

		// ------ CREATE THE ZONE
		let newZoneSize
		if (zoneSize === undefined) {
			newZoneSize = {
				x: this.sprite.width,
				y: this.sprite.height,
			}
		} else if (typeof zoneSize === 'number') {
			newZoneSize = {
				x: this.sprite.width + zoneSize,
				y: this.sprite.height + zoneSize,
			}
		} else {
			newZoneSize = zoneSize
		}

		this.#zone = new Zone(
			scene,
			label,
			this.id,
			this.pos,
			newZoneSize,
			isCircle ? 'circle' : 'rectangle'
		)
	}
}

import { useState } from 'react'
import '../../styles/WindowPopUp.scss'
import Store from './Store'
import Equipment from './Equipment'

const Inventory = ({ activeTabIndex, profileData }) => {
	const [storeChanged, setStoreChanged] = useState(true)

	return (
		<>
			{activeTabIndex === 0 ? (
				<Equipment
					profileData={profileData}
					storeChanged={storeChanged}
					setStoreChanged={setStoreChanged}></Equipment>
			) : (
				<Store
					profileData={profileData}
					setStoreChanged={setStoreChanged}></Store>
			)}
		</>
	)
}

export default Inventory

import { useContext, useEffect, useState } from 'react'
import {
	API_MANAGE_DOORS,
	API_MANAGE_SCENE_SUB_WORLDS,
	API_MANAGE_SUBWORLD_DOORS,
	API_MANAGE_SCENE_WORLDS,
	API_MANAGE_PATH_DOORS,
	API_MANAGE_PATH_SUBWORLD_DOORS,
} from '../../../../utils/constantsAdmin'
import ModalContext from '../../../../context/editor/ModalContext'
import { FormDestinationDoor } from '../DestinationDoor/FormDestinationDoor'
import { ManageStorageAdminDesigner } from '../../../../utils/local_storage'
import { useAuthProvider } from '../../../../context/AuthProvider/AuthProvider'
import AlertContext from '../../../../context/editor/AlertContext'
import { useTranslation } from 'react-i18next'

export const BodyCollapseDoorSceneWorld = ({
	stateObjectDoor,
	setStateObjectDoor,
}) => {
	const { t } = useTranslation()
	// AUTH PERMISSION
	const { gameUser, axiosSupreme } = useAuthProvider()

	// ALERT
	const { showAlert, setMessage, setTypeMessage } = useContext(AlertContext)

	// LOCAL STORAGE
	const { gettypeContextSpace } = ManageStorageAdminDesigner()

	const [infoDestination, setInfoDestination] = useState(
		t("pages.editor.components.body_collapse_door_scene_world.searching_door")
	)

	useEffect(() => {
		if (
			(gettypeContextSpace() === 'World' &&
				gameUser.permissions?.hasOwnProperty('view_world_door_path')) ||
			(gettypeContextSpace() === 'Subworld' &&
				gameUser.permissions?.hasOwnProperty(
					'view_sub_world_door_path'
				))
		) {
			getDetailObjectDoor()
		}
	}, [stateObjectDoor])

	// GET DETAIL DOOR
	const getDetailObjectDoor = async () => {
		if (gettypeContextSpace() == 'World') {
			if (stateObjectDoor.world_door_path !== null) {
				// HACIA UN MUNDO
				if (stateObjectDoor.world_door_path.world_door_destination) {
					returnDestinationWorldDoor(
						stateObjectDoor.world_door_path.world_door_destination
					)
				}
				// HACIA UN SUBMUNDO
				if (
					stateObjectDoor.world_door_path.sub_world_door_destination
				) {
					returnDestinationSubWorldDoor(
						stateObjectDoor.world_door_path
							.sub_world_door_destination
					)
				}
			} else {
				setInfoDestination(
					t("pages.editor.components.body_collapse_door_scene_world.no_destination_assigned")
				)
			}
		} else if (gettypeContextSpace() == 'Subworld') {
			if (stateObjectDoor.sub_world_door_path !== null) {
				// HACIA UN MUNDO
				if (
					stateObjectDoor.sub_world_door_path.world_door_destination
				) {
					returnDestinationWorldDoor(
						stateObjectDoor.sub_world_door_path
							.world_door_destination
					)
				}
				// HACIA UN SUBMUNDO
				if (
					stateObjectDoor.sub_world_door_path
						.sub_world_door_destination
				) {
					returnDestinationSubWorldDoor(
						stateObjectDoor.sub_world_door_path
							.sub_world_door_destination
					)
				}
			} else {
				setInfoDestination(
					t("pages.editor.components.body_collapse_door_scene_world.no_destination_assigned")
				)
			}
		}
	}

	const returnDestinationWorldDoor = async (idDoor) => {
		// SEARCH DOOR DESTINATION
		const result_door_destination = await axiosSupreme(
			'get',
			API_MANAGE_DOORS + idDoor + '/',
			undefined
		)
		// SEARCH SCENE DESTINATION
		const result_scene_destination = await axiosSupreme(
			'get',
			API_MANAGE_SCENE_WORLDS +
			result_door_destination.world_scene.id_world_scene +
			'/',
			undefined
		)
		setInfoDestination(
			result_scene_destination.world.name +
			' / ' +
			result_scene_destination.name +
			' / ' +
			result_door_destination.name
		)
	}

	const returnDestinationSubWorldDoor = async (idDoor) => {
		// SEARCH DOOR DESTINATION
		const result_door_destination = await axiosSupreme(
			'get',
			API_MANAGE_SUBWORLD_DOORS + idDoor + '/',
			undefined
		)
		// SEARCH SCENE DESTINATION
		const result_scene_destination = await axiosSupreme(
			'get',
			API_MANAGE_SCENE_SUB_WORLDS +
			result_door_destination.sub_world_scene.id_scene_sub_world +
			'/',
			undefined
		)
		setInfoDestination(
			result_scene_destination.sub_world.name +
			' / ' +
			result_scene_destination.name +
			' / ' +
			result_door_destination.name
		)
	}

	const deleteDestination = async () => {
		const url =
			gettypeContextSpace() == 'World'
				? API_MANAGE_PATH_DOORS +
				stateObjectDoor.world_door_path.id_world_door_path +
				'/'
				: API_MANAGE_PATH_SUBWORLD_DOORS +
				stateObjectDoor.sub_world_door_path.id_sub_world_door_path +
				'/'
		const result_data = await axiosSupreme('delete', url, undefined)
		if (result_data.hasOwnProperty('response')) {
			setTypeMessage('error')
			setMessage(result_data.response.data)
			showAlert()
		} else {
			const result_data = await axiosSupreme(
				'get',
				gettypeContextSpace() === 'World'
					? API_MANAGE_DOORS + stateObjectDoor.id_world_door + '/'
					: API_MANAGE_SUBWORLD_DOORS +
					stateObjectDoor.id_sub_world_door +
					'/',
				undefined
			)
			setStateObjectDoor(result_data)
		}
	}

	// MODAL
	const { openModal, setTitleModal, setContentModal } =
		useContext(ModalContext)

	// MANAGE DESTINATION
	const OpenManageDestination = () => {
		setTitleModal(t("pages.editor.components.body_collapse_door_scene_world.door_destination"))
		setContentModal(
			<FormDestinationDoor
				setStateObjectDoor={setStateObjectDoor}
				objectWorldDoor={stateObjectDoor}
			/>
		)
		openModal()
	}

	return (
		<div className='doorContentBody collapseStylesBody'>
			{stateObjectDoor !== undefined ? (
				<div className='doorContentBody__container'>
					{gettypeContextSpace() == 'World' ? (
						<>
							<div className='doorContentBody__container__infoColumn'>
								<h1>{t("pages.editor.components.body_collapse_door_scene_world.bandge_and_alt")}</h1>
								<div className='doorContentBody__container__infoColumn__Left'>
									{stateObjectDoor.badge_file ? (
										<div className='doorContentBody__container__image'>
											<a
												href={
													stateObjectDoor.badge_file
												}
												target='_blank'
												rel='noreferrer noopener'>
												<img
													src={
														stateObjectDoor.badge_file
													}
													alt={t("pages.editor.components.body_collapse_door_scene_world.bandge_and_alt")}
													loading='lazy'
												/>
											</a>
										</div>
									) : (
										<p className='noSelect'>{t("pages.editor.components.body_collapse_door_scene_world.badge_no_image_selected")}
										</p>
									)}
								</div>
								<div className='doorContentBody__container__infoColumn__Right'>
									<div className='doorContentBody__container__infoColumn__Right__Info'>
										<h3>{t("pages.editor.components.body_collapse_door_scene_world.coordinates")}</h3>
										<p>
											{' '}
											{
												stateObjectDoor.badge_coordinates
											}{' '}
										</p>
									</div>
									<div className='doorContentBody__container__infoColumn__Right__Info'>
										<h3>{t("pages.editor.components.body_collapse_door_scene_world.frame_size")}</h3>
										<p>{stateObjectDoor.frame_size}</p>
									</div>
								</div>
							</div>
							<hr />
						</>
					) : null}

					<div className='doorContentBody__container__infoColumn'>
						<h1>{t("pages.editor.components.body_collapse_door_scene_world.door_and_alt")}</h1>
						<div className='doorContentBody__container__infoColumn__Left'>
							{stateObjectDoor.door_file ? (
								<div className='doorContentBody__container__image'>
									<a
										href={stateObjectDoor.door_file}
										target='_blank'
										rel='noreferrer noopener'>
										<img
											src={stateObjectDoor.door_file}
											alt={t("pages.editor.components.body_collapse_door_scene_world.door_and_alt")}
											loading='lazy'
										/>
									</a>
								</div>
							) : (
								<p className='noSelect'>
									{t("pages.editor.components.body_collapse_door_scene_world.door_no_image_selected")}
								</p>
							)}
						</div>
						<div className='doorContentBody__container__infoColumn__Right'>
							<div className='doorContentBody__container__infoColumn__Right__Info'>
								<h3>{t("pages.editor.components.body_collapse_door_scene_world.coordinates")}</h3>
								<p>{stateObjectDoor.coordinates} </p>
							</div>
							<div className='doorContentBody__container__infoColumn__Right__Info'>
								<h3>{t("pages.editor.components.body_collapse_door_scene_world.frame_size")}</h3>
								<p>{stateObjectDoor.frame_size}</p>
							</div>
							<div className='doorContentBody__container__infoColumn__Right__Info'>
								<h3>{t("pages.editor.components.body_collapse_door_scene_world.dependency")}</h3>
								{stateObjectDoor.door_dependency !== null ? (
									<p>
										{stateObjectDoor.door_dependency.name}
									</p>
								) : (
									<p>
										{t("pages.editor.components.body_collapse_door_scene_world.no_door_dependency")}
									</p>
								)}
							</div>
							<div className='doorContentBody__container__infoColumn__Right__Info'>
								<h3>{t("pages.editor.components.body_collapse_door_scene_world.stars_percentage_required")}</h3>
								<p>
									{stateObjectDoor.required_stars_percentage}
								</p>
							</div>
						</div>
					</div>
					<hr />

					{(gettypeContextSpace() === 'World' &&
						gameUser.permissions?.hasOwnProperty(
							'view_world_door_path'
						)) ||
						(gettypeContextSpace() === 'Subworld' &&
							gameUser.permissions?.hasOwnProperty(
								'view_sub_world_door_path'
							)) ? (
						<>
							<div className='doorContentBody__container__infoColumn'>
								<h1>{t("pages.editor.components.body_collapse_door_scene_world.destiny")}</h1>
								<div className='doorContentBody__container__infoColumn__Left'>
									<h3>{infoDestination}</h3>
									{gettypeContextSpace() === 'World' ? (
										stateObjectDoor.world_door_path ? (
											<p>{t("pages.editor.components.body_collapse_door_scene_world.world_escene_door")}</p>
										) : null
									) : stateObjectDoor.sub_world_door_path ? (
										<p>{t("pages.editor.components.body_collapse_door_scene_world.world_escene_door")}</p>
									) : null}
								</div>
								<div className='doorContentBody__container__infoColumn__Right'>
									<div className='doorContentBody__container__infoColumn__Right__Buttons'>
										{(gettypeContextSpace() === 'World' &&
											gameUser.permissions?.hasOwnProperty(
												'add_world_door_path'
											)) ||
											(gettypeContextSpace() === 'Subworld' &&
												gameUser.permissions?.hasOwnProperty(
													'add_sub_world_door_path'
												)) ? (
											<div className='doorContentBody__container__destination__btnsDes'>
												<button
													className='editorBtnActionAdd'
													onClick={
														OpenManageDestination
													}>{t("pages.editor.components.body_collapse_door_scene_world.manage")}
												</button>
											</div>
										) : null}

										{((gettypeContextSpace() === 'World' &&
											gameUser.permissions?.hasOwnProperty(
												'delete_world_door_path'
											)) ||
											(gettypeContextSpace() ===
												'Subworld' &&
												gameUser.permissions?.hasOwnProperty(
													'delete_sub_world_door_path'
												))) &&
											((gettypeContextSpace() === 'World' &&
												stateObjectDoor.world_door_path) ||
												(gettypeContextSpace() ===
													'Subworld' &&
													stateObjectDoor.sub_world_door_path)) ? (
											<button
												className='editorBtnActionDeleteWithoutIcon'
												onClick={deleteDestination}>{t("common.remove")}
											</button>
										) : null}
									</div>
								</div>
							</div>
							<hr />
						</>
					) : null}
				</div>
			) : null}
		</div>
	)
}

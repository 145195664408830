import { DoorBar } from "./DoorBar"
import { SubWorldDoor } from "./SubWorldDoor"

export class WorldDoor extends SubWorldDoor {
	#goesToGeneralLobby

	#starsData
	get startsData() {
		return this.#starsData
	}

	#badgeData
	#badge
	#bar

	constructor(
		id,
		name,
		pos,
		spriteSheets,
		goesToWorld,
		isAvailable,
		starsData,
		badgeData
	) {
		super(id, name, pos, spriteSheets, goesToWorld, isAvailable)
		this.#starsData = starsData
		this.#badgeData = {
			...badgeData,
			coordinates: badgeData.coordinates
				? this.parseStringVector(badgeData.coordinates, ['x', 'y'])
				: null,
		}
		this.#goesToGeneralLobby =
			starsData.total === 0 && badgeData.coordinates === null

		this.lockMessage =
			'Te faltan ⭐12 estrellas para desbloquear este nivel.'

		/*
		// this.isAvailable = isAvailable
		// If goes to lobby
		if (this.#goesToGeneralLobby) {
			this.isAvailable = true
		} else {
			// else if goes to level
			// if has enough stars
			if (
				this.#starsData.earned >=
				this.#starsData.total * this.#starsData.minPercentageToUnlock
			) {
				this.isAvailable = true
			} else {
				this.isAvailable = false
			}
		}
		*/
	}
	preload(scene) {
		super.preload(scene)
	}
	create(scene, bodyType) {
		super.create(scene, bodyType, true)

		if (this.#goesToGeneralLobby) {
			super.switchStateTo('available')
		} else if (this.isAvailable) {
			// Si esta disponible y no es una puerta que conduce al lobby
			super.switchStateTo('available')

			// ----- Create the badge
			const customCoordinates = { x: this.pos.x, y: this.pos.y }
			this.#badge = {
				container: null,
				element: null,
				create: function (file, x, y) {
					const div = document.createElement('div')
					div.setAttribute('class', 'map__door__badge')
					// div.style.backgroundImage =
					// 	'url(' + BASE_API + '/media/star/Sprite_loro_red.png)'
					div.style.backgroundImage = 'url(' + file + ')'
					//div.innerHTML = 'THE badge'

					// ----- CREATE THE HTML PHASER ELEMENT
					this.element = scene.add.dom(0, 0, div)

					this.container = scene.add.container(x, y)
					this.container.add(this.element)
				},
				enableVisibility(enable) {
					//this.container.alpha = enable ? 1 : 0
				},
			}
			this.#badge.create(
				this.#badgeData.file,
				this.#badgeData.coordinates
					? this.#badgeData.coordinates.x
					: this.pos.x,
				this.#badgeData.coordinates
					? this.#badgeData.coordinates.y
					: this.pos.y
			)

			// ----- DOOR BAR
			const percentage =
				100 -
				Math.round(
					(this.startsData.earned / this.startsData.total) * 100
				)
			this.#bar = new DoorBar(
				//{ x: this.pos.x, y: this.pos.y - this.sprite.height / 2 - 130 },
				{
					x: this.#badgeData.coordinates
						? this.#badgeData.coordinates.x
						: this.pos.x,
					y: this.#badgeData.coordinates
						? this.#badgeData.coordinates.y
						: this.pos.y,
				},
				{ x: 0.5, y: 0.5 },
				this.startsData.earned + ' / ' + this.startsData.total,
				'map__npc__name',
				true,
				percentage
			)
			this.#bar.create(scene)
		}
	}
	enable() {}
	onStartCollision(openDoor, player) {
		if (this.id === 'c4b872c9-c7aa-4ecf-8559-e3c43761a97b') return
		super.onStartCollision(openDoor, player, this.#goesToGeneralLobby)
	}
	onEndCollision() {
		super.onEndCollision(this.#goesToGeneralLobby)
	}
}

import '../../../styles/shared/Spinner.scss'

const Spinner = ({ className, size = 50, style = {} }) => {
	return (
		<div className={className}>
			<div
				className='spinner'
				style={{
					width: size + 'px',
					height: size + 'px',
					borderWidth: size / 10 + 'px',
					...style,
				}}
			/>
		</div>
	)
}

const SpinnerContainer = ({ containerClassName, size = 50 }) => {
	return (
		<div className='spinnerContainer'>
			<div className={containerClassName}>
				<div
					style={{
						width: size + 'px',
						height: size + 'px',
						borderWidth: size / 10 + 'px',
					}}
					className='spinner'></div>
			</div>
		</div>
	)
}

export default Spinner
export { SpinnerContainer }

import { useReducer } from 'react'

import {
	UPDATE_DATA_USER_ACTION_TYPE,
	initialStateUpdateDataUser,
	updateDataUserReducer,
} from '../../reducers/updateDataUserReducer/updateDataUserReducer'

export function useUpdateDataUserActions() {
	const [state, dispatch] = useReducer(
		updateDataUserReducer,
		initialStateUpdateDataUser
	)

	const updateLoading = ({ loading }) => {
		dispatch({
			type: UPDATE_DATA_USER_ACTION_TYPE.UPDATE_LOADING,
			payload: {
				loading,
			},
		})
	}

	const updateEditPassword = ({ editPassword }) => {
		dispatch({
			type: UPDATE_DATA_USER_ACTION_TYPE.UPDATE_EDIT_PASSWORD,
			payload: {
				editPassword,
			},
		})
	}

	const updateErorrs = ({ errorCode, errors }) => {
		dispatch({
			type: UPDATE_DATA_USER_ACTION_TYPE.CLEAN_AND_SET_ERRORS,
			payload: {
				errorCode,
				errors,
			},
		})
	}

	return {
		errors: state.errors,
		loading: state.loading,
		editPassword: state.editPassword,
		updateLoading,
		updateEditPassword,
		updateErorrs,
	}
}

import Coins from '../fragments/Coins'
import configIcon from '../../assets/icons/config.png'
import IconButton from '../shared/IconButton/IconButton'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const BasicDataBar = ({
	profileData,
	sceneData,
	onConfigClick,
	activityIsActive,
}) => {
	/**
	 * Basicamente detectar el cambio de XP y de monedas.
	 * Cuando aquello ocurre, yo tomo la diferencia y
	*/
	const { t } = useTranslation()
	const [lastXP, setLastXP] = useState(profileData.total_xp)
	const [lastCoins, setLastCoins] = useState(profileData.rewards)

	const [XPIsAnimating, setXPIsAnimating] = useState(false)
	const [coinsIsAnimating, setCoinsIsAnimating] = useState(false)

	const linearTimeoutLoop = (
		num,
		newNum,
		setAnimating,
		setNum,
		delayPlus,
		nPlus
	) => {
		if (num < newNum) {
			// THE XP HAVE CHANGED RECENTLY
			const timeout = setTimeout(() => {
				clearTimeout(timeout)
				setAnimating(true)

				const timeoutInterval = setInterval(() => {
					setNum((prevNum) => {
						const sumNum = prevNum + nPlus
						if (sumNum >= newNum) {
							setAnimating(false)
							clearInterval(timeoutInterval)
						}
						return sumNum
					})
				}, 50)
			}, 2500)
		}
	}

	useEffect(() => {
		if (activityIsActive) return

		linearTimeoutLoop(
			lastXP,
			profileData.total_xp,
			setXPIsAnimating,
			setLastXP,
			30,
			1
		)
		linearTimeoutLoop(
			lastCoins,
			profileData.rewards,
			setCoinsIsAnimating,
			setLastCoins,
			30,
			1
		)

		return () => {
			setLastXP(profileData.total_xp)
			setLastCoins(profileData.rewards)
		}
	}, [profileData, activityIsActive])

	return (
		<div className='scene__basicdata'>
			<div
				className={
					'scene__basicdata__container' +
					(XPIsAnimating
						? ' scene__basicdata__container__animate__shake'
						: '')
				}
				style={{ fontWeight: 'bold', fontSize: '1rem' }}>
				{'XP: ' + lastXP}
			</div>
			<div
				className={
					'scene__basicdata__container' +
					(coinsIsAnimating
						? ' scene__basicdata__container__animate__shake'
						: '')
				}
				style={{ fontWeight: 'bold', fontSize: '1rem' }}>
				<Coins coinsAmount={lastCoins} />
			</div>
			<div
				className='scene__basicdata__container'
				style={{ fontWeight: 'bold', fontSize: '1rem' }}>
				{t("pages.game.components.progress_bar.map")} {sceneData.name}
			</div>
			<IconButton
				src={configIcon}
				onClick={onConfigClick}
				parentStyle={{
					cursor: 'pointer',
					border: 'none',
					outline: 'none',
				}}
			/>
		</div>
	)
}

export default BasicDataBar

import { useRef } from "react"
import { newDecorations, newDoors, newLostItems, newNPCS, newPlayer } from "../phaser/Init"

export const useLocalGameObjects = (sceneData, actualDoor, missionIsCompleted) => {
	/**
	 * It is going to fill all of these arrays with instances interating over the sceneData.
	 * Providing essencial information to each one of them. Since: name, spriteSheet and ID,
	 * To: isAvailable, actions (functions) and metadata.
	 */
	let playerRef = useRef(null)
	let doors = useRef(null)
	let NPCS = useRef(null)
	let lostItems = useRef(null)
	let decorations = useRef(null)

	if (!playerRef.current) {
		playerRef.current = newPlayer(sceneData.skinFile, actualDoor)
		doors.current = newDoors(sceneData, actualDoor)
		NPCS.current = newNPCS(sceneData, missionIsCompleted)
		lostItems.current = newLostItems(sceneData)
		decorations.current = newDecorations(sceneData)
	}

	return [playerRef, doors, NPCS, lostItems, decorations]
}

import {
	SubWorldDoor,
	WorldDoor,
	NPCGame,
	NPCClass,
	NPCLostItem,
	Player,
	EasterEgg,
	LostItem,
	Decoration,
} from './Classes'
import { BASE_API } from '../urls'

function newPlayer(skinFile, actualDoor) {
	let player
	let playerPos = actualDoor.coordinates //data.map.initial_coordinates
	const animationDuration = 800
	player = new Player('playerID', 'player', playerPos, [
		{
			name: 'playerSpriteSheet',
			// url: process.env.PUBLIC_URL + '/images/loroNew.png',
			url: BASE_API + skinFile,
			frameSize: '[190, 159]',
			animations: [
				{
					// ----- FRONT
					name: '-idle0', // 'player-idle'
					frames: { start: 0, end: 6 },
					duration: animationDuration,
					repeat: -1,
				},
				{
					name: '-walk0', // 'player-idle'
					frames: { start: 7, end: 13 },
					duration: animationDuration,
					repeat: -1,
				},
				{
					// ----- SIDE
					name: '-idle1', // 'player-idle'
					frames: { start: 14, end: 20 },
					duration: animationDuration,
					repeat: -1,
				},
				{
					name: '-walk1', // 'player-idle'
					frames: { start: 21, end: 27 },
					duration: animationDuration,
					repeat: -1,
				},
				{
					// ------ BACK
					name: '-idle2', // 'player-idle'
					frames: { start: 28, end: 34 },
					duration: animationDuration,
					repeat: -1,
				},
				{
					name: '-walk2', // 'player-idle'
					frames: { start: 35, end: 41 },
					duration: animationDuration,
					repeat: -1,
				},
			],
		},
	])
	return player
}

function newDoors(sceneData, actualDoor) {
	const doors = []
	for (const data of sceneData.doors) {
		const attrs = [
			data.id,
			data.name,
			data.coordinates,
			data.spriteSheets,
			data.goesToWorld,
			data.isAvailable,
		]
		let newDoor
		if (actualDoor.isWorld) {
			newDoor = new WorldDoor(...attrs, data.starsData, data.badgeData)
		} else {
			newDoor = new SubWorldDoor(...attrs)
		}
		doors.push(newDoor)
	}
	return doors
}

function newNPCS(sceneData, missionIsCompleted) {
	const NPCS = { class: [], game: [], lostItem: [], easterEgg: [] }
	for (const data of sceneData.npcs) {
		const attrs = [
			data.id,
			data.name,
			data.coordinates,
			data.spriteSheets,
			data.type,
			data.dialogues,
			data.missions,
		]
		let newNPC
		if (data.type === 'l') {
			// ----- $CLASS
			newNPC = new NPCClass(...attrs, data.profileImage)
			NPCS.class.push(newNPC)
		} else if (data.type === 'g') {
			// ----- $GAME
			newNPC = new NPCGame(
				...attrs,
				data.profileImage,
				data.gameType,
				data.starsData
			)
			NPCS.game.push(newNPC)
		} else if (data.type === 'o') {
			// ----- $LOST ITEM
			newNPC = new NPCLostItem(...attrs)
			NPCS.lostItem.push(newNPC)
		} else if (data.type === 'ee') {
			// ----- $EASTER EGG
			newNPC = new EasterEgg(...attrs) // pass missions but ignor it.
			NPCS.easterEgg.push(newNPC)
		}
	}
	return NPCS
}

function newLostItems(sceneData) {
	const lostItems = []
	for (const data of sceneData.lostItems) {
		const newLostItem = new LostItem(
			data.id,
			data.name,
			data.coordinates,
			data.spriteSheets,
			data.mission
		)
		lostItems.push(newLostItem)
	}
	return lostItems
}

function newDecorations(sceneData) {
	let decorations = []
	for (const data of sceneData.decorations) {
		const decoration = new Decoration(
			data.id,
			data.name,
			data.coordinates,
			data.spriteSheets,
			data.hidden,
			data.isSuperimposed
		)
		decorations.push(decoration)
	}
	return decorations
}

export { newPlayer, newDoors, newNPCS, newLostItems, newDecorations }

export function getHorizontal(y1, y2) {
  const diagonal = [];

  const pasoY = y1 < y2 ? 1 : -1;

  let y = y1;

  diagonal.push(y);

  while (y !== y2) {
      y += pasoY;
      diagonal.push(y);
  }

  return diagonal;
}

export function getVertical (x1, x2) {
  const diagonal = [];

  const pasoX = x1 < x2 ? 1 : -1;

  let x = x1;

  diagonal.push(x);

  while (x !== x2) {
      x += pasoX;
      diagonal.push(x);
  }

  return diagonal;
}

export function getDiagonal (x1, y1, x2, y2) {
  const diagonal = [];

  const pasoX = x1 < x2 ? 1 : -1;
  const pasoY = y1 < y2 ? 1 : -1;

  let x = x1;
  let y = y1;

  diagonal.push([x, y]);

  while (x !== x2 || y !== y2) {
      x += pasoX;
      y += pasoY;
      diagonal.push([x, y]);
  }

  return diagonal;
}

import { SliderBar } from './SliderBar'
import { SpriteWithZoneAndCollisions } from './SpriteWithZoneAndCollisions'
import { StringTag } from './StringTag'

export class SubWorldDoor extends SpriteWithZoneAndCollisions {
	#isAvailable = false
	get isAvailable() {
		return this.#isAvailable
	}
	set isAvailable(is) {
		this.#isAvailable = is
	}
	#goesToWorld
	get goesToWorld() {
		return this.#goesToWorld
	}

	#lockLetter
	get lockLetter() {
		return this.#lockLetter
	}
	#lockMessage
	set lockMessage(msg) {
		this.#lockMessage = msg
	}

	#nameTag
	get nameTag() {
		return this.#nameTag
	}

	#openDoorTimeout
	#openingBar

	constructor(id, name, pos, spriteSheets, goesToWorld, isAvailable) {
		super(id, name, pos, spriteSheets)
		this.#isAvailable = isAvailable
		this.#goesToWorld = goesToWorld
		this.#lockMessage =
			'Completa la barra de progreso para desbloquear esta puerta'
	}
	create(scene, bodyType, hideName = false) {
		super.create(scene, bodyType, undefined, 'DOOR ZONE', false)

		// ----- ANIMATION DEPENDING ON STATE
		if (this.#isAvailable) this.switchStateTo('available')
		else this.switchStateTo('blocked')

		this.#openingBar = new SliderBar(scene, this.pos.x, this.pos.y)

		// ----- CREATE NAME TAG
		if (!hideName) {
			this.#nameTag = new StringTag(
				{ x: this.pos.x, y: this.pos.y + 30 },
				{ x: 0.5, y: 0 },
				'● ' + this.name[0].toUpperCase() + this.name.slice(1) + ' ●',
				'map__npc__name',
				true
			)
			this.#nameTag.create(scene)
		}

		/// ----- LOCK LETTER
		this.#lockLetter = new StringTag(
			{ x: this.pos.x, y: this.pos.y - this.sprite.height / 2 - 10 },
			{ x: 0.5, y: 1 },
			this.#lockMessage,
			'map__door__locked',
			true
		)
		this.#lockLetter.create(scene)
		this.#lockLetter.enableVisibility(false)
	}
	switchStateTo(state) {
		if (state === 'blocked') {
			// ----- ANIMATE BLOCKED
			this.sprite.anims.play(this.name + '-blocked')
		} else if (state === 'available') {
			// ----- ANIMATE AVAILABLE
			this.sprite.anims.play(this.name + '-available')
		} else if (state === 'interacting') {
			// ----- CLOSE THE DOOR
			//this.sprite.anims.play(this.name + '-interacting')
		}
	}
	enable() {
		this.#isAvailable = true
		this.switchStateTo('available')
	}
	onStartCollision(openDoor, player, goesToGeneralLobby) {
		if (!this.isAvailable && !goesToGeneralLobby) {
			this.#lockLetter.enableVisibility(true)
			return
		}

		/**
		 * The user always starts a scene inside of a door. To avoid opening that door
		 * again only by being collisioning with it already, put a conditional that
		 * says that if the player has the exact same position of the door in the first
		 * frame of collision, then do not open the door.
		 */
		if (player.pos.x !== this.pos.x || player.pos.y !== this.pos.y) {
			// ANIMATE THE DOOR TO INTERACTING
			//this.switchStateTo('interacting')
			this.#openingBar.start()

			// WAIT X TIME (ANIMATION TIME) AND SWITCH SCENE
			clearTimeout(this.#openDoorTimeout)
			this.#openDoorTimeout = setTimeout(() => {
				clearTimeout(this.#openDoorTimeout)
				openDoor(this)
			}, this.#openingBar.time)
		}
	}
	onEndCollision(goesToGeneralLobby) {
		// RETURN - IF THE DOOR IS STILL NOT AVAILABLE
		if (!this.#isAvailable && !goesToGeneralLobby) {
			this.#lockLetter.enableVisibility(false)
			return
		}

		clearTimeout(this.#openDoorTimeout)
		this.#openingBar.stop()
	}
	destroyStart() {
		clearTimeout(this.#openDoorTimeout)
		this.#openingBar.stop()
		this.#openingBar.container.setPosition(10000, 10000)
		this.lockLetter.container.setPosition(10000, 10000)
	}
	destroyEnd() {
		//this.zone.sprite.position.x = 10000
	}
}

import React from 'react'
import { useNavigate } from 'react-router-dom'
import image from '../assets/images/Error404/MensajeError2.png'
import '../styles/Error404.scss'

function Error() {
	const navigate = useNavigate()
	const routeChange = () => {
		let path = '/'
		navigate(path)
	}

	return (
		<section className='bodyError'>
			<div className='error'>
				<div className='error__container'>
					<div className='error__container__title'>
						<img
							className='error__container__title__img'
							src={image}
							alt='recurso no encontrado'
						/>
					</div>
					<button
						className='error__container__btn'
						onClick={routeChange}>
						{' '}
						Regresar{' '}
					</button>
				</div>
			</div>
		</section>
	)
}

export { Error }

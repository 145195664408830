/* eslint-disable no-lone-blocks */
import React, { useState } from 'react'
import {
	API_WORLD_DOOR_DESTINATION,
	API_SUB_WORLD_DOOR_DESTINATION,
	URL_UPDATE_PROFILE,
} from '../utils/constants'
import { LoadingScreen } from './fragments/LoadingScreen'
// --------------------------------------------------------------------------- PLATFORM

import { SceneDataFetch } from './SceneDataFetch'
import { useAuthProvider } from '../context/AuthProvider/AuthProvider'
import SceneConfigProvider from '../context/SceneConfigProvider'
import LostItemsProvider from '../context/LostItemsProvider'

/**
 * This compoment basically destroys and creates scenes, go invisible while destroying and creating
 * and goes visible when the new scene created is fully loaded. It also updates the position
 * of the userData.
 */
function SceneSwitcher() {
	const { axiosSupreme } = useAuthProvider()
	//This is the key of the scene, when we change it, it destroys the actual scene and create a new one.
	const [sceneKey, setSceneKey] = useState(true)

	// Loading Vars
	const [loadingIsVisible, setLoadingIsVisible] = useState(true)
	const [loadingBarPercentage, setLoadingBarPercentage] = useState(0)

	const updateLoadingBarPercentage = (percentage) => {
		setLoadingBarPercentage(100 * percentage)
	}
	const loadingBarWasCompleted = () => {
		setLoadingIsVisible(false)
	}

	/**
	 * Called each time the user click a door to go to another map (scene).
	 * All of the params necessary to call updatePositionProfile
	 */
	const goToNextScene = async (
		doorID,
		profileID,
		doorName,
		goesToWorld,
		isDirectDestinationToWorld = false
	) => {
		// ----- RESTART LOADING SCREEN PROGRESS BAR
		updateLoadingBarPercentage(0)
		setLoadingIsVisible(true)

		// ----- WAIT WHILE THE ANIMATION COMPLETES
		const API_DOOR = goesToWorld
			? API_WORLD_DOOR_DESTINATION
			: API_SUB_WORLD_DOOR_DESTINATION
		// ----- #1 GET THE DESTINATION OF THE WORLD THE PLAYER JUST ENTER
		const doorDestination = await axiosSupreme(
			'get',
			API_DOOR,
			doorID + '/' + profileID
		)
		// ----- #2 FILTER THE DESTINATION BASED ON WORLD OR SUB_WORD
		let destination = doorDestination.world_door_destination
			? doorDestination.world_door_destination
			: doorDestination.sub_world_door_destination
		const isWorld = doorDestination.world_door_destination !== null

		// ----- #3 UPDATE THE PROFILE OF THE USER WITH THE DOOR DESTINATION
		await axiosSupreme(
			'patch',
			URL_UPDATE_PROFILE + profileID + '/',
			{
				world_door: isDirectDestinationToWorld
					? doorID
					: isWorld
					? destination
					: null,
				sub_world_door: isDirectDestinationToWorld
					? null
					: isWorld
					? null
					: destination,
			}
		)

		// ----- #4 DELETE THE ACTUAL SCENE AND CREATE A NEW ONE.
		setSceneKey((sk) => !sk)
	}

	return (
		<SceneConfigProvider loadingIsVisible={loadingIsVisible}>
			<LostItemsProvider>
				<div className='switcher'>
					<LoadingScreen
						percentage={loadingBarPercentage}
						isVisible={loadingIsVisible}
					/>
					<SceneDataFetch
						key={sceneKey}
						goToNextScene={goToNextScene}
						updateLoadingBarPercentage={updateLoadingBarPercentage}
						loadingBarWasCompleted={loadingBarWasCompleted}
					/>
				</div>
			</LostItemsProvider>
		</SceneConfigProvider>
	)
}

export { SceneSwitcher }

import '../styles/MobileRedirection.scss'
import ImageOnFlex1 from './fragments/ImageOnFlex1'
import loroImage from '../assets/images/aspectRation.png'
import googlePlayImage from '../assets/images/mobileStore/googlePlay/english.png'
import appStoreImage from '../assets/images/mobileStore/appStore/english.svg'

const MobileRedirection = () => {
	return (
		<section className='mobile__redirection'>
			<h1>¡DESCARGA LA APP YA MISMO!</h1>
			<ImageOnFlex1 src={loroImage} />
			<div className='mobile__redirection__buttons'>
				<a href='https://google.com' target='_blank' rel='noreferrer noopener'>
					<img src={googlePlayImage} alt='google play' />
				</a>
				<a href='https://google.com' target='_blank' rel='noreferrer noopener'>
					<img src={appStoreImage} alt='apple store' />
				</a>
			</div>
		</section>
	)
}

export default MobileRedirection

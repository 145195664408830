/**
 * This custom hook, grows and shinks the size of a container (child)
 * to fill the most space of his parent, keeping its aspect
 * ratio.
 */

import { useEffect, useRef, useState } from 'react'

const useResponsiveDivAspectRatio = (
	aspectRatio,
	shrinkParent,
	delay = false
) => {
	const childRef = useRef(null)
	const parentRef = useRef(null)
	const [windowResized, setWindowResized] = useState(false)

	useEffect(() => {
		const calculateFitSize = () => {
			if (!parentRef.current || !childRef.current) return

			const actualStyles = window.getComputedStyle(parentRef.current)
			const actualWidth = parseFloat(
				actualStyles.width.substring(0, actualStyles.width.length - 2)
			)
			const actualHeight = parseFloat(
				actualStyles.height.substring(0, actualStyles.height.length - 2)
			)
			const actualAspectRatio = actualWidth / actualHeight

			if (actualAspectRatio > aspectRatio) {
				// Width is grater than height
				// height is the right size, so resize the width
				childRef.current.style.width = actualHeight * aspectRatio + 'px'
				childRef.current.style.height = actualHeight + 'px'
				// childRef.current.style.height = '100px'
				// childRef.current.style.backgroundColor = 'black'

				if (shrinkParent) {
					parentRef.current.style.flex = 'none'
					parentRef.current.style.width = actualHeight + 'px'
				}
			} else {
				// Height is grater than width
				// width is the right size, so resize the height
				childRef.current.style.height = actualWidth / aspectRatio + 'px'
				// childRef.current.style.height = actualWidth + 'px'
				childRef.current.style.width = '100%'
				// childRef.current.style.width = '100px'
				// childRef.current.style.backgroundColor = 'blue'

				if (shrinkParent) {
					parentRef.current.style.flex = 'none'
					parentRef.current.style.height = actualWidth + 'px'
				}
			}
			// childRef.current.style.backgroundColor = 'blue'
		}

		if (delay) {
			const timeout = setTimeout(() => {
				clearTimeout(timeout)
				calculateFitSize()
			}, 100)
		} else calculateFitSize()

		// ----- WINDOW
		const resize = function () {
			setWindowResized((w) => !w)
		}
		window.addEventListener('resize', resize)

		return () => {
			window.removeEventListener('resize', resize)
		}
	}, [windowResized])

	return [parentRef, childRef]
}

export default useResponsiveDivAspectRatio
